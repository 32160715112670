import { DATE_TYPE_ENUM } from 'src/utils/graphsUtils/graphConstants';
import { StatisticsDateUnitTypes } from 'src/routes/IndividualDashboard/modules/statistics/types';

export const FORMAT_MAP = Object.freeze({
  [DATE_TYPE_ENUM.MINUTE]: 'HH:mm',
  [DATE_TYPE_ENUM.HOUR]: 'HH:mm',
});

export const TIME_CALC_OPTIONS = Object.freeze({
  ADD: 'ADD',
  SUB: 'SUB',
});

export const DATE_PICKER_DATE_TIME_MAP: Record<
  StatisticsDateUnitTypes,
  {
    topDatePicker: StatisticsDateUnitTypes;
    bottomDatePicker: StatisticsDateUnitTypes | null;
  }
> = {
  [DATE_TYPE_ENUM.DATE]: {
    topDatePicker: DATE_TYPE_ENUM.DATE,
    bottomDatePicker: null,
  },
  [DATE_TYPE_ENUM.MONTH]: {
    topDatePicker: DATE_TYPE_ENUM.MONTH,
    bottomDatePicker: null,
  },
  [DATE_TYPE_ENUM.YEAR]: {
    topDatePicker: DATE_TYPE_ENUM.YEAR,
    bottomDatePicker: null,
  },
};
