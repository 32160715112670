import {
  createSlice,
  createSelector,
  PayloadAction,
  createAction,
} from '@reduxjs/toolkit';

import { DATA_FETCHING_STATUS } from 'src/redux/data/constants';
import { RootState } from 'src/redux/store';
import { selectors as devicesSelectors } from 'src/redux/data/device';
import { selectors as patientsSelectors } from 'src/redux/data/patient';
import { selectors as roomsSelectors } from 'src/redux/data/rooms';
import { selectors as monitorSelectors } from 'src/redux/data/monitor';
import { selectors as sessionsSelectors } from 'src/redux/data/sessions';
import { AlertSoundSettings } from 'src/types/alerts';
import { DEFAULT_TAB } from './tabConfig';
import { DeviceFilter, StateFilters } from './filterUtils';
import { NurseStationDevice, NurseStationTabKey } from './types';

export const STATE_KEY = 'nurseStation';

export const INITIAL_STATE: {
  activeTab: NurseStationTabKey;
  alertSoundStatus: AlertSoundSettings;
  fetchStatus: keyof typeof DATA_FETCHING_STATUS;
  filters: StateFilters;
  searchQuery: string;
} = {
  activeTab: DEFAULT_TAB,
  alertSoundStatus: AlertSoundSettings.ENABLED,
  fetchStatus: DATA_FETCHING_STATUS.SUCCESS,
  filters: {
    status: [],
    view: 'all',
  },
  searchQuery: '',
};

const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    changeActiveTab: (state, action: PayloadAction<NurseStationTabKey>) => {
      state.activeTab = action.payload;
    },
    applyFilter: (state, action: PayloadAction<DeviceFilter>) => {
      const { filter, value } = action.payload;

      switch (filter) {
        case 'status':
          state.filters[filter] = [...state.filters[filter], value];
          return;
        case 'view':
          state.filters[filter] = value;
          return;
      }
    },
    removeFilter: (state, action: PayloadAction<DeviceFilter>) => {
      const { filter, value } = action.payload;

      switch (filter) {
        case 'status':
          state.filters[filter] = state.filters[filter].filter(
            f => f !== value,
          );
          return;
        case 'view':
          state.filters[filter] = 'all';
          return;
      }
    },
    resetFilters: (state, action: PayloadAction<DeviceFilter['filter']>) => {
      const filter = action.payload;

      switch (filter) {
        case 'status':
          state.filters[filter] = [];
          return;
        case 'view':
          state.filters[filter] = 'all';
          return;
      }
    },
    applySearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
    },
    changeAlertSoundStatus: (
      state,
      action: PayloadAction<AlertSoundSettings>,
    ) => {
      state.alertSoundStatus = action.payload;
    },
  },
});

const extraActions = {
  nurseStationPageMounted: createAction(`${STATE_KEY}/nurseStationPageMounted`),
  nurseStationPageUnmounted: createAction(
    `${STATE_KEY}/nurseStationPageUnmounted`,
  ),
};

const getState = (state: RootState) => state[STATE_KEY] || INITIAL_STATE;
export const selectors = {
  selectActiveTab: createSelector(getState, state => state.activeTab),
  selectFilters: createSelector(getState, state => state.filters),
  selectSearchQuery: createSelector(getState, state => state.searchQuery),
  selectAlertSoundStatus: createSelector(
    getState,
    state => state.alertSoundStatus,
  ),
};

export const extraSelectors = {
  selectNSDevices: createSelector(
    (state: RootState) => state,
    devicesSelectors.getDevicesList,
    patientsSelectors.getPatientsList,
    sessionsSelectors.selectSessions,
    roomsSelectors.getRoomList,
    monitorSelectors.getContiuousDataForAllDevices,
    monitorSelectors.getDevices,
    (
      state,
      devices,
      patients,
      sessions,
      rooms,
      devicesContinuousData,
      monitorDevices,
    ): NurseStationDevice[] =>
      devices.map(device => {
        const deviceSession = sessions.find(
          s => s.deviceId === device.manufacturerId,
        );
        const deviceContinuousData = devicesContinuousData.find(
          data => data.deviceId === device.manufacturerId,
        )?.continuousData;

        const patientId = monitorSelectors.getPatientId(
          state,
          device.manufacturerId,
        );

        const room = rooms.find(room =>
          room.beds?.find(bed => bed.deviceId === device.manufacturerId),
        );
        const bed = room?.beds?.find(
          bed => bed.deviceId === device.manufacturerId,
        );
        const isDeviceConnected = !!device.connectionStatus.connected;

        return {
          id: device.manufacturerId,
          patientId: patientId || deviceSession?.patientId,
          deviceName: device.name,
          patientName: patients.find(patient => patient.id === patientId)
            ?.fullName,
          status: monitorDevices[device.manufacturerId]?.status || null,
          continuousData: deviceContinuousData,
          certificateExists: device?.certificateExists,
          isDeviceConnected,
          isDeviceActive:
            isDeviceConnected &&
            monitorSelectors.getIsActive(state, device.manufacturerId),
          roomName: room?.name,
          bedName: bed?.name,
        };
      }),
  ),
};

export const actions = { ...slice.actions, ...extraActions };
const { reducer } = slice;
export default reducer;
