import { styled, css } from 'styled-components';

const SidebarFlexWrapper = css`
  height: 90%;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  margin-top: 1.5rem;

  .sorting-flex {
    display: grid;
    grid-template-columns: 0.9fr 1fr 0.6fr 0.8fr;
  }
`;

// right padding and negative right margin are for scrollbar alignment
const ScrollBarSpace = css`
  padding-right: 0.5rem;
  margin-right: -0.5rem;
`;

export const AlertSidebarWrapper = styled.div`
  height: 100vh;
  width: 16.5rem;
  font-family: ${props => props.theme.fonts.primary};
  background: linear-gradient(
      0deg,
      rgba(155, 188, 203, 0.75),
      rgba(155, 188, 203, 0.75)
    ),
    #ffffff;
  box-shadow: -3px 0px 2px rgba(78, 83, 96, 0.15);
  z-index: 1;
  padding: 1rem 0.875rem;
`;

export const AlertCardsWrapper = styled.div`
  overflow-y: auto;
  ${SidebarFlexWrapper}
  ${ScrollBarSpace}
`;

export const StyledText = styled.div`
  font-size: 0.8rem;
  font-weight: bold;
`;

export const EventLogWrap = styled.div`
  ${SidebarFlexWrapper}
`;

export const AttentionListWrap = styled.div`
  ${SidebarFlexWrapper}
`;

export const ListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  overflow-y: auto;
  ${ScrollBarSpace}
`;
