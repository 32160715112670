import {
  createSlice,
  createSelector,
  createAction,
  PayloadAction,
  current,
} from '@reduxjs/toolkit';
import * as R from 'fp-ts/lib/Record';
import * as O from 'fp-ts/lib/Option';

import { RootState } from 'src/redux/store';
import {
  Alert,
  AlertSettings,
  AlertThreshold,
  AlertWithPersonalDetails,
  BaselineAlertThreshold,
  PatientAlertTypeSettings,
  SubtenantAttentionList,
} from 'src/types/alerts';
import { UUID } from 'src/types/utility';
import { ALERT_STATUS_ENUM } from 'src/utils/constants';
import { DATA_STATE_KEY, DATA_FETCHING_STATUS } from '../../constants';
import {
  AlertsMap,
  AlertSoundsMap,
  PacientIdPayload,
  SoundsToBePlayed,
} from './types';
import {
  mergeArraysWithUniqueIds,
  mergeRecordsWithUniqueIds,
} from '../../dataUtils';
import { mapPatientAlertsByPatientId, partitionAlertsByType } from './utils';
import { API_STATUS } from 'src/utils/api-constants';
import { FetchMTMAttentionListResponse } from 'src/services/types';
import {
  ActivityAlertSettings,
  SelectedLevelAlertSettings,
} from 'src/components/AlertSettingsComponents/ActivityAlerts/types';

export const STATE_KEY = 'alerts';

// TODO: Change to enum
type LoadingStatus = 'LOADING' | 'SUCCESS' | 'ERROR' | 'INITIAL';

export const INITIAL_STATE: {
  alerts: Alert[];
  patientCurrentAlerts: AlertsMap;
  mtmAttentionList: Record<UUID, SubtenantAttentionList>;
  alertSounds: AlertSoundsMap;
  thresholds: AlertThreshold[];
  baselineThresholds: BaselineAlertThreshold[];
  alertSettings: {
    tenant: Partial<AlertSettings>;
    list: AlertSettings[];
  };
  isBedExitAlertEnabled: Record<UUID, boolean>;
  thresholdStatus: LoadingStatus;
  baseLineThresholdStatus: LoadingStatus;
  alertsStatus: LoadingStatus;
  historicalAlertsStatus: LoadingStatus;
  alertTypesStatus: LoadingStatus;
  alertsSettingsStatus: LoadingStatus;
  mtmAttentionListStatus: LoadingStatus;
  ongoingAlertProcesses: {
    clear: Record<UUID, boolean>;
  };
  alertWithPersonalDetails: {
    data: O.Option<AlertWithPersonalDetails>;
    apiStatus: API_STATUS;
  };
  areAlertsMuted: boolean;
  activityAlertSettings: {
    data: ActivityAlertSettings;
    extraProps: {
      isLoading: LoadingStatus;
    };
  };
} = {
  alerts: [],
  patientCurrentAlerts: {},
  mtmAttentionList: {},
  alertSounds: {},
  thresholds: [],
  baselineThresholds: [],
  alertSettings: {
    tenant: {},
    list: [],
  },
  isBedExitAlertEnabled: {},
  thresholdStatus: DATA_FETCHING_STATUS.LOADING,
  baseLineThresholdStatus: DATA_FETCHING_STATUS.LOADING,
  alertsStatus: DATA_FETCHING_STATUS.LOADING,
  historicalAlertsStatus: DATA_FETCHING_STATUS.LOADING,
  alertTypesStatus: DATA_FETCHING_STATUS.LOADING,
  alertsSettingsStatus: DATA_FETCHING_STATUS.LOADING,
  mtmAttentionListStatus: DATA_FETCHING_STATUS.LOADING,
  ongoingAlertProcesses: {
    clear: {},
  },
  alertWithPersonalDetails: {
    data: O.none,
    apiStatus: API_STATUS.LOADING,
  },
  areAlertsMuted: false,
  activityAlertSettings: {
    data: {
      uniqueChildTenantAlertSettings: [
        {
          tenantIds: [],
          outOfBedAlertSettings: {
            shortlyAfterBedExitAlertEnabled: false,
            bedtimeExitAlertSettings: {
              startTime: '00:00',
              endTime: '00:00',
              continuousOutOfBedAlertSettings: {
                thresholdDuration: 0,
                enabled: false,
              },
              repeatingStepOutOfBedAlertSettings: {
                minimumRepetitions: 0,
                minimumDuration: 0,
                maximumDuration: 0,
                enabled: false,
              },
            },
          },
          inBedAlertSettings: {
            percentChange: 0,
            percentChangeInBedExits: 0,
            durationBaselineDays: 0,
            deviationPeriodDays: 0,
            enabled: false,
          },
        },
      ],
      selectedLevelAlertSettings: {
        tenantIds: [],
        outOfBedAlertSettings: {
          shortlyAfterBedExitAlertEnabled: false,
          bedtimeExitAlertSettings: {
            startTime: '00:00',
            endTime: '00:00',
            continuousOutOfBedAlertSettings: {
              thresholdDuration: 0,
              enabled: false,
            },
            repeatingStepOutOfBedAlertSettings: {
              minimumRepetitions: 0,
              minimumDuration: 0,
              maximumDuration: 0,
              enabled: false,
            },
          },
        },
        inBedAlertSettings: {
          percentChange: 0,
          percentChangeInBedExits: 0,
          durationBaselineDays: 0,
          deviationPeriodDays: 0,
          enabled: false,
        },
      },
      uniqueChildTenantIds: [],
    },
    extraProps: {
      isLoading: API_STATUS.INITIAL,
    },
  },
};

const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    fetchAlertsSuccess: (
      state,
      action: PayloadAction<{
        alertsList: Alert[];
        alertsMap: AlertsMap;
      }>,
    ) => {
      const { alertsList, alertsMap } = action.payload;

      state.alerts = mergeArraysWithUniqueIds(state.alerts, alertsList).sort(
        (a, b) => b.startTime.localeCompare(a.startTime),
      );

      state.patientCurrentAlerts = mergeRecordsWithUniqueIds(
        state.patientCurrentAlerts,
        alertsMap,
      );
      state.alertsStatus = DATA_FETCHING_STATUS.SUCCESS;
    },
    updateAlertsSuccess: (
      state,
      action: PayloadAction<{
        alertsList: Alert[];
      }>,
    ) => {
      const { alertsList } = action.payload;

      state.alerts = mergeArraysWithUniqueIds(state.alerts, alertsList).sort(
        (a, b) => b.startTime.localeCompare(a.startTime),
      );
    },
    setAlertsStatus: (
      state,
      action: PayloadAction<keyof typeof DATA_FETCHING_STATUS>,
    ) => {
      state.alertsStatus = action.payload;
    },
    setHistoricalAlertsStatus: (
      state,
      action: PayloadAction<keyof typeof DATA_FETCHING_STATUS>,
    ) => {
      state.historicalAlertsStatus = action.payload;
    },
    fetchHistoricalAlertsSuccess: (state, action: PayloadAction<Alert[]>) => {
      const alertsList = action.payload;

      state.alerts = mergeArraysWithUniqueIds(state.alerts, alertsList).sort(
        (a, b) => b.startTime.localeCompare(a.startTime),
      );
      state.historicalAlertsStatus = DATA_FETCHING_STATUS.SUCCESS;
    },
    fetchAlertsFailed: state => {
      state.alertsStatus = DATA_FETCHING_STATUS.ERROR;
    },
    fetchMTMAttentionListSuccess: (
      state,
      action: PayloadAction<FetchMTMAttentionListResponse>,
    ) => {
      state.mtmAttentionList = action.payload.byTenantId;
    },
    setMTMAttentionListStatus(
      state,
      action: PayloadAction<keyof typeof DATA_FETCHING_STATUS>,
    ) {
      state.mtmAttentionListStatus = action.payload;
    },
    suppressAlertTypeSuccess: (state, action: PayloadAction<Alert>) => {
      const newAlert = action.payload;

      state.alerts = mergeArraysWithUniqueIds(state.alerts, [newAlert]).sort(
        (a, b) => b.startTime.localeCompare(a.startTime),
      );

      const newPatientAlerts = partitionAlertsByType(
        mapPatientAlertsByPatientId([
          { patientId: newAlert.patientId, alerts: [newAlert] },
        ]),
      );

      state.patientCurrentAlerts = mergeRecordsWithUniqueIds(
        state.patientCurrentAlerts,
        newPatientAlerts,
      );
    },
    unSuppressAlertTypeSuccess: (state, action: PayloadAction<Alert>) => {
      const newAlert = action.payload;

      state.alerts = mergeArraysWithUniqueIds(state.alerts, [newAlert]).sort(
        (a, b) => b.startTime.localeCompare(a.startTime),
      );

      const newPatientAlerts = partitionAlertsByType(
        mapPatientAlertsByPatientId([
          { patientId: newAlert.patientId, alerts: [newAlert] },
        ]),
      );

      state.patientCurrentAlerts = mergeRecordsWithUniqueIds(
        state.patientCurrentAlerts,
        newPatientAlerts,
      );
    },
    clearAllPatientAlertsSuccess: (state, action: PayloadAction<Alert>) => {
      const newAlert = action.payload;
      const clearedPatientId = newAlert.patientId;
      const clearedAlerts: Alert[] = current(state.alerts)
        .filter(a => a.patientId === clearedPatientId && a.status !== 'OFF')
        .map(a => ({
          ...a,
          status: 'OFF',
          endTime: newAlert.endTime,
        }));

      state.alerts = mergeArraysWithUniqueIds(state.alerts, clearedAlerts).sort(
        (a, b) => b.startTime.localeCompare(a.startTime),
      );

      const newPatientAlerts = partitionAlertsByType(
        mapPatientAlertsByPatientId([
          { patientId: clearedPatientId, alerts: clearedAlerts },
        ]),
      );

      state.patientCurrentAlerts = mergeRecordsWithUniqueIds(
        state.patientCurrentAlerts,
        newPatientAlerts,
      );
    },
    gotAlertsFromAws: (
      state,
      action: PayloadAction<{
        alertsList: Alert[];
        alertsMap: AlertsMap;
      }>,
    ) => {
      const { alertsList, alertsMap } = action.payload;

      state.alerts = mergeArraysWithUniqueIds(state.alerts, alertsList).sort(
        (a, b) => b.startTime.localeCompare(a.startTime),
      );

      state.patientCurrentAlerts = mergeRecordsWithUniqueIds(
        state.patientCurrentAlerts,
        alertsMap,
      );
    },
    alertSoundsChanged: (state, action: PayloadAction<AlertSoundsMap>) => {
      state.alertSounds = mergeRecordsWithUniqueIds(
        state.alertSounds,
        action.payload,
      );
    },
    alertSoundsPlayed: (state, action: PayloadAction<AlertSoundsMap>) => {
      state.alertSounds = mergeRecordsWithUniqueIds(
        state.alertSounds,
        action.payload,
      );
    },
    setThresholdsStatus: (state, action: PayloadAction<LoadingStatus>) => {
      state.thresholdStatus = action.payload;
    },
    setBaselineThresholdsStatus: (
      state,
      action: PayloadAction<LoadingStatus>,
    ) => {
      state.baseLineThresholdStatus = action.payload;
    },
    getThresholdsListSuccess: (
      state,
      action: PayloadAction<AlertThreshold[]>,
    ) => {
      state.thresholds = mergeArraysWithUniqueIds(
        state.thresholds,
        action.payload,
      );
      state.thresholdStatus = DATA_FETCHING_STATUS.SUCCESS;
    },
    getThresholdsListFailed: state => {
      state.thresholdStatus = DATA_FETCHING_STATUS.ERROR;
    },
    createAlertThresholdSuccess: (
      state,
      action: PayloadAction<AlertThreshold>,
    ) => {
      state.thresholds = mergeArraysWithUniqueIds(state.thresholds, [
        action.payload,
      ]);
    },
    editAlertThresholdSuccess: (
      state,
      action: PayloadAction<AlertThreshold>,
    ) => {
      state.thresholds = mergeArraysWithUniqueIds(state.thresholds, [
        action.payload,
      ]);
    },
    getBaselineThresholdsListSuccess: (
      state,
      action: PayloadAction<BaselineAlertThreshold[]>,
    ) => {
      state.baselineThresholds = mergeArraysWithUniqueIds(
        state.baselineThresholds,
        action.payload,
      );
      state.baseLineThresholdStatus = DATA_FETCHING_STATUS.SUCCESS;
    },
    getBaselineThresholdsListFailed: state => {
      state.baseLineThresholdStatus = DATA_FETCHING_STATUS.ERROR;
    },
    createBaselineAlertThresholdSuccess: (
      state,
      action: PayloadAction<BaselineAlertThreshold>,
    ) => {
      state.baselineThresholds = mergeArraysWithUniqueIds(
        state.baselineThresholds,
        [action.payload],
      );
    },
    editBaselineAlertThresholdSuccess: (
      state,
      action: PayloadAction<BaselineAlertThreshold>,
    ) => {
      state.baselineThresholds = mergeArraysWithUniqueIds(
        state.baselineThresholds,
        [action.payload],
      );
    },
    getAlertSettingsSuccess: (state, action: PayloadAction<AlertSettings>) => {
      state.alertSettings.tenant = action.payload;
      state.alertsSettingsStatus = DATA_FETCHING_STATUS.SUCCESS;
    },
    updateAlertSettingsSuccess: (
      state,
      action: PayloadAction<AlertSettings>,
    ) => {
      state.alertSettings.tenant = action.payload;
      state.alertsSettingsStatus = DATA_FETCHING_STATUS.SUCCESS;
    },
    getAlertSettingsListSuccess: (
      state,
      action: PayloadAction<AlertSettings[]>,
    ) => {
      state.alertSettings.list = mergeArraysWithUniqueIds(
        state.alertSettings.list,
        action.payload,
      );
      state.alertsSettingsStatus = DATA_FETCHING_STATUS.SUCCESS;
    },
    updateAlertSettingsListSuccess: (
      state,
      action: PayloadAction<AlertSettings[]>,
    ) => {
      state.alertSettings.list = mergeArraysWithUniqueIds(
        state.alertSettings.list,
        action.payload,
      );
      state.alertsSettingsStatus = DATA_FETCHING_STATUS.SUCCESS;
    },
    getAlertsSettingsFailed: state => {
      state.alertsSettingsStatus = DATA_FETCHING_STATUS.ERROR;
    },
    onClearPatientAlertFinished: (
      state,
      action: PayloadAction<PacientIdPayload>,
    ) => {
      state.ongoingAlertProcesses.clear = {
        [action.payload]: false,
      };
    },
    onPatientMonitoringStopped: (state, action: PayloadAction<UUID>) => {
      const patientId = action.payload;
      delete state.patientCurrentAlerts[patientId];

      state.alerts = state.alerts.filter(
        alert => !(alert.patientId === patientId && alert.status !== 'OFF'),
      );
    },
    getPatientsBedExitAlertStatusSuccess: (
      state,
      action: PayloadAction<Record<UUID, boolean>>,
    ) => {
      state.isBedExitAlertEnabled = action.payload;
      state.alertTypesStatus = DATA_FETCHING_STATUS.SUCCESS;
    },
    getPatientsBedExitAlertStatusFailed: state => {
      state.alertTypesStatus = DATA_FETCHING_STATUS.ERROR;
    },
    setPatientBedExitAlertStatusSuccess(
      state,
      action: PayloadAction<PatientAlertTypeSettings>,
    ) {
      const { patientId, bedExitEnabled } = action.payload;
      state.isBedExitAlertEnabled[patientId] = bedExitEnabled || false;
    },
    setPatientBedExitAlertStatusFailed() {
      // TODO: Add set alert type status if needed
    },
    fetchAlertWithPersonalDetailsSuccess(
      state,
      action: PayloadAction<AlertWithPersonalDetails>,
    ) {
      state.alertWithPersonalDetails = {
        data: O.some(action.payload),
        apiStatus: API_STATUS.OK,
      };
    },
    fetchAlertWithPersonalDetailsFailed(state) {
      state.alertWithPersonalDetails = {
        data: O.none,
        apiStatus: API_STATUS.ERROR,
      };
    },
    toggleMuteAlert(state) {
      state.areAlertsMuted = !state.areAlertsMuted;
    },
    fetchActivityAlertSettingsSuccess(
      state,
      action: PayloadAction<ActivityAlertSettings>,
    ) {
      state.activityAlertSettings.data = action.payload;
      state.activityAlertSettings.extraProps.isLoading =
        DATA_FETCHING_STATUS.SUCCESS;
    },
    fetchActivityAlertSettingsFailed(state) {
      state.activityAlertSettings.extraProps.isLoading =
        DATA_FETCHING_STATUS.ERROR;
    },
  },
  extraReducers: {},
});

const getState = (state: RootState) =>
  state[DATA_STATE_KEY][STATE_KEY] || INITIAL_STATE;
const getPatientId = (_state: RootState, patientId: UUID) => patientId;
const getSingleAlertId = (_state: RootState, alertId: UUID) => alertId;
const getAlertId = (_state: RootState, _patientId: UUID, alertId: UUID) =>
  alertId;

export const selectors = {
  selectThresholds: createSelector(getState, state => state.thresholds),
  selectBaselineThresholds: createSelector(
    getState,
    state => state.baselineThresholds,
  ),
  getThresholdsStatus: createSelector(getState, state => state.thresholdStatus),
  areThresholdsLoading: createSelector(
    getState,
    state => state.thresholdStatus === DATA_FETCHING_STATUS.LOADING,
  ),
  getBaselineThresholdsStatus: createSelector(
    getState,
    state => state.baseLineThresholdStatus,
  ),
  getActivityAlertSettingsStatus: createSelector(
    getState,
    state => state.activityAlertSettings.extraProps.isLoading,
  ),
  areBaselineThresholdsLoading: createSelector(
    getState,
    state => state.baseLineThresholdStatus === DATA_FETCHING_STATUS.LOADING,
  ),
  getAlertsStatus: createSelector(getState, state => state.alertsStatus),
  areAlertsLoading: createSelector(
    getState,
    state => state.alertsStatus === DATA_FETCHING_STATUS.LOADING,
  ),
  areHistoricalAlertsLoading: createSelector(
    getState,
    state => state.historicalAlertsStatus === DATA_FETCHING_STATUS.LOADING,
  ),
  getMtmAttentionListStatus: createSelector(
    getState,
    state => state.mtmAttentionListStatus,
  ),
  selectAlertTypesStatus: createSelector(
    getState,
    state => state.alertTypesStatus,
  ),
  selectAlerts: createSelector(getState, state => state.alerts),
  selectAlertById: createSelector(
    getState,
    getSingleAlertId,
    (state, alertId) => state.alerts.find(a => a.id === alertId),
  ),
  selectPatientAlerts: createSelector(
    getState,
    state => state.patientCurrentAlerts,
  ),
  selectEnabledBedExitAlerts: createSelector(
    getState,
    state => state.isBedExitAlertEnabled,
  ),
  selectIsBedExitAlertActive: createSelector(
    getState,
    getPatientId,
    (state, patientId) =>
      state.patientCurrentAlerts[patientId]?.BED_EXIT?.status ===
        ALERT_STATUS_ENUM.ON || false,
  ),
  selectIsBedExitAlertEnabled: createSelector(
    getState,
    getPatientId,
    (state, patientId) => state.isBedExitAlertEnabled[patientId] || false,
  ),
  selectLongOutOfBedAlert: createSelector(
    getState,
    getPatientId,
    (state, patientId) =>
      state.patientCurrentAlerts[patientId]?.LONG_OUT_OF_BED,
  ),
  selectBedExitAlert: createSelector(
    getState,
    getPatientId,
    (state, patientId) => state.patientCurrentAlerts[patientId]?.BED_EXIT,
  ),
  getAlertsByPatientId: createSelector(
    getState,
    getPatientId,
    (state, patientId) => state.patientCurrentAlerts[patientId],
  ),
  selectOngoingAlertsByPatientId: createSelector(
    getState,
    getPatientId,
    (state, patientId) => {
      return R.filter<Alert>(alert => alert && alert.status !== 'OFF')(
        state.patientCurrentAlerts[patientId] || {},
      );
    },
  ),
  selectPatientAlert: createSelector(
    getState,
    getPatientId,
    getAlertId,
    (state, patientId, alertId) =>
      Object.values(state.patientCurrentAlerts[patientId] || {}).find(
        a => a.id === alertId,
      ),
  ),
  getAlertsSettings: createSelector(
    getState,
    state => state.alertSettings.tenant,
  ),
  getAlertsSettingsList: createSelector(
    getState,
    state => state.alertSettings.list,
  ),
  selectAlertSounds: createSelector(getState, state => state.alertSounds),
  selectOngoingClearAlertsByPacientId: createSelector(
    getState,
    getPatientId,
    (state, patientId) => state.ongoingAlertProcesses.clear[patientId] || false,
  ),
  selectAlertWithPersonalDetails: createSelector(
    getState,
    state => state.alertWithPersonalDetails,
  ),
  selectMTMAttentionList: createSelector(
    getState,
    state => state.mtmAttentionList,
  ),
  getAreAlertsMuted: createSelector(getState, state => state.areAlertsMuted),
  selectMTMAlertDeviceIds: createSelector(getState, state =>
    state.alerts.map(elem => elem.deviceId),
  ),
  selectActivityAlertSettings: createSelector(
    getState,
    state => state.activityAlertSettings.data,
  ),
};

const extraActions = {
  getThresholdsList: createAction(`${STATE_KEY}/getThresholdsList`),
  createAlert: createAction<
    Pick<AlertThreshold, 'enable' | 'metric' | 'preposition' | 'value'>
  >(`${STATE_KEY}/createAlert`),
  editAlert: createAction<Pick<AlertThreshold, 'id' | 'value' | 'enable'>>(
    `${STATE_KEY}/editAlert`,
  ),
  getBaselineThresholdsList: createAction(
    `${STATE_KEY}/getBaselineThresholdsList`,
  ),
  createBaselineAlertThreshold: createAction<
    Pick<
      BaselineAlertThreshold,
      | 'enable'
      | 'metric'
      | 'baselineDaysInterval'
      | 'deviationHoursInterval'
      | 'deviationPercentage'
    >
  >(`${STATE_KEY}/createBaselineThreshold`),
  updateBaselineAlertThreshold: createAction<
    { id: UUID } & Partial<
      Pick<
        BaselineAlertThreshold,
        | 'enable'
        | 'baselineDaysInterval'
        | 'deviationHoursInterval'
        | 'deviationPercentage'
      >
    >
  >(`${STATE_KEY}/updateBaselineThreshold`),
  deleteAlert: createAction<UUID>(`${STATE_KEY}/deleteAlert`),
  getPatientsBedExitAlertStatus: createAction<UUID[]>(
    `${STATE_KEY}/getPatientsBedExitAlertStatus`,
  ),
  setPatientBedExitAlertStatus: createAction<{
    patientId: UUID;
    isBedExitAlertEnabled: boolean;
  }>(`${STATE_KEY}/setPatientBedExitAlertStatus`),
  fetchPatientAlerts: createAction(`${STATE_KEY}/fetchPatientAlerts`),
  fetchHistoricalAlerts: createAction<UUID[]>(
    `${STATE_KEY}/fetchHistoricalAlerts`,
  ),
  playSoundForAlerts: createAction<SoundsToBePlayed>(
    `${STATE_KEY}/playSoundForAlerts`,
  ),
  suppressAlertType: createAction<UUID>(`${STATE_KEY}/suppressAlertType`),
  unSuppressAlertType: createAction<UUID>(`${STATE_KEY}/unSuppressAlertType`),
  clearAllPatientAlerts: createAction<UUID>(
    `${STATE_KEY}/clearAllPatientAlerts`,
  ),
  fetchAlertSettings: createAction(`${STATE_KEY}/fetchAlertSettings`),
  fetchAlertWithPersonalDetails: createAction<UUID>(
    `${STATE_KEY}/fetchAlertWithPersonalDetails`,
  ),
  fetchActivityAlertSettings: createAction(
    `${STATE_KEY}/fetchActivityAlertSettings`,
  ),
  fetchMTMActivityAlertSettings: createAction(
    `${STATE_KEY}/fetchMTMActivityAlertSettings`,
  ),
  fetchMTMAttentionList: createAction(`${STATE_KEY}/fetchMTMAttentionList`),
  updateActivityAlertSettings: createAction<SelectedLevelAlertSettings>(
    `${STATE_KEY}/updateActivityAlertSettings`,
  ),
  updateMTMActivityAlertSettings: createAction<SelectedLevelAlertSettings>(
    `${STATE_KEY}/updateMTMActivityAlertSettings`,
  ),
};

export const actions = { ...slice.actions, ...extraActions };

const { reducer } = slice;
export default reducer;
