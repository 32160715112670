import { all, call, put, takeLatest, select } from 'typed-redux-saga/macro';

import { actions, selectors } from './slice';
import { actions as gmActions } from 'src/redux/data/groupManager';
import { actions as userActions } from 'src/redux/data/user';
import { GM_DATA_KEYS } from 'src/redux/data/groupManager/modules/constants';
import MultiTenantService from 'src/services/MultiTenantService';
import {
  parseFormDataToInviteUserRequest,
  parseFormDataToUpdateUserPhoneRequest,
  parseFormDataToUpdateUserRequest,
} from 'src/redux/data/user/modules/parser';
import { MODAL_STATUS } from 'src/components/Modal/constants';
import { notifyUserByActionTypeAndCode } from 'src/utils/errorHandling/notifications';
import { selectors as loggedInUserSelectors } from 'src/redux/data/loggedInUser';

function* inviteUserByTenantIds(
  action: ReturnType<typeof actions.gmSettingsInviteUser>,
) {
  const formData = action.payload.formData;

  const tenantId = action.payload.selectedTenantId;

  try {
    yield* put(actions.setModalStatus(MODAL_STATUS.SUBMITTING));
    const request = parseFormDataToInviteUserRequest(formData) as unknown;
    yield* call(MultiTenantService.inviteUserByTenantId, request, [tenantId]);
    yield* put(gmActions.fetchSubtenantData({ dataKey: GM_DATA_KEYS.USERS }));
    yield* put(actions.setModalStatus(MODAL_STATUS.SUCCESS));
  } catch (error) {
    console.error('error in inviteUser: ', error);
    yield* put(actions.setModalStatus(MODAL_STATUS.ERROR));
  }
}

function* updateUserByTenantId(
  action: ReturnType<typeof actions.updateUserByTenantId>,
) {
  const { formData, selectedTenantId, userId } = action.payload;

  try {
    yield* put(actions.setModalStatus(MODAL_STATUS.SUBMITTING));
    const request = parseFormDataToUpdateUserRequest(formData) as unknown;
    yield* call(MultiTenantService.updateUserByTenantId, request, userId, [
      selectedTenantId,
    ]);
    yield* put(gmActions.fetchSubtenantData({ dataKey: GM_DATA_KEYS.USERS }));
    yield* put(actions.setModalStatus(MODAL_STATUS.SUCCESS));
  } catch (error) {
    console.error('error in updateUser: ', error);
    yield* put(actions.setModalStatus(MODAL_STATUS.ERROR));
  }
}

function* updateUserPhoneNumberByTenantId(
  action: ReturnType<typeof actions.updateUserPhoneNumberByTenantId>,
) {
  const { userId, selectedTenantId, formData } = action.payload;

  try {
    const request = parseFormDataToUpdateUserPhoneRequest(formData) as unknown;
    yield* call(MultiTenantService.updateUserByTenantId, request, userId, [
      selectedTenantId,
    ]);
    yield* put(gmActions.fetchSubtenantData({ dataKey: GM_DATA_KEYS.USERS }));
  } catch (error) {
    console.error('error in updateUserPhoneNumber: ', error);
  }
}

function* deleteInvitationByTenantId(
  action: ReturnType<typeof actions.deleteInvitationByTenantId>,
) {
  const { tenantId, tenantType, userId } = action.payload;

  if (!userId) {
    return;
  }

  if (tenantType === 'groupManager') {
    yield* put(userActions.deleteInvitation(userId));
    return;
  }

  try {
    yield* call(MultiTenantService.deleteInvitationForSubtenant, {
      [tenantId]: userId,
    });
    yield* put(gmActions.fetchSubtenantData({ dataKey: GM_DATA_KEYS.USERS }));
  } catch (error) {
    console.error('error in deleteInvitationUser: ', error);
    notifyUserByActionTypeAndCode(action.type, userId, error);
  }
}

function* deleteUserByTenantId(
  action: ReturnType<typeof actions.deleteUserByTenantId>,
) {
  const { tenantId, tenantType, userId } = action.payload;

  if (!userId) {
    return;
  }

  if (tenantType === 'groupManager') {
    yield* put(userActions.deleteUser(userId));

    return;
  }
  try {
    yield* call(MultiTenantService.deleteUserForSubtenant, {
      [tenantId]: userId,
    });
    yield* put(gmActions.fetchSubtenantData({ dataKey: GM_DATA_KEYS.USERS }));
  } catch (error) {
    console.error('error in deleteUser: ', error);
    notifyUserByActionTypeAndCode(action.type, userId, error);
  }
}

function* resendUserInvitation(
  action: ReturnType<typeof actions.resendUserInvitation>,
) {
  const { tenantId, tenantType, data } = action.payload;

  if (!data?.id) {
    return;
  }

  if (tenantType === 'groupManager') {
    yield* put(userActions.resendInvitation(data));

    return;
  }

  yield* call(MultiTenantService.resendInvitationForSubtenantUsers, {
    [tenantId]: data.id,
  });
}

function* updateTenantSubscribers(
  action: ReturnType<typeof actions.updateTenantSubscribers>,
) {
  const { tenantId, tenantType, data } = action.payload;

  if (!data) {
    return;
  }

  const { usersAssignedToEmail, usersAssignedToSMS } = data;

  const payload = {
    targetMedias: {
      EMAIL: {
        HR: usersAssignedToEmail.MEDICAL,
        RR: usersAssignedToEmail.MEDICAL,
        HR_BASELINE: usersAssignedToEmail.MEDICAL,
        RR_BASELINE: usersAssignedToEmail.MEDICAL,
        DEVICE_DISCONNECTED: usersAssignedToEmail.TECHNICAL,
        ALL_DEVICES_DISCONNECTED: usersAssignedToEmail.TECHNICAL,
        BED_EXIT: usersAssignedToEmail.ACTIVITY,
        BED_EXIT_FREQUENCY: usersAssignedToEmail.ACTIVITY,
        BED_TIME_BASELINE: usersAssignedToEmail.ACTIVITY,
        LONG_OUT_OF_BED: usersAssignedToEmail.ACTIVITY,
      },
      SMS: {
        HR: usersAssignedToSMS.MEDICAL,
        RR: usersAssignedToSMS.MEDICAL,
        HR_BASELINE: usersAssignedToSMS.MEDICAL,
        RR_BASELINE: usersAssignedToSMS.MEDICAL,
        DEVICE_DISCONNECTED: usersAssignedToSMS.TECHNICAL,
        ALL_DEVICES_DISCONNECTED: usersAssignedToSMS.TECHNICAL,
        BED_EXIT: usersAssignedToSMS.ACTIVITY,
        BED_EXIT_FREQUENCY: usersAssignedToSMS.ACTIVITY,
        BED_TIME_BASELINE: usersAssignedToSMS.ACTIVITY,
        LONG_OUT_OF_BED: usersAssignedToSMS.ACTIVITY,
      },
    },
  };

  if (tenantType === 'groupManager') {
    console.error('Cannot update alert subscribers for GM Users');

    return;
  }

  yield* put(
    gmActions.updateSubtenantSubscribersList({
      subtenantIds: [tenantId],
      data: payload,
    }),
  );
}

function* onTenantChange() {
  const selectedTenantId = yield* select(selectors.getSelectedTenantId);
  const tenantId = yield* select(loggedInUserSelectors.getCurrentTenantId);
  // make fetchSubtenantData server call just for subtenants, not for the current tenant
  if (selectedTenantId === tenantId) {
    yield* put(userActions.fetchAllUsers());
    return;
  }
  yield* put(
    gmActions.fetchSubtenantData({
      subtenantIds: selectedTenantId ? [selectedTenantId] : undefined,
      dataKey: GM_DATA_KEYS.USERS,
    }),
  );
  yield* put(
    gmActions.fetchSubtenantData({
      subtenantIds: selectedTenantId ? [selectedTenantId] : undefined,
      dataKey: GM_DATA_KEYS.SUBSCRIBERS,
    }),
  );
}

export default function* groupManagerSettingsActions() {
  yield* all([
    takeLatest(actions.setSelectedTenantId, onTenantChange),
    takeLatest(actions.gmSettingsInviteUser, inviteUserByTenantIds),
    takeLatest(actions.resendUserInvitation, resendUserInvitation),
    takeLatest(actions.updateTenantSubscribers, updateTenantSubscribers),
    takeLatest(actions.updateUserByTenantId, updateUserByTenantId),
    takeLatest(actions.deleteUserByTenantId, deleteUserByTenantId),
    takeLatest(actions.deleteInvitationByTenantId, deleteInvitationByTenantId),
    takeLatest(
      actions.updateUserPhoneNumberByTenantId,
      updateUserPhoneNumberByTenantId,
    ),
  ]);
}
