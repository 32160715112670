import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'src/redux/store';
import {
  actions as userActions,
  selectors as userSelectors,
} from 'src/redux/data/user';
import { selectors as loggedInUserSelector } from 'src/redux/data/loggedInUser';
import { selectors as subscriberSelectors } from 'src/redux/data/subscribers';
import { selectors as gmSelectors } from 'src/redux/data/groupManager';
import { API_STATUS } from 'src/utils/api-constants';
import { GM_DATA_KEYS } from 'src/redux/data/groupManager/modules/constants';
import { UUID } from 'src/types/utility';
import { extraSelectors, actions, selectors } from '../../modules/slice';
import { getTenantSubscribers, getTenantUserTableItems } from './utils';
import { extractUserSubscriptionsFromSubscribers } from 'src/redux/data/subscribers/modules/utils';

const mapStateToProps = (state: RootState) => {
  const gmTenantId = loggedInUserSelector.getUserTenantId(state);
  const gmUserIds = userSelectors
    .getUsersList(state)
    .reduce(
      (acc: UUID[], user) =>
        user.tenantId === gmTenantId ? [...acc, user.id] : acc,
      [],
    );
  const selectedTenantId =
    selectors.getSelectedTenantId(state) || gmTenantId || '';
  const allSubscribers = subscriberSelectors.selectSubscribersList(state);
  const tenantDataDict = gmSelectors.selectDataTenantDict(
    state,
    GM_DATA_KEYS.SUBSCRIBERS,
  );
  const tenantSubscribers = getTenantSubscribers(
    selectedTenantId,
    allSubscribers,
    tenantDataDict,
  );
  const tenantGmUserSubscriptions = extractUserSubscriptionsFromSubscribers(
    tenantSubscribers.filter(s => gmUserIds.includes(s.userId)),
  );

  return {
    usersTableData: getTenantUserTableItems(
      extraSelectors.selectUsersTableData(state),
      tenantSubscribers,
    ),
    selectedTenantId: selectedTenantId,
    loggedInUserId: loggedInUserSelector.getUserId(state),
    isGmTenant: selectedTenantId === gmTenantId,
    tenantGmUserSubscriptions,
    isLoadingUsers: userSelectors.getStatus(state) === API_STATUS.LOADING,
    areSubtenantUsersLoading:
      gmSelectors.selectDataStatus(state, GM_DATA_KEYS.USERS) ===
      API_STATUS.LOADING,
    areSubtenantSubscribersLoading:
      gmSelectors.selectDataStatus(state, GM_DATA_KEYS.SUBSCRIBERS) ===
      API_STATUS.LOADING,
    modalStatus: selectors.getModalStatus(state),
  };
};

const mapActionCreators = {
  updateUserPhoneNumber: userActions.updateUserPhone,
  updateUserPhoneNumberByTenantId: actions.updateUserPhoneNumberByTenantId,
  updateUser: userActions.updateUser,
  resendUserInvitation: actions.resendUserInvitation,
  updateTenantSubscribers: actions.updateTenantSubscribers,
  updateUserByTenantId: actions.updateUserByTenantId,
  setModalStatus: actions.setModalStatus,
  deleteUserByTenantId: actions.deleteUserByTenantId,
  deleteInvitationByTenantId: actions.deleteInvitationByTenantId,
  sendMessageForResetPassword: userActions.sendMessageForResetPassword,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
