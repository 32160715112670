import React from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';

import Search, { useSearch } from 'src/components/general-ui/Search';
import { handleClickSubtenantPatientName } from 'src/routes/GMDashboard/modules/utils';
import Connector, { PropsFromRedux } from './Connector';
import Sort, { useSort } from 'src/components/general-ui/Sort';
import AttentionListCard from '../../components/AttentionListCard';
import { messages, searchKeys, buildSorters } from './utils';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useInfiniteScrollProps } from 'src/utils/hooks/useInfiniteScrollProps';
import { AttentionListWrap, ListWrapper, StyledText } from '../../styled';

type Props = PropsFromRedux & {
  intl: IntlShape;
};

const AttentionList = ({ alertItems, intl }: Props): JSX.Element => {
  const sorters = buildSorters(intl);
  // @ts-ignore FIX ME LATER
  const [filteredItems, query, setQuery] = useSearch(alertItems, searchKeys);
  const [sortedItems, sortState, setSortState] = useSort(
    filteredItems,
    sorters,
  );
  const { noOfVisibleElements, fetchMoreData } = useInfiniteScrollProps();

  if (!alertItems.length) {
    return (
      <AttentionListWrap data-cy="alerts-sidebar-attention-list">
        <StyledText>
          <FormattedMessage {...messages.noAlertsRecieved} />
        </StyledText>
      </AttentionListWrap>
    );
  }

  if (!filteredItems.length) {
    return (
      <AttentionListWrap data-cy="alerts-sidebar-attention-list">
        <Search value={query} onChange={e => setQuery(e.target.value)} />
        <Sort
          sortState={sortState}
          sorters={sorters}
          setSortState={setSortState}
          className="sorting-flex"
        />
        <StyledText>
          <FormattedMessage {...messages.noAlertsMatched} />
        </StyledText>
      </AttentionListWrap>
    );
  }

  return (
    <AttentionListWrap data-cy="alerts-sidebar-attention-list">
      <Search value={query} onChange={e => setQuery(e.target.value)} />
      <Sort
        sortState={sortState}
        sorters={sorters}
        setSortState={setSortState}
        className="sorting-flex"
      />
      <ListWrapper id="attentionListScrollableDiv">
        <InfiniteScroll
          dataLength={sortedItems.slice(0, noOfVisibleElements).length} //This is important field to render the next data
          next={fetchMoreData}
          hasMore={sortedItems.length > noOfVisibleElements}
          loader={
            <StyledText>
              <FormattedMessage {...messages.loadingOnScroll} />
            </StyledText>
          }
          scrollableTarget="attentionListScrollableDiv"
          style={{ gap: '0.5rem' }}
        >
          {sortedItems.slice(0, noOfVisibleElements).map(alertItem => (
            <AttentionListCard
              key={alertItem.id}
              {...alertItem}
              onPatientNameClick={() =>
                alertItem.patientId &&
                handleClickSubtenantPatientName(
                  alertItem.tenantId,
                  alertItem.patientId,
                )
              }
            />
          ))}
        </InfiniteScroll>
      </ListWrapper>
    </AttentionListWrap>
  );
};

export default Connector(injectIntl(AttentionList));
