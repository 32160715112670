import { styled } from 'styled-components';
import { Avatar, Dropdown } from 'antd';

import { Row } from 'src/components/styled';

// App Layout Styles
export const StyledLayout = styled.div`
  display: flex;
  min-height: 100vh;
  width: calc(100vw - 20rem);
  flex-direction: column;
  flex-grow: 1;
`;

export const StyledHeader = styled.div`
  color: ${props => props.theme.colors.veryDarkGrey};
  z-index: 2;
  display: flex;
  align-content: center;
  box-shadow: 0px 3px 2px rgba(78, 83, 96, 0.15);
`;

export const StyledLogo = styled.img`
  height: 2rem;
  margin: 0.8rem 0rem 0.8rem 1.3rem;
`;

export const StyledNavigationMenu = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex: 1;
  margin: 0rem 3rem;
  line-height: 1.6rem;
`;

export const StyledContent = styled.div`
  height: 80%;
  background-color: #f4f7fa;
  flex: 1;
`;

// User Menu Styles

export const StyledDropdown: typeof Dropdown = styled(Dropdown)`
  margin: 0.8rem 1.3rem 0.8rem 0rem;
`;

export const StyledRow = styled(Row)`
  place-content: end;
  line-height: 0.75rem;
`;

export const DropdownItem = styled.div`
  display: inline-block;
  vertical-align: center;
  color: ${props => props.theme.colors.logoutButton};
  padding: 0 0.5rem;
  font-size: 0.7rem;
  width: 100%;
`;

export const StyledLink = styled.a`
  color: ${props => props.theme.colors.logoutButton} !important;
  padding: 0.6rem 0.5rem !important;
  font-size: 0.7rem;
  width: 100%;
  height: 100%;
`;

export const StyledUser = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-weight: bold;
`;

export const UserDetails = styled.div`
  display: block;
`;

export const StyledText = styled.div`
  white-space: nowrap;
  font-size: 0.8rem;
  margin-left: 0.4rem;
  font-family: ${props => props.theme.fonts.secondary};
`;

export const StyledSmallText = styled(StyledText)`
  font-size: 0.65rem;
`;

export const StyledAvatar: typeof Avatar = styled(Avatar)`
  flex: 1;
  background: ${props => props.theme.colors.layoutBackground};
  color: ${props => props.theme.colors.headerAvatarColor};
  width: 1.6rem;
  height: 1.6rem;
  line-height: 1.6rem;
  font-size: 0.9rem !important;
`;
