import { defineMessages, IntlShape } from 'react-intl';

import { Sorter } from 'src/components/general-ui/Sort';
import { sortWithEmptyString } from 'src/utils/sorters/utils';
import { AttentionListItem } from '../modules/types';

export const messages = defineMessages({
  noAlertsRecieved: {
    defaultMessage: 'No alerts in the last 24 hours.',
  },
  noAlertsMatched: {
    defaultMessage: 'No alerts match the filters.',
  },
  tenantSorter: {
    defaultMessage: 'Tenant',
  },
  rbSorter: {
    defaultMessage: 'Room/Bed',
  },
  alertCounterSorter: {
    defaultMessage: 'Alert',
  },
  patientNameSorter: {
    defaultMessage: 'Patient',
  },
  loadingOnScroll: {
    defaultMessage: 'Loading...',
  },
});

export const searchKeys: (keyof AttentionListItem)[] = [
  'deviceRoomName',
  'deviceBedName',
  'patientFirstName',
  'patientLastName',
  'tenantName',
];

export const buildSorters = (intl: IntlShape): Sorter<AttentionListItem>[] => [
  {
    key: 'time',
    label: intl.formatMessage(messages.tenantSorter),
    sort: (a, b) => a.tenantName.localeCompare(b.tenantName),
  },
  {
    key: 'roomBed',
    label: intl.formatMessage(messages.rbSorter),
    sort: (a, b) => {
      const roomOrder = sortWithEmptyString(
        a.deviceRoomName || '',
        b.deviceRoomName || '',
        { numeric: true },
      );

      if (roomOrder) {
        return roomOrder;
      }

      return sortWithEmptyString(a.deviceBedName, b.deviceBedName, {
        numeric: true,
      });
    },
  },
  {
    key: 'alertType',
    label: intl.formatMessage(messages.alertCounterSorter),
    sort: (a, b) => a.alertCounter - b.alertCounter,
  },
  {
    key: 'patient',
    label: intl.formatMessage(messages.patientNameSorter),
    sort: (a, b) => {
      const firstNameOrder = sortWithEmptyString(
        a.patientFirstName,
        b.patientFirstName,
        { numeric: true },
      );

      if (firstNameOrder) {
        return firstNameOrder;
      }

      return sortWithEmptyString(a.patientLastName, b.patientLastName, {
        numeric: true,
      });
    },
  },
];
