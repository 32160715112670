import dayjs from 'dayjs';
import { defineMessages, IntlShape } from 'react-intl';

import { AlertLog, BaselineAlert } from 'src/types/alerts';
import { createStringFromTemplate } from './general';

export const messages = defineMessages({
  high: {
    defaultMessage: 'High',
  },
  low: {
    defaultMessage: 'Low',
  },
  bpm: {
    defaultMessage: 'BPM',
  },
  brpm: {
    defaultMessage: 'BrPM',
  },
  hr: {
    defaultMessage: 'HR',
  },
  rr: {
    defaultMessage: 'RR',
  },
  alert: {
    defaultMessage: 'Alert',
  },
  anAverageOf: {
    defaultMessage: 'An average of',
  },
  hours: {
    defaultMessage: 'hours',
  },
  by: {
    defaultMessage: 'by',
  },
  orMore: {
    defaultMessage: 'or more',
  },
  since: {
    defaultMessage: 'since',
  },
  deviatesFromBaseline: {
    defaultMessage: 'deviates from',
  },
  baseline: {
    defaultMessage: 'baseline',
  },
  days: {
    defaultMessage: 'days',
  },
  bedExitDetails: {
    defaultMessage: 'BedExit Since',
  },
  unoccupied: {
    defaultMessage: 'Unoccupied',
  },
  hour: {
    defaultMessage: 'hour',
  },
  for: {
    defaultMessage: 'for',
  },
  bedTimeHoursDiffer: {
    defaultMessage: 'Bed-Time hours differs from last',
  },
  frequentBedExitsBetween: {
    defaultMessage: 'frequent Bed Exits between',
  },
  on: {
    defaultMessage: 'on',
  },
  and: {
    defaultMessage: 'and',
  },
  patient: {
    defaultMessage: 'Patient is out of bed for',
  },
});

const formatDuration = (duration: number): string => {
  if (duration < 60) {
    return `${duration} ${duration === 1 ? 'second' : 'seconds'}`;
  }

  const minutes = Math.floor(duration / 60);
  const seconds = duration % 60;

  return `${minutes}${
    seconds ? `:${seconds < 10 ? `0${seconds}` : seconds}` : ''
  } ${minutes > 1 ? 'minutes' : 'minute'}`;
};

const calculateSinceTime = (
  averageIntervalInSec: number,
  startTime: string,
): string =>
  dayjs(startTime).subtract(averageIntervalInSec, 'second').format('HH:mm');

export const getBaselineAlertDetails = (
  alert: Pick<
    BaselineAlert,
    | 'thresholdMetric'
    | 'thresholdPreposition'
    | 'deviationHours'
    | 'deviationValue'
    | 'baselineValue'
    | 'baselineDaysInterval'
    | 'deviationPercentage'
    | 'reminderCount'
    | 'startTime'
  >,
  intl: IntlShape,
): string => {
  const {
    thresholdMetric,
    thresholdPreposition,
    deviationHours,
    deviationValue,
    baselineValue,
    baselineDaysInterval,
    deviationPercentage,
    reminderCount,
    startTime,
  } = alert;
  const metricMessage =
    thresholdMetric === 'HR_BASELINE' ? messages.hr : messages.rr;
  const unitMessage =
    thresholdMetric === 'HR_BASELINE' ? messages.bpm : messages.brpm;

  const reminderCountText = reminderCount
    ? `| ${intl.formatMessage(messages.alert)} #${reminderCount}`
    : '';

  const sinceTime = deviationHours
    ? calculateSinceTime(Math.round(deviationHours * 60 * 60), startTime)
    : '';

  const isValidSinceTime = sinceTime !== 'Invalid Date';

  const details = createStringFromTemplate(
    `{prepositionIndicator} {metric} - {anAverageOf} {deviationHours} {hours} ({deviationValue} {unit}) {deviatesFromBaseline} {baselineDaysInterval} {days} {baseline} ({baselineValue} {unit}) {by} {deviationPercentage}% {orMore} {since} {sinceTime} {reminderCountText}`,
    {
      prepositionIndicator: intl.formatMessage(
        thresholdPreposition === 'ABOVE' ? messages.high : messages.low,
      ),
      metric: intl.formatMessage(metricMessage),
      anAverageOf: intl.formatMessage(messages.anAverageOf),
      hours: intl.formatMessage(messages.hours),
      deviationHours: deviationHours?.toString() || '',
      deviatesFromBaseline: intl.formatMessage(messages.deviatesFromBaseline),
      deviationValue: deviationValue
        ? Math.round(deviationValue).toString()
        : '',
      unit: intl.formatMessage(unitMessage),
      by: intl.formatMessage(messages.by),
      baseline: intl.formatMessage(messages.baseline),
      days: intl.formatMessage(messages.days),
      baselineValue: baselineValue ? Math.round(baselineValue).toString() : '',
      baselineDaysInterval: baselineDaysInterval
        ? Math.round(baselineDaysInterval).toString()
        : '',
      deviationPercentage: deviationPercentage
        ? Math.floor(Math.abs(deviationPercentage)).toString()
        : '',
      orMore: intl.formatMessage(messages.orMore),
      since: isValidSinceTime ? intl.formatMessage(messages.since) : '',
      sinceTime:
        isValidSinceTime && deviationHours
          ? calculateSinceTime(Math.round(deviationHours * 60 * 60), startTime)
          : '',
      reminderCountText: reminderCountText,
    },
  );

  return details;
};

export const getAlertLogDetails = (
  alert: AlertLog,
  intl: IntlShape,
): string => {
  const {
    triggerValue,
    thresholdMetric,
    thresholdPreposition,
    averageInterval,
    thresholdValue,
    averageValue,
    startTime,
    endTime,
  } = alert;

  if (!thresholdMetric || thresholdMetric === 'BED_EXIT') {
    return `${intl.formatMessage(messages.bedExitDetails)} ${dayjs(
      startTime,
    ).format('HH:mm')}`;
  }

  if (!thresholdMetric || thresholdMetric === 'LONG_OUT_OF_BED') {
    return `${intl.formatMessage(messages.patient)} ${triggerValue} ${intl.formatMessage(triggerValue === 1 ? messages.hour : messages.hours)} ${intl.formatMessage(messages.orMore)} ${intl.formatMessage(messages.since)} ${dayjs(
      startTime,
    ).format('HH:mm')}`;
  }

  if (!thresholdMetric || thresholdMetric === 'BED_EXIT_FREQUENCY') {
    const startDateStamp = dayjs(startTime).format('MM-DD-YYYY');
    const startTimeStamp = dayjs(startTime).format('HH:mm');
    const endDateStamp = endTime ? dayjs(endTime).format('MM-DD-YYYY') : '';
    const endTimeStamp = endTime ? dayjs(endTime).format('HH:mm') : '';

    return `${triggerValue} ${intl.formatMessage(messages.frequentBedExitsBetween)} ${startTimeStamp} ${intl.formatMessage(messages.on)} ${startDateStamp}  ${intl.formatMessage(messages.and)} ${endTimeStamp} ${intl.formatMessage(messages.on)} ${endDateStamp} ${intl.formatMessage(messages.since)} ${dayjs(
      startTime,
    ).format('HH:mm')}`;
  }

  if (thresholdMetric === 'HR_BASELINE' || thresholdMetric === 'RR_BASELINE') {
    return getBaselineAlertDetails(alert, intl);
  }

  if (!thresholdPreposition || !thresholdValue) {
    return '';
  }

  if (thresholdMetric === 'HR' || thresholdMetric === 'RR') {
    return `${thresholdMetric} - An average of ${formatDuration(
      averageInterval,
    )} is ${thresholdPreposition?.toLowerCase()} ${thresholdValue} ${intl.formatMessage(
      thresholdMetric === 'HR' ? messages.bpm : messages.brpm,
    )} (${Math.round(averageValue)}) 
      since ${calculateSinceTime(averageInterval, startTime)}`;
  }

  return '';
};
