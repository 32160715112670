// TODO: Group resources for ease of user and readability

import { UUID } from 'src/types/utility';

const DEFAULT_LIMIT = 1000000;
const DEFAULT_SORT_PROP = 'name';
const DEFAULT_SORT_ORDER = 'ASC';

export type Resource = {
  url: string;
  method: 'GET' | 'POST' | 'PUT' | 'PATCH' | 'DELETE';
  requireAuth: boolean;
};

export const resources = Object.freeze({
  loadConfig: {
    url: 'config/loadConfig/',
    method: 'GET',
    requireAuth: false,
  },
  getUsers: {
    url: 'ums/v1/admin/users/all',
    method: 'GET',
    requireAuth: true,
  },
  getDevices: {
    url: `device/v2/devices?limit=${DEFAULT_LIMIT}`,
    method: 'GET',
    requireAuth: true,
  },
  getUserDetails: {
    url: 'organization/v1/users/self',
    method: 'GET',
    requireAuth: true,
  },
  adminCreateDevice: {
    url: 'device/v2/admin/devices',
    method: 'POST',
    requireAuth: true,
  },
  createTenant: {
    url: 'organization/v2/tenants',
    method: 'POST',
    requireAuth: true,
  },
  getTenants: {
    url: `organization/v2/tenants?limit=${DEFAULT_LIMIT}&sort%5B0%5D.prop=${DEFAULT_SORT_PROP}&sort%5B0%5D.order=${DEFAULT_SORT_ORDER}`,
    method: 'GET',
    requireAuth: true,
  },
  login: {
    url: 'ums/v2/users/login',
    method: 'POST',
    requireAuth: false,
  },
  getMfaUsersData: {
    url: `ums/v2/users?limit=${DEFAULT_LIMIT}`,
    method: 'GET',
    requireAuth: true,
    cache: false,
  },
  getPatients: {
    url: `organization/v1/patients?limit=${DEFAULT_LIMIT}`,
    method: 'GET',
    requireAuth: true,
  },
  createPatient: {
    url: 'organization/v1/patients',
    method: 'POST',
    requireAuth: true,
  },
  deviceCredentials: {
    url: 'device/v2/credentials',
    method: 'POST',
    requireAuth: true,
  },
  getAllUsers: {
    url: `organization/v1/users?limit=${DEFAULT_LIMIT}`,
    method: 'GET',
    requireAuth: true,
    cache: false,
  },
  inviteUser: {
    url: 'organization/v1/invitations',
    method: 'POST',
    requireAuth: true,
  },
  getAllUserTypes: {
    url: 'organization/v1/user-types',
    method: 'GET',
    requireAuth: true,
  },
  createGroup: {
    url: '/update/v2/group',
    method: 'POST',
    requireAuth: true,
  },
  getTenant: {
    url: 'organization/v1/tenant',
    method: 'GET',
    requireAuth: true,
  },
  getVSAlertThresholds: {
    url: 'alert/v2/thresholds',
    method: 'GET',
    requireAuth: true,
  },
  getBaselineAlertThresholds: {
    url: 'alert/v2/baseline-thresholds',
    method: 'GET',
    requireAuth: true,
  },
  fetchPatientsCurrentAlerts: {
    url: 'alert/v1/alerts/current',
    method: 'GET',
    requireAuth: true,
  },
  fetchPatientsHistoricalAlerts: {
    url: 'alert/v1/alerts/history',
    method: 'GET',
    requireAuth: true,
  },
  fetchMTMAttentionList: {
    url: 'alert/v1/alerts/attention-list',
    method: 'GET',
    requireAuth: true,
  },
  createAlertThreshold: {
    url: 'alert/v1/thresholds/',
    method: 'POST',
    requireAuth: true,
  },
  createBaselineAlertThreshold: {
    url: 'alert/v1/baseline-thresholds/',
    method: 'POST',
    requireAuth: true,
  },
  updateActivityAlertSettings: {
    url: 'alert/v3/alerts/settings/activity',
    method: 'PUT',
    requireAuth: true,
  },
  updateMTMActivityAlertSettings: {
    url: 'alert/v3/alerts/settings/activity/mtm',
    method: 'PUT',
    requireAuth: true,
  },
  createMeasurementSettings: {
    url: 'telemetry/v1/settings',
    method: 'POST',
    requireAuth: true,
  },
  getMeasurementSettings: {
    url: 'telemetry/v1/settings',
    method: 'GET',
    requireAuth: true,
  },
  updateMeasurementSettings: {
    url: 'telemetry/v1/settings',
    method: 'PATCH',
    requireAuth: true,
  },
  getRooms: {
    url: 'organization/v2/room',
    method: 'GET',
    requireAuth: true,
  },
  createRoom: {
    url: 'organization/v2/room',
    method: 'POST',
    requireAuth: true,
  },
  getAlertsSettings: {
    url: '/alert/v2/alerts/settings',
    method: 'GET',
    requireAuth: true,
  },
  deleteAlertsSettings: {
    url: '/alert/v1/alerts/admin/settings',
    method: 'DELETE',
    requireAuth: true,
  },
  fetchActivityAlertSettings: {
    url: '/alert/v3/alerts/settings/activity',
    method: 'GET',
    requireAuth: true,
  },
  fetchMTMActivityAlertSettings: {
    url: '/alert/v3/alerts/settings/activity/mtm',
    method: 'GET',
    requireAuth: true,
  },
  editAlertsSettings: {
    url: '/alert/v1/alerts/admin/settings',
    method: 'PATCH',
    requireAuth: true,
  },
  getSubscribersList: {
    url: `alert/v2/subscribers`,
    method: 'GET',
    requireAuth: true,
  },
  updateSubscribersList: {
    url: 'alert/v1/subscribers',
    method: 'POST',
    requireAuth: true,
  },
  getInvitedUsers: {
    url: '/organization/v1/invitations',
    method: 'GET',
    requireAuth: true,
  },
  getMultiPatientAlertLogData: {
    url: `/alert/v1/alerts/logs`,
    method: 'GET',
    requireAuth: true,
  },
  getDisconnectionOverview: {
    url: `/device/v2/devices/continuously-disconnected`,
    method: 'GET',
    requireAuth: true,
  },
  fetchMTMDeviceConnectionInfo: {
    url: `/device/v2/devices/stats/continuously-disconnected/count`,
    method: 'GET',
    requireAuth: true,
  },
  fetchPatientsNoteCounterMetadata: {
    url: `alert/v1/patients/notes/count`,
    method: 'GET',
    requireAuth: true,
  },
  getCustomers: {
    url: `/organization/v2/customers`,
    method: 'GET',
    requireAuth: true,
  },
  createCustomer: {
    url: `/organization/v2/customers`,
    method: 'POST',
    requireAuth: true,
  },
  mapEmrFacilities: {
    url: `/integration/v1/emr/mapFacilities`,
    method: 'POST',
    requireAuth: true,
  },
  fetchMTMAlerts: {
    url: `alert/v1/alerts/current/mtm`,
    method: 'GET',
    requireAuth: true,
  },
  fetchMTMRooms: {
    url: `/organization/v2/room/mtm`,
    method: 'GET',
    requireAuth: true,
  },
  fetchMTMPatients: {
    url: `organization/v1/patients/mtm?limit=${DEFAULT_LIMIT}`,
    method: 'GET',
    requireAuth: true,
  },
}) satisfies Record<string, Resource>;

export type ResourceCreator = (...args: string[]) => Resource;

export const resourceCreators = {
  getUsersByUserType: filter => ({
    url: `organization/v1/users${filter}&limit=${DEFAULT_LIMIT}`,
    method: 'GET',
    requireAuth: true,
  }),
  getUploadUrl: versionId => ({
    url: `/update/v1/version/upload-url?versionId=${versionId}`,
    method: 'GET',
    requireAuth: true,
  }),
  adminGetDevices: pagination => {
    return {
      url: `device/v2/admin/devices${pagination}`,
      method: 'GET',
      requireAuth: true,
      cache: false,
    };
  },
  getGroups: pagination => {
    return {
      url: `/update/v2/group${pagination}`,
      method: 'GET',
      requireAuth: true,
    };
  },
  adminSearchAllDevices: searchParamString => {
    return {
      url: `device/v2/admin/devices${searchParamString}`,
      method: 'GET',
      requireAuth: true,
    };
  },
  getDeviceById: filter => ({
    url: `device/v2/devices${filter}`,
    method: 'GET',
    requireAuth: true,
  }),
  getAdminDevicesByTenantId: filter => ({
    url: `device/v2/admin/devices${filter}`,
    method: 'GET',
    requireAuth: true,
  }),
  updateDevice: manufacturerId => ({
    url: `device/v2/devices/${manufacturerId}`,
    method: 'PATCH',
    requireAuth: true,
  }),
  adminUpdateDevice: manufacturerId => ({
    url: `device/v2/admin/devices/${manufacturerId}`,
    method: 'PATCH',
    requireAuth: true,
  }),
  adminUpdateDeviceTenant: manufacturerId => ({
    url: `device/v2/admin/devices/${manufacturerId}/update-tenant-id`,
    method: 'PATCH',
    requireAuth: true,
    cache: false,
  }),
  adminDeleteDevice: manufacturerId => ({
    url: `device/v2/admin/devices/${manufacturerId}`,
    method: 'DELETE',
    requireAuth: true,
  }),
  getTenantsPaginated: pagination => ({
    url: `organization/v2/tenants${pagination}&sort%5B0%5D.prop=${DEFAULT_SORT_PROP}&sort%5B0%5D.order=${DEFAULT_SORT_ORDER}`,
    method: 'GET',
    requireAuth: true,
  }),
  searchAllTenants: searchParamString => ({
    url: `organization/v2/tenants${searchParamString}`,
    method: 'GET',
    requireAuth: true,
  }),
  searchGroups: searchParamString => ({
    url: `/update/v2/group${searchParamString}`,
    method: 'GET',
    requireAuth: true,
  }),
  deleteTenant: tenantId => ({
    url: `organization/v2/tenants/${tenantId}`,
    method: 'DELETE',
    requireAuth: true,
  }),
  updateTenant: tenantId => ({
    url: `organization/v2/tenants/${tenantId}`,
    method: 'PATCH',
    requireAuth: true,
  }),
  getSpotMeasurements: patientId => ({
    url: `telemetry/v1/spot/patients/${patientId}/measurements`,
    method: 'GET',
    requireAuth: true,
  }),
  getAllSessions: patientId => ({
    url: `telemetry/v1/continuous/patients/${patientId}/sessions`,
    method: 'GET',
    requireAuth: true,
  }),
  queryBySession: sessionId => ({
    url: `telemetry/v1/continuous/sessions/${sessionId}/measurements`,
    method: 'GET',
    requireAuth: true,
  }),
  queryByDateTime: patientId => ({
    url: `telemetry/v1/continuous/patients/${patientId}/measurements`,
    method: 'GET',
    requireAuth: true,
  }),
  queryGraphDataByDateTime: patientId => ({
    url: `telemetry/v1/continuous/patients/${patientId}/measurements/continuous`,
    method: 'GET',
    requireAuth: true,
  }),
  deletePatient: id => ({
    url: `organization/v1/patients/${id}`,
    method: 'DELETE',
    requireAuth: true,
  }),
  updatePatient: id => ({
    url: `organization/v1/patients/${id}`,
    method: 'PATCH',
    requireAuth: true,
  }),
  startContinuous: deviceId => ({
    url: `telemetry/v1/continuous/devices/${deviceId}/start`,
    method: 'POST',
    requireAuth: true,
  }),
  stopContinuous: deviceId => ({
    url: `telemetry/v1/continuous/devices/${deviceId}/stop`,
    method: 'PATCH',
    requireAuth: true,
  }),
  startSpot: deviceId => ({
    url: `telemetry/v1/spot/devices/${deviceId}/start`,
    method: 'POST',
    requireAuth: true,
  }),
  updateUser: userId => ({
    url: `organization/v1/users/${userId}`,
    method: 'PATCH',
    requireAuth: true,
  }),
  updateMfaUserInfo: userId => ({
    url: `/ums/v2/users/${userId}`,
    method: 'PATCH',
    requireAuth: true,
  }),
  deleteUser: userId => ({
    url: `organization/v1/users/${userId}`,
    method: 'DELETE',
    requireAuth: true,
  }),
  updateGroup: groupId => ({
    url: `update/v2/group/${groupId}`,
    method: 'PATCH',
    requireAuth: true,
  }),
  deleteGroup: groupId => ({
    url: `update/v1/group/${groupId}`,
    method: 'DELETE',
    requireAuth: true,
  }),
  getVersionByGroupId: groupId => ({
    url: `update/v1/group/${groupId}/versions`,
    method: 'GET',
    requireAuth: true,
  }),
  getGroupClients: () => ({
    url: 'update/v2/group/clients/all',
    method: 'GET',
    requireAuth: true,
  }),
  assignDevice: groupId => ({
    url: `update/v1/group/${groupId}/clients`,
    method: 'PUT',
    requireAuth: true,
  }),
  editAssignedDevices: groupId => ({
    url: `update/v2/group/${groupId}/clients`,
    method: 'PATCH',
    requireAuth: true,
  }),
  createVersion: versionId => ({
    url: `update/v1/version/${versionId}`,
    method: 'POST',
    requireAuth: true,
  }),
  updateVersion: versionId => ({
    url: `update/v1/version/${versionId}`,
    method: 'PATCH',
    requireAuth: true,
  }),
  getDefaultVersion: appId => ({
    url: `update/v1/application/${appId}`,
    method: 'GET',
    requireAuth: true,
  }),
  updateDefaultVersion: appId => ({
    url: `update/v1/application/${appId}`,
    method: 'PUT',
    requireAuth: true,
  }),
  getVersion: filterParams => ({
    url: `update/v1/version/${filterParams}`,
    method: 'GET',
    requireAuth: true,
  }),
  getVersionById: versionId => ({
    url: `update/v1/version/${versionId}`,
    method: 'GET',
    requireAuth: true,
  }),
  deleteDeviceCertificate: manufacturerId => ({
    url: `device/v2/devices/${manufacturerId}/certificate`,
    method: 'DELETE',
    requireAuth: true,
  }),
  getUserConsents: () => ({
    url: 'consent/v1/users/self/consents',
    method: 'GET',
    requireAuth: true,
  }),
  getConsent: consentId => ({
    url: `consent/v1/consents/${consentId}/locales/eng`,
    method: 'GET',
    requireAuth: true,
  }),
  getHriData: patientId => ({
    url: `telemetry/v1/hri/patients/${patientId}`,
    method: 'GET',
    requireAuth: true,
  }),
  getHriFile: url => ({
    url,
    method: 'GET',
    requireAuth: false,
  }),
  deleteVersion: versionId => ({
    url: `/update/v1/version/${versionId}`,
    method: 'DELETE',
    requireAuth: true,
  }),
  confirmConsent: () => ({
    url: 'consent/v1/users/self/consents/confirm',
    method: 'POST',
    requireAuth: true,
  }),
  getSessionsForDevice: deviceId => ({
    url: `telemetry/v1/admin/devices/${deviceId}/continuous/sessions`,
    method: 'GET',
    requireAuth: true,
  }),
  getSessionDataToDownload: sessionId => ({
    url: `telemetry/v1/admin/continuous/sessions/${sessionId}/all-measurements`,
    method: 'GET',
    requireAuth: true,
  }),
  resendInvitation: invitationId => ({
    url: `/organization/v1/invitations/${invitationId}/resend`,
    method: 'POST',
    requireAuth: true,
  }),
  deleteInvitation: invitationId => ({
    url: `/organization/v1/invitations/${invitationId}`,
    method: 'DELETE',
    requireAuth: true,
  }),
  getDeviceStatus: devicesId => ({
    url: `/telemetry/v1/devices/${devicesId}/status`,
    method: 'GET',
    requireAuth: true,
  }),
  getDevicesStatuses: devicesIds => ({
    url: `/telemetry/v1/devices/status/latest?filterMissing=true&deviceIds=${devicesIds}`,
    method: 'GET',
    requireAuth: true,
  }),
  getPatientsLatestSessions: patientIds => ({
    url: `/telemetry/v1/patients/sessions/latest?filterMissing=true${
      patientIds ? '&patientIds=' + patientIds : ''
    }`,
    method: 'GET',
    requireAuth: true,
  }),
  getAllLatestSessions: () => ({
    url: `/telemetry/v1/devices/status/latest?filterMissing=true`,
    method: 'GET',
    requireAuth: true,
    cache: false,
  }),
  deleteAlertThreshold: alertId => ({
    url: `alert/v1/thresholds/${alertId}`,
    method: 'DELETE',
    requireAuth: true,
  }),
  updateAlertThreshold: alertId => ({
    url: `alert/v1/thresholds/${alertId}`,
    method: 'PATCH',
    requireAuth: true,
  }),
  updateBaselineAlertThreshold: alertId => ({
    url: `alert/v1/baseline-thresholds/${alertId}`,
    method: 'PATCH',
    requireAuth: true,
  }),
  clearAlert: alertId => ({
    url: `/alert/v1/alerts/${alertId}/clear`,
    method: 'PATCH',
    requireAuth: true,
  }),
  // TODO check this for cleaning, seems it's not more used
  updateRoom: roomId => ({
    url: `/organization/v2/room/${roomId}`,
    method: 'PATCH',
    requireAuth: true,
  }),
  assignDeviceToBed: (bedId, deviceId) => ({
    url: `/organization/v2/room/bed/${bedId}?deviceId=${deviceId}`,
    method: 'PATCH',
    requireAuth: true,
  }),
  createBedInRoom: () => ({
    url: `/organization/v2/room/bed`,
    method: 'POST',
    requireAuth: true,
  }),
  deleteRoom: roomId => ({
    url: `/organization/v2/room/${roomId}`,
    method: 'DELETE',
    requireAuth: true,
  }),
  getHistogramData: (patientId, startTime, endTime) => ({
    url: `/telemetry/v1/continuous/patients/${patientId}/histogram?startTime=${startTime}&endTime=${endTime}&metrics=HR&metrics=RR`,
    method: 'GET',
    requireAuth: true,
  }),
  getSubscriberStatus: (userId, targetMedia, alertType) => ({
    url: `/alert/v1/subscribers/subscription?userId=${userId}&targetMedia=${targetMedia}&alertType=${alertType}`,
    method: 'GET',
    requireAuth: true,
  }),
  updateSubscribers: (userId = '') => ({
    url: `/alert/v1/subscribers/${userId}`,
    method: 'PATCH',
    requireAuth: true,
  }),
  getBedOccupancyData: (patientId, startTime, endTime) => ({
    url: `/alert/v1/alerts/bedoccupancies/${patientId}?startDateTime=${startTime}&endDateTime=${endTime}`,
    method: 'GET',
    requireAuth: true,
  }),
  getAlertsLogData: (patientId, startDate, endDate, pagination) => ({
    url: `/alert/v1/alerts/logs/${patientId}${pagination}&startDateTime=${startDate}&endDateTime=${endDate}`,
    method: 'GET',
    requireAuth: true,
  }),
  getPatientAlertTypesSettings: patientIds => ({
    url: `/alert/v1/thresholds/settings/patients?
      ${patientIds ? '?patientIds=' + patientIds : ''}
    `,
    method: 'GET',
    requireAuth: true,
  }),
  setBedExitAlertStatus: patientId => ({
    url: `/alert/v1/thresholds/settings/patients/${patientId}`,
    method: 'PATCH',
    requireAuth: true,
  }),
  suppressAlertType: alertId => ({
    url: `alert/v1/alerts/${alertId}/suppress`,
    method: 'PATCH',
    requireAuth: true,
  }),
  unSuppressAlertType: alertId => ({
    url: `alert/v1/alerts/${alertId}/un-suppress`,
    method: 'PATCH',
    requireAuth: true,
  }),
  clearAllPatientAlerts: alertId => ({
    url: `alert/v1/alerts/${alertId}/clear-all`,
    method: 'PATCH',
    requireAuth: true,
  }),
  getSubtenants: parentTenantId => ({
    url: `organization/v2/tenants/${parentTenantId}/sub-tenants?limit=${DEFAULT_LIMIT}`,
    method: 'GET',
    requireAuth: true,
  }),
  fetchPatientNotes: patientId => ({
    url: `alert/v1/patients/${patientId}/notes`,
    method: 'GET',
    requireAuth: true,
  }),
  createNote: patientId => ({
    url: `alert/v1/patients/${patientId}/notes`,
    method: 'POST',
    requireAuth: true,
  }),
  fetchAlertWithPersonalDetails: (alertId: UUID) => ({
    url: `alert/v1/alerts/report/alert-report-with-private-data/${alertId}`,
    method: 'GET',
    requireAuth: true,
  }),
  updateCustomer: (customerId: UUID) => ({
    url: `organization/v2/customers/${customerId}`,
    method: 'PATCH',
    requireAuth: true,
  }),
  deleteCustomer: (customerId: UUID) => ({
    url: `organization/v2/customers/${customerId}`,
    method: 'DELETE',
    requireAuth: true,
  }),
  getTenantsByCustomerId: (customerId: UUID) => ({
    url: `organization/v2/tenants/customer/${customerId}`,
    method: 'GET',
    requireAuth: true,
  }),
  getFacilitiesByEmrType: (customerId: UUID, emrType: string) => ({
    url: `integration/v1/emr/facilities/${customerId}/${emrType}`,
    method: 'GET',
    requireAuth: true,
  }),
} satisfies Record<string, ResourceCreator>;
