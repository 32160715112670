import { connect, ConnectedProps } from 'react-redux';
import * as A from 'fp-ts/lib/Array';
import * as R from 'fp-ts/lib/Record';
import * as Ord from 'fp-ts/lib/Ord';

import { selectors as alertSelectors } from 'src/redux/data/alerts';
import { RootState } from 'src/redux/store';
import { PatientAttentionItem, SubtenantAttentionList } from 'src/types/alerts';
import { UUID } from 'src/types/utility';
import dataSelectors from 'src/redux/data/dataSelectors';
import { selectors as patientSelectors } from 'src/redux/data/patient';
import { selectors as tenantSelectors } from 'src/redux/data/tenant';
import { Patient } from 'src/types/patients';
import { DeviceIdWithRoomAndBed } from 'src/types/devices';
import { ListTenant } from 'src/types/tenants';
import {
  AttentionListItem,
  MassDeviceDisconnectionItem,
  PatientListItem,
} from '../modules/types';

const processAttentionListItems = (
  mtmAttentionList: Record<UUID, SubtenantAttentionList>,
  patients: Patient[],
  deviceRoomAndBedList: DeviceIdWithRoomAndBed[],
  tenants: ListTenant[],
): AttentionListItem[] => {
  const patientAttentionListItems = A.flatten(
    R.collect(Ord.trivial)<UUID, SubtenantAttentionList, PatientListItem[]>(
      (tenantId, subtenantAttentionList) => {
        const tenant = tenants.find(t => t.id === tenantId);

        return R.collect(Ord.trivial)<
          UUID,
          PatientAttentionItem,
          PatientListItem
        >((patientId, patientAttentionList) => {
          const { roomName, bedName } =
            deviceRoomAndBedList.find(
              item => item.deviceId === patientAttentionList.deviceId,
            ) || {};
          const { lastName, firstName } =
            patients.find(patient => patient.id === patientId) || {};

          return {
            id: patientId,
            type: 'PATIENT_ALERTS',
            patientId,
            deviceId: patientAttentionList.deviceId,
            tenantId: tenantId,
            tenantName: tenant?.name || '',
            alertCounter: patientAttentionList.alertsCount,
            deviceRoomName: roomName,
            deviceBedName: bedName,
            patientFirstName: firstName || '--',
            patientLastName: lastName || '--',
            timestamp: patientAttentionList.timestamp,
            notes: patientAttentionList?.notesCount || {
              counters: {
                regular: 0,
                important: 0,
              },
            },
          };
        })(subtenantAttentionList.medical.byPatientId);
      },
    )(mtmAttentionList),
  );

  const disconnectionAttentionListItems = A.flatten(
    R.collect(Ord.trivial)<
      UUID,
      SubtenantAttentionList,
      MassDeviceDisconnectionItem[]
    >((tenantId, subtenantAttentionList) => {
      const tenant = tenants.find(t => t.id === tenantId);

      return subtenantAttentionList.technical.alerts.map(alert => ({
        type: 'MASS_DISCONNECTION',
        id: alert.id,
        tenantId,
        tenantName: tenant?.name || '',
        alertCounter: 1,
        alert,
        tenant,
        patientId: null,
        deviceId: null,
        deviceRoomName: undefined,
        deviceBedName: undefined,
        patientFirstName: undefined,
        patientLastName: undefined,
        timestamp: alert.endTime || '',
      }));
    })(mtmAttentionList),
  );

  return [
    ...patientAttentionListItems,
    ...disconnectionAttentionListItems,
  ].sort((a, b) => b.timestamp.localeCompare(a.timestamp));
};

const mapStateToProps = (state: RootState) => ({
  alertItems: processAttentionListItems(
    alertSelectors.selectMTMAttentionList(state),
    patientSelectors.getPatientsList(state),
    dataSelectors.selectMTMAttentionListDeviceRoomAndBed(state),
    tenantSelectors.getTenantsList(state),
  ),
});

const mapActionCreators = {};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;

export default Connector;
