import { defineMessages, FormattedMessage, IntlShape } from 'react-intl';
import { ALERT_SETTINGS_TAB_KEYS, AlertSettingsTabType } from './types';
import CombinedSettings from './CombinedSettings';
import { Select } from 'antd';
import { SubtenantItem } from 'src/routes/GMDashboard/modules/types';
import {
  StyledOptionLabel,
  StyledOptionLabelTag,
} from 'src/routes/GMDashboard/components/SubtenantCards/SubtenantSettings/AlertSettings/CombinedSettings/styled';
import {
  typeToColor,
  typeToMessage,
} from 'src/routes/GMDashboard/components/SubtenantCards/SubtenantSettings/AlertSettings/CombinedSettings/constants';
import React from 'react';
import {
  ALL_DISCONNECTIONS,
  ONLY_ASSIGNED_DEVICES,
} from 'src/components/AlertSettingsComponents/DisconnectionAlertsSettingsForm/constants';
import ConnectivityAlertsSettings from 'src/routes/GMDashboard/components/SubtenantCards/SubtenantSettings/AlertSettings/ConnectivityAlerts';
import ActivityAlerts from 'src/components/AlertSettingsComponents/ActivityAlerts/Container';
import permissions from 'src/permissions';
const messages = defineMessages({
  medicalAlerts: {
    defaultMessage: 'Medical Alerts',
  },
  activityAlerts: {
    defaultMessage: 'BedSight Alerts',
  },
  connectivityAlerts: {
    defaultMessage: 'Connectivity Alerts',
  },
  allDisconnectedDevices: {
    defaultMessage: 'All Devices',
  },
  assignedDisconnectedDevices: {
    defaultMessage: 'Patient Assigned Only',
  },
});
export const ALERT_SETTINGS_TABS: AlertSettingsTabType[] = [
  {
    key: ALERT_SETTINGS_TAB_KEYS.MEDICAL_ALERTS,
    labelMessage: messages.medicalAlerts,
    component: CombinedSettings,
  },
  {
    key: ALERT_SETTINGS_TAB_KEYS.ACTIVITY_ALERTS,
    labelMessage: messages.activityAlerts,
    component: ActivityAlerts,
    editablePermissions: [
      permissions.ACTIVITY_ALERT_SETTINGS_CREATE_UPDATE_MTM,
    ],
  },
  {
    key: ALERT_SETTINGS_TAB_KEYS.CONNECTIVITY_ALERTS,
    labelMessage: messages.connectivityAlerts,
    component: ConnectivityAlertsSettings,
  },
];
export const DEFAULT_TAB: AlertSettingsTabType['key'] =
  ALERT_SETTINGS_TAB_KEYS.MEDICAL_ALERTS;

export const getDeviceConnectionAlertOptions = (intl: IntlShape) => [
  {
    id: 'allDisconnected',
    label: intl.formatMessage(messages.allDisconnectedDevices),
    value: ALL_DISCONNECTIONS,
  },
  {
    id: 'assignedDisconnected',
    label: intl.formatMessage(messages.assignedDisconnectedDevices),
    value: ONLY_ASSIGNED_DEVICES,
  },
];

const { Option } = Select;
export const customOptionRender = ({ value, label, type }: SubtenantItem) => (
  <Option value={value} label={label} key={value}>
    <StyledOptionLabel>{label}</StyledOptionLabel>
    <StyledOptionLabelTag color={typeToColor[type]}>
      <FormattedMessage {...typeToMessage[type]} />
    </StyledOptionLabelTag>
  </Option>
);
