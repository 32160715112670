import styled from 'styled-components';

import FormModal from 'src/components/general-ui/FormModal';
import Icon from 'src/components/general-ui/Icon';

export const StyledCollectiveSettingsIcon = styled(Icon)`
  width: 0.95rem;
  height: 1.05rem;
  cursor: pointer;
`;

export const StyledIndividualSettingsIcon = styled(Icon)`
  width: 1rem;
  height: 1.1rem;
  cursor: pointer;
`;

export const StyledModal = styled(FormModal)`
  .ant-modal-content {
    width: 26.85rem;
  }
`;
