import { connect, ConnectedProps } from 'react-redux';

import {
  selectors as emrMappingsSelectors,
  actions as emrMappingsActions,
} from 'src/redux/data/emrMappings';
import {
  selectors as tenantDataSelectors,
  actions as tenantDataActions,
} from 'src/redux/data/tenant';
import { RootState } from 'src/redux/store';
import { DATA_STRATEGY } from 'src/utils/constants';

const mapStateToProps = (state: RootState) => {
  const customerTenantsList = emrMappingsSelectors.selectCustomerTenants(state);

  const facilitiesList = emrMappingsSelectors.selectFacilties(state);

  const facilitiesDict = facilitiesList?.reduce((acc, curr) => {
    const mappedTenant = customerTenantsList?.find(
      tenant => tenant.emrId === curr.id,
    );
    return {
      ...acc,
      [curr.id]: {
        name: curr.facilityName,
        parentId: null,
        tenantId: null,
        strategy: mappedTenant ? DATA_STRATEGY.MAPPED : DATA_STRATEGY.SKIP,
      },
    };
  }, {});

  const mtmTenantsList = tenantDataSelectors
    .getTenantsList(state)
    .filter(tenants => tenants.group);

  return {
    mtmTenantsList: mtmTenantsList || [],
    customerTenantsList: customerTenantsList || [],
    facilitiesList: facilitiesList || [],
    facilityInitialDict: facilitiesDict || {},
    isLoadingCustomerTenants:
      emrMappingsSelectors.isLoadingCustomerTenants(state),
    isLoadingFacilities: emrMappingsSelectors.isLoadingFacilities(state),
  };
};

const mapActionCreators = {
  getCustomerTenants: emrMappingsActions.getCustomerTenants,
  getFacilities: emrMappingsActions.getFacilities,
  getTenants: tenantDataActions.getTenantsList,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
