import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import {
  NurseStationDevice,
  DeviceAlerts,
} from 'src/routes/NursesStation/modules/types';
import Connector, { PropsFromRedux } from './Connector';
import DisplayValue from './DisplayValue';
import {
  BedExitAlertWrapper,
  DeviceMeasurementsWrapper,
  MeasurementBox,
  StyledTitle,
  StyledTitleMetric,
  StyledTitleUnit,
  StyledValue,
} from './styled';
import {
  getDeviceAlertDetails,
  getDisplayContinuousValue,
  getMeasurementBoxColors,
  getVSColorByQuality,
  isDeviceGatheringData,
} from './utils';
import { StyledGatherInfoIcon } from 'src/components/PatientStatusComponent/styled';
import { useTheme } from 'styled-components';
import { deepEqual } from 'src/utils/comparators';

type Props = PropsFromRedux &
  Pick<NurseStationDevice, 'isDeviceActive' | 'continuousData'> & {
    isUnoccupied: boolean;
    ongoingDeviceAlerts: DeviceAlerts;
  };

const DeviceMeasurements = ({
  continuousData,
  isDeviceActive,
  isUnoccupied,
  ongoingDeviceAlerts,
  shouldDisplayHighQualityOnly,
}: Props): JSX.Element => {
  const theme = useTheme();
  const { latestHrAlert, latestRrAlert, occupancyAlert, longOutOfBed } =
    ongoingDeviceAlerts;
  const isBedExit =
    isUnoccupied && occupancyAlert && occupancyAlert.status !== 'OFF';
  const isLongOutOfBedAlert = longOutOfBed?.id;

  if (isBedExit || isLongOutOfBedAlert) {
    const backgroundColor =
      occupancyAlert?.status === 'ON' || longOutOfBed?.status === 'ON'
        ? theme.colors.alertBackground
        : '#FAF3E2';
    const borderColor =
      occupancyAlert?.status === 'ON' || longOutOfBed?.status === 'ON'
        ? theme.colors.alertBorder
        : '#FFAD0D';

    return (
      <BedExitAlertWrapper background={backgroundColor} border={borderColor}>
        {isBedExit && <FormattedMessage {...messages.vacancyAlertText} />}
        {isLongOutOfBedAlert && (
          <>
            <FormattedMessage
              {...messages.longOutOfBedAlertText}
              values={{ triggerValue: longOutOfBed?.triggerValue }}
            />
            <FormattedMessage {...messages.hour} />
          </>
        )}
      </BedExitAlertWrapper>
    );
  }

  const {
    currentHrValue,
    currentHrQuality,
    currentRrValue,
    currentRrQuality,
    currentPatientStateValue,
  } = continuousData || {};
  const continuousValues = {
    HR: getDisplayContinuousValue(
      currentHrValue,
      currentHrQuality,
      shouldDisplayHighQualityOnly,
      'HR',
    ),
    isHrGatheringData: isDeviceGatheringData(
      currentHrValue,
      currentHrQuality,
      currentPatientStateValue,
      shouldDisplayHighQualityOnly,
    ),
    RR: getDisplayContinuousValue(
      currentRrValue,
      currentRrQuality,
      shouldDisplayHighQualityOnly,
      'RR',
    ),
    isRrGatheringData: isDeviceGatheringData(
      currentRrValue,
      currentRrQuality,
      currentPatientStateValue,
      shouldDisplayHighQualityOnly,
    ),
  };

  const alertDetails = {
    HR: getDeviceAlertDetails(isDeviceActive, latestHrAlert),
    RR: getDeviceAlertDetails(isDeviceActive, latestRrAlert),
  };
  const {
    backgroundColor: hrBackgroundColor,
    borderColor: hrBorderColor,
    titleColor: hrTitleColor,
    valueColor: hrValueColor,
  } = getMeasurementBoxColors(isDeviceActive, latestHrAlert?.status, theme);
  const {
    backgroundColor: rrBackgroundColor,
    borderColor: rrBorderColor,
    titleColor: rrTitleColor,
    valueColor: rrValueColor,
  } = getMeasurementBoxColors(isDeviceActive, latestRrAlert?.status, theme);

  return (
    <DeviceMeasurementsWrapper>
      <MeasurementBox
        backgroundColor={hrBackgroundColor}
        borderColor={hrBorderColor}
        isLeft
      >
        <StyledTitle>
          <StyledTitleMetric color={hrTitleColor}>
            <FormattedMessage {...messages.hr} />
          </StyledTitleMetric>
          <StyledTitleUnit color={hrTitleColor}>
            <FormattedMessage {...messages.bpm} />
          </StyledTitleUnit>
        </StyledTitle>
        <StyledValue
          color={getVSColorByQuality(
            continuousValues.HR,
            !!currentHrQuality,
            hrValueColor,
          )}
          isGatheringInfo={continuousValues.isHrGatheringData}
        >
          <DisplayValue {...alertDetails.HR}>
            {continuousValues.isHrGatheringData ? (
              <StyledGatherInfoIcon type={'gathering-data-icon'} />
            ) : (
              <> {continuousValues.HR} </>
            )}
          </DisplayValue>
        </StyledValue>
      </MeasurementBox>

      <MeasurementBox
        backgroundColor={rrBackgroundColor}
        borderColor={rrBorderColor}
      >
        <StyledTitle>
          <StyledTitleMetric color={rrTitleColor}>
            <FormattedMessage {...messages.rr} />
          </StyledTitleMetric>
          <StyledTitleUnit color={rrTitleColor}>
            <FormattedMessage {...messages.brpm} />
          </StyledTitleUnit>
        </StyledTitle>
        <StyledValue
          color={getVSColorByQuality(
            continuousValues.RR,
            !!currentRrQuality,
            rrValueColor,
          )}
          isGatheringInfo={continuousValues.isRrGatheringData}
        >
          <DisplayValue {...alertDetails.RR}>
            {continuousValues.isRrGatheringData ? (
              <StyledGatherInfoIcon type={'gathering-data-icon'} />
            ) : (
              <> {continuousValues.RR} </>
            )}
          </DisplayValue>
        </StyledValue>
      </MeasurementBox>
    </DeviceMeasurementsWrapper>
  );
};

const messages = defineMessages({
  hr: {
    defaultMessage: 'HR',
  },
  rr: {
    defaultMessage: 'RR',
  },
  bpm: {
    defaultMessage: '(BPM)',
  },
  brpm: {
    defaultMessage: '(BRPM)',
  },
  vacancyAlertText: {
    defaultMessage: 'BedExit',
  },
  longOutOfBedAlertText: {
    defaultMessage: 'Out of bed for {triggerValue}',
  },
  hour: {
    defaultMessage: 'h',
  },
});

export default Connector(
  React.memo(DeviceMeasurements, (oldProps, newProps) =>
    deepEqual(oldProps, newProps),
  ),
);
