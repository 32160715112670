import React from 'react';
import { FormattedMessage } from 'react-intl';

import { SubTenantOverview } from 'src/routes/GMDashboard/modules/types';
import { handleClickTenantName } from 'src/routes/GMDashboard/modules/utils';
import { updateText } from 'src/utils/stringUtils';
import DevicesStatistics from './DevicesStatistics';
import IndividualSubtenantSettings from './IndividualSubtenantSettings';
import PatientsStatistics from './PatientsStatistics';
import {
  CardWrapper,
  CardHeaderWrapper,
  StyledTenantName,
  StyledTenantInfo,
  StyledTimezoneOffset,
} from './styled';
import { parseUtcOffsetToHours } from 'src/utils/timeUtils';
import { Tooltip } from 'antd';
import { ToolTipMessages } from 'src/translations/messages/toolTipMessages';

type Props = SubTenantOverview;

const SubtenantCard = (props: Props): JSX.Element => {
  const {
    id,
    name,
    address,
    timeZoneId,
    totalDevicesCounter,
    disconnectedAssignedDevicesCounter,
    disconnectedUnassignedDevicesCounter,
    connectedAssignedDevicesCounter,
    connectedUnassignedDevicesCounter,
    lastDayAlertsCounter,
    hasActiveAlert,
  } = props;

  const locationText = `${address.city || ''}${updateText(
    ', ',
    address.state,
  )}${updateText(', ', address.countryCode)}`;
  const timezoneText = ' UTC ';

  return (
    <CardWrapper>
      <CardHeaderWrapper>
        <StyledTenantName onClick={() => handleClickTenantName(id)}>
          <Tooltip
            title={
              <FormattedMessage {...ToolTipMessages.MtMtenantCardsTenantName} />
            }
          >
            {name}
          </Tooltip>
        </StyledTenantName>
        <IndividualSubtenantSettings subtenantId={id} />
      </CardHeaderWrapper>
      <StyledTenantInfo>
        {`${locationText}${timezoneText}`}
        <StyledTimezoneOffset>
          {parseUtcOffsetToHours(timeZoneId)}
        </StyledTimezoneOffset>
      </StyledTenantInfo>
      <DevicesStatistics
        id={id}
        name={name}
        totalDevicesCounter={totalDevicesCounter}
        disconnectedAssignedDevicesCounter={disconnectedAssignedDevicesCounter}
        disconnectedUnassignedDevicesCounter={
          disconnectedUnassignedDevicesCounter
        }
      />
      <PatientsStatistics
        id={id}
        name={name}
        lastDayAlertsCounter={lastDayAlertsCounter}
        hasActiveAlert={hasActiveAlert}
        connectedAssignedDevicesCounter={connectedAssignedDevicesCounter}
        connectedUnassignedDevicesCounter={connectedUnassignedDevicesCounter}
      />
    </CardWrapper>
  );
};

export default SubtenantCard;
