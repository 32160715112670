import { all, takeLatest, call, put, select } from 'typed-redux-saga/macro';
import { PayloadAction } from '@reduxjs/toolkit';

import BackendService from 'src/services/BackendService';

import { UUID } from 'src/types/utility';
import {
  notifyUserByActionTypeAndCode,
  actionSuccessNotification,
} from 'src/utils/errorHandling/notifications';
import { actions as settingsActions } from 'src/routes/Settings';
import {
  actions as monitorActions,
  selectors as monitorSelectors,
} from 'src/redux/data/monitor';
import { API_DATE_FORMAT, nowUTC } from 'src/utils/timeUtils';
import dataActions from '../../dataActions';
import { actions } from './slice';
import { createSoundMap, mapCurrentAlerts } from './utils';
import { selectors as loggedInUserSelectors } from 'src/redux/data/loggedInUser';
import { hasEditOrCreateAlertThresholdsPermissions } from 'src/utils/permissions';
import { DATA_FETCHING_STATUS } from 'src/redux/data/constants';
import { getAlertLogsUpdated } from 'src/utils/alertHelpers';
import { Alert } from 'src/types/alerts';

export function* fetchAlerts(patientIds: UUID[]) {
  try {
    yield* put(actions.setAlertsStatus(DATA_FETCHING_STATUS.LOADING));
    const {
      data: { alerts: currentAlertsList },
    } = yield* call(BackendService.fetchPatientsCurrentAlerts, patientIds);

    const patientCurrentAlerts = mapCurrentAlerts(currentAlertsList);

    yield* put(
      actions.fetchAlertsSuccess({
        alertsList: currentAlertsList,
        alertsMap: patientCurrentAlerts,
      }),
    );
    yield* put(
      actions.alertSoundsChanged(createSoundMap(patientCurrentAlerts)),
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('error in fetchVSAlerts: ', e);
    yield* put(actions.fetchAlertsFailed());
  }
}

export function* fetchHistoricalAlerts(
  action: ReturnType<typeof actions.fetchHistoricalAlerts>,
) {
  const endTime = nowUTC();
  const startTime = endTime.subtract(1, 'd');
  try {
    yield* put(actions.setHistoricalAlertsStatus(DATA_FETCHING_STATUS.LOADING));
    const {
      data: { alerts: alertsList },
    } = yield* call(
      BackendService.fetchPatientsHistoricalAlerts,
      action.payload,
      startTime.format(API_DATE_FORMAT),
      endTime.format(API_DATE_FORMAT),
    );
    yield* put(actions.fetchHistoricalAlertsSuccess(alertsList));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('error in fetchHistoricalAlerts: ', e);
    yield* put(actions.fetchAlertsFailed());
  }
}

export function* fetchMTMAttentionList() {
  const endTime = nowUTC();
  const startTime = endTime.subtract(1, 'd');
  try {
    yield* put(actions.setMTMAttentionListStatus(DATA_FETCHING_STATUS.LOADING));
    const { data } = yield* call(
      BackendService.fetchMTMAttentionList,
      startTime.format(API_DATE_FORMAT),
      endTime.format(API_DATE_FORMAT),
    );

    yield* put(actions.fetchMTMAttentionListSuccess(data));
    yield* put(actions.setMTMAttentionListStatus(DATA_FETCHING_STATUS.SUCCESS));
  } catch (e) {
    // eslint-disable-next-line no-console
    yield* put(actions.setMTMAttentionListStatus(DATA_FETCHING_STATUS.ERROR));
    console.error('error in fetchMTMAttentionList: ', e);
  }
}

export function* suppressAlertType(
  action: ReturnType<typeof actions.suppressAlertType>,
) {
  try {
    const { data } = yield* call(
      BackendService.suppressAlertType,
      action.payload,
    );
    const timezone = yield* select(loggedInUserSelectors.getUserTenantTimezone);
    const updatedAlerts = getAlertLogsUpdated([data], timezone) as Alert[];
    if (!updatedAlerts || !updatedAlerts[0]) {
      return;
    }
    yield* put(actions.suppressAlertTypeSuccess(updatedAlerts[0]));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('error in suppressAlertType: ', e);
  }
}

export function* unSuppressAlertType(
  action: ReturnType<typeof actions.unSuppressAlertType>,
) {
  try {
    const { data } = yield* call(
      BackendService.unSuppressAlertType,
      action.payload,
    );
    const timezone = yield* select(loggedInUserSelectors.getUserTenantTimezone);
    const updatedAlerts = getAlertLogsUpdated([data], timezone) as Alert[];
    if (!updatedAlerts || !updatedAlerts[0]) {
      return;
    }
    yield* put(actions.unSuppressAlertTypeSuccess(updatedAlerts[0]));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('error in unSuppressAlertType: ', e);
  }
}

export function* clearAllPatientAlerts(
  action: ReturnType<typeof actions.clearAllPatientAlerts>,
) {
  try {
    const { data } = yield* call(
      BackendService.clearAllPatientAlerts,
      action.payload,
    );
    yield* put(actions.clearAllPatientAlertsSuccess(data));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('error in clearAllPatientAlerts: ', e);
  }
}

export function* fetchAlertSettings() {
  try {
    const { data: alertSettings } = yield* call(
      BackendService.getAlertsSettings,
    );
    const loggedInTenantId = yield* select(
      loggedInUserSelectors.getCurrentTenantId,
    );
    if (!loggedInTenantId) {
      return;
    }
    const currentTenantAlertSettings =
      alertSettings.tenantAlertSettingsMap[loggedInTenantId];

    if (currentTenantAlertSettings) {
      yield* put(actions.getAlertSettingsSuccess(currentTenantAlertSettings));
    }
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error in fetchAlertSettings: ', error);
    yield* put(actions.getAlertsSettingsFailed());
  }
}

function* getPatientsBedExitAlertStatus(
  action: ReturnType<typeof actions.getPatientsBedExitAlertStatus>,
) {
  try {
    const patientIds = action.payload;
    const {
      data: { alertsTypes },
    } = yield* call(
      BackendService.getPatientAlertTypesSettings,
      patientIds.toString(),
    );
    const mappedBedExitStatuses = alertsTypes.reduce(
      (acc, { patientId, bedExitEnabled }) => ({
        ...acc,
        [patientId]: bedExitEnabled || false,
      }),
      {},
    );
    yield* put(
      actions.getPatientsBedExitAlertStatusSuccess(mappedBedExitStatuses),
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('error in getBedExitAlertStatus: ', e);
    yield* put(actions.getPatientsBedExitAlertStatusFailed());
  }
}

function* setPatientBedExitAlertStatus(
  action: ReturnType<typeof actions.setPatientBedExitAlertStatus>,
) {
  try {
    const { patientId, isBedExitAlertEnabled } = action.payload;
    const { data: patientAlertTypes } = yield* call(
      BackendService.setBedExitAlertStatus,
      patientId,
      { bedExitEnabled: isBedExitAlertEnabled },
    );
    yield* put(actions.setPatientBedExitAlertStatusSuccess(patientAlertTypes));
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('error in setPatientBedExitAlertStatusStatus: ', e);
    yield* put(actions.setPatientBedExitAlertStatusFailed());
  }
}

export function* fetchAlertThresholds() {
  try {
    const {
      data: { tenantIdToThresholds },
    } = yield* call(BackendService.getVSAlertThresholds);

    yield* put(actions.setThresholdsStatus(DATA_FETCHING_STATUS.LOADING));

    const loggedInTenantId = yield* select(
      loggedInUserSelectors.getCurrentTenantId,
    );

    if (!loggedInTenantId) {
      yield* put(actions.setThresholdsStatus(DATA_FETCHING_STATUS.SUCCESS));
      return;
    }

    yield* put(
      actions.getThresholdsListSuccess(
        tenantIdToThresholds[loggedInTenantId]?.thresholds || [],
      ),
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('error in fetchAlertThresholds: ', e);
    yield* put(actions.getThresholdsListFailed());
  }
}

function* createAlertThreshold(action: ReturnType<typeof actions.createAlert>) {
  const { value, enable, metric, preposition } = action.payload;

  if (!hasEditOrCreateAlertThresholdsPermissions()) {
    return;
  }
  try {
    yield* call(BackendService.createAlertThreshold, {
      value,
      enable,
      preposition,
      metric,
    });
    actionSuccessNotification(action.type, null);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error in createAlertThreshold: ', error);
    notifyUserByActionTypeAndCode(action.type, metric, error);
  } finally {
    yield* call(fetchAlertThresholds);
  }
}

function* updateAlertThreshold(action: ReturnType<typeof actions.editAlert>) {
  const { id, value, enable } = action.payload;

  if (!hasEditOrCreateAlertThresholdsPermissions()) {
    return;
  }
  try {
    yield* call(BackendService.updateAlertThreshold, id, {
      value,
      enable,
    });
    actionSuccessNotification(action.type, null);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error in updateAlertThreshold: ', error);
    notifyUserByActionTypeAndCode(action.type, value, error);
  } finally {
    yield* call(fetchAlertThresholds);
  }
}

function* deleteAlertThreshold(action: ReturnType<typeof actions.deleteAlert>) {
  const alertId = action.payload;
  try {
    yield* call(BackendService.deleteAlertThreshold, alertId);
    yield* call(fetchAlertThresholds);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error in deleteAlertThreshold: ', error);
    notifyUserByActionTypeAndCode(action.type, alertId, error);
  }
}

export function* fetchBaselineAlertThresholds() {
  try {
    const {
      data: { tenantBaselineThresholds },
    } = yield* call(BackendService.getBaselineAlertThresholds);

    yield* put(
      actions.setBaselineThresholdsStatus(DATA_FETCHING_STATUS.LOADING),
    );

    const loggedInTenantId = yield* select(
      loggedInUserSelectors.getCurrentTenantId,
    );
    if (!loggedInTenantId) {
      yield* put(
        actions.setBaselineThresholdsStatus(DATA_FETCHING_STATUS.SUCCESS),
      );
      return;
    }

    yield* put(
      actions.getBaselineThresholdsListSuccess(
        tenantBaselineThresholds[loggedInTenantId]?.thresholds ?? [],
      ),
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error('error in getBaselineThresholdsListSuccess: ', e);
    yield* put(actions.getBaselineThresholdsListFailed());
  }
}

function* createBaselineAlertThreshold(
  action: ReturnType<typeof actions.createBaselineAlertThreshold>,
) {
  const {
    enable,
    metric,
    baselineDaysInterval,
    deviationHoursInterval,
    deviationPercentage,
  } = action.payload;

  if (!hasEditOrCreateAlertThresholdsPermissions()) {
    return;
  }

  try {
    yield* call(BackendService.createBaselineAlertThreshold, {
      enable,
      metric,
      baselineDaysInterval,
      deviationHoursInterval,
      deviationPercentage,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error in createBaselineAlertThreshold: ', error);
  } finally {
    yield* call(fetchBaselineAlertThresholds);
  }
}

function* updateActivityAlertSettings(
  action: ReturnType<typeof actions.updateActivityAlertSettings>,
) {
  try {
    yield* call(BackendService.updateActivityAlertSettings, action.payload);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error in updateActivityAlertSettings: ', error);
  } finally {
    yield* call(fetchActivityAlertSettings);
    yield* put(actions.getPatientsBedExitAlertStatus([]));
  }
}

function* updateMTMActivityAlertSettings(
  action: ReturnType<typeof actions.updateActivityAlertSettings>,
) {
  try {
    yield* call(BackendService.updateMTMActivityAlertSettings, action.payload);
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error in updateActivityAlertSettings: ', error);
  } finally {
    yield* call(fetchMTMActivityAlertSettings);
  }
}

function* updateBaselineAlertThreshold(
  action: ReturnType<typeof actions.updateBaselineAlertThreshold>,
) {
  const {
    id,
    enable,
    baselineDaysInterval,
    deviationHoursInterval,
    deviationPercentage,
  } = action.payload;

  if (!hasEditOrCreateAlertThresholdsPermissions()) {
    return;
  }

  try {
    yield* call(BackendService.updateBaselineAlertThreshold, id, {
      enable,
      baselineDaysInterval,
      deviationHoursInterval,
      deviationPercentage,
    });
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error('error in updateBaselineAlertThreshold: ', error);
  } finally {
    yield* call(fetchBaselineAlertThresholds);
  }
}

function* onPatientMonitoringStopped(
  action: PayloadAction<{ deviceId: UUID }>,
) {
  // TODO: Check if it can be array
  // const deviceId = Array.isArray(action.payload)
  //   ? action.payload[0]
  //   : action.payload;
  const { deviceId } = action.payload;
  // @ts-ignore Ignore untill monitor selector is integrated with TS
  const patientId = yield* select(monitorSelectors.getPatientId, deviceId);

  if (!patientId) {
    return;
  }

  yield* put(actions.onPatientMonitoringStopped(patientId));
}

function* getAlertWithPersonalDetails(action: PayloadAction<UUID>) {
  try {
    const { data } = yield* call(
      BackendService.fetchAlertWithPersonalDetails,
      action.payload,
    );

    yield* put(actions.fetchAlertWithPersonalDetailsSuccess(data));
  } catch (e) {
    console.error('error in getAlertWithPersonalDetails: ', e);

    yield* put(actions.fetchAlertWithPersonalDetailsFailed());
  }
}

function* fetchActivityAlertSettings() {
  try {
    const { data } = yield* call(BackendService.fetchActivityAlertSettings);
    yield* put(
      actions.fetchActivityAlertSettingsSuccess(data.activityAlertSettings),
    );
  } catch (e) {
    console.error('error in fetchActivityAlertSettings: ', e);
    yield* put(actions.fetchActivityAlertSettingsFailed());
  }
}
function* fetchMTMActivityAlertSettings() {
  try {
    const { data } = yield* call(BackendService.fetchMTMActivityAlertSettings);
    yield* put(
      actions.fetchActivityAlertSettingsSuccess(data.activityAlertSettings),
    );
  } catch (e) {
    console.error('error in fetchActivityAlertSettings: ', e);
    yield* put(actions.fetchActivityAlertSettingsFailed());
  }
}

export default function* watchAlertsActions() {
  yield* all([
    takeLatest(monitorActions.onSessionStopped, onPatientMonitoringStopped),

    takeLatest(actions.createAlert, createAlertThreshold),
    takeLatest(actions.editAlert, updateAlertThreshold),
    takeLatest(actions.deleteAlert, deleteAlertThreshold),

    takeLatest(actions.getBaselineThresholdsList, fetchBaselineAlertThresholds),
    takeLatest(
      actions.createBaselineAlertThreshold,
      createBaselineAlertThreshold,
    ),
    takeLatest(
      actions.updateBaselineAlertThreshold,
      updateBaselineAlertThreshold,
    ),

    takeLatest(actions.fetchHistoricalAlerts, fetchHistoricalAlerts),
    takeLatest(actions.fetchMTMAttentionList, fetchMTMAttentionList),
    takeLatest(actions.suppressAlertType, suppressAlertType),
    takeLatest(actions.unSuppressAlertType, unSuppressAlertType),
    takeLatest(actions.clearAllPatientAlerts, clearAllPatientAlerts),
    takeLatest(
      actions.getPatientsBedExitAlertStatus,
      getPatientsBedExitAlertStatus,
    ),
    takeLatest(
      actions.setPatientBedExitAlertStatus,
      setPatientBedExitAlertStatus,
    ),

    takeLatest(dataActions.onLoadAlertsModal, fetchAlertThresholds),
    takeLatest(actions.getThresholdsList, fetchAlertThresholds),
    takeLatest(settingsActions.onSettingsPageMounted, fetchAlertThresholds),

    takeLatest(actions.fetchAlertSettings, fetchAlertSettings),
    takeLatest(settingsActions.onSettingsPageMounted, fetchAlertSettings),
    takeLatest(dataActions.onLoadNursesStationPage, fetchAlertSettings),
    takeLatest(dataActions.monitorPageMounted, fetchAlertSettings),

    takeLatest(
      actions.fetchAlertWithPersonalDetails,
      getAlertWithPersonalDetails,
    ),
    takeLatest(actions.fetchActivityAlertSettings, fetchActivityAlertSettings),
    takeLatest(
      actions.fetchMTMActivityAlertSettings,
      fetchMTMActivityAlertSettings,
    ),
    takeLatest(
      actions.updateActivityAlertSettings,
      updateActivityAlertSettings,
    ),
    takeLatest(
      actions.updateMTMActivityAlertSettings,
      updateMTMActivityAlertSettings,
    ),
  ]);
}
