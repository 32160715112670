import React from 'react';
import { styled } from 'styled-components';
import Icon from '../Icon';
import { Sorter, SorterDisplay, SortOrder, SortState, useSort } from './utils';

const NEXT_SORTING: Record<SortOrder, SortOrder> = Object.freeze({
  ASC: 'DESC',
  DESC: 'DEFAULT',
  DEFAULT: 'ASC',
});

const SortWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  margin-top: 0.6rem;
  padding: 0rem 0.5rem;

  font-family: ${props => props.theme.fonts.primary};
  font-style: normal;
  font-weight: 400;
  font-size: 0.6rem;
  color: #767881;
`;

const SortItem = styled.div`
  cursor: pointer;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
`;

const StyledIcon = styled(Icon)`
  margin-left: 0.3rem;
  height: 0.55rem;
  width: 0.55rem;
`;

interface Props {
  sorters: SorterDisplay[];
  sortState: SortState;
  setSortState: React.Dispatch<React.SetStateAction<SortState>>;
  className?: string;
}

const Sort = ({
  sorters,
  sortState,
  setSortState,
  className,
}: Props): JSX.Element => {
  const { key: sortKey, order: sortOrder } = sortState;

  return (
    <SortWrapper className={className}>
      {sorters.map(({ key, label }) => (
        <div
          key={key}
          onClick={() =>
            setSortState({
              key,
              order: key === sortKey ? NEXT_SORTING[sortOrder] : 'ASC',
            })
          }
        >
          <SortItem>
            <span>{label}</span>
            <StyledIcon
              type="sort-icon"
              sortOrder={sortKey === key ? sortOrder : 'DEFAULT'}
            />
          </SortItem>
        </div>
      ))}
    </SortWrapper>
  );
};

export default Sort;
export { useSort };
export type { SorterDisplay, SortOrder, SortState, Sorter };
