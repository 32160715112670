import { RouterLocation } from 'connected-react-router';
import qs from 'qs';
import { LocationProps } from './types';
import { LOCAL_STORAGE_ITEMS } from 'src/utils/constants';
import { SoundQueryParams, redirectRoutes, searchParams } from './constants';

export const appRoutes = Object.freeze({
  LOGIN: 'login',
  FORGOT_PASSWORD: 'forgot-password',
  SET_PASSWORD: 'set-password',
  ACCEPT_INVITATION: 'accept-invitation',
  TENANTS: 'tenants',
  CUSTOMERS: 'customers',
  DEVICES: 'devices',
  MONITOR: 'monitor',
  PATIENTS: 'patients',
  USERS: 'users',
  GROUPS: 'groups',
  VERSIONS: 'versions',
  MANAGEMENT: 'management',
  NURSES_STATION: 'nurses-station',
  INDIVIDUAL_DASHBOARD: 'individual-dashboard',
  SETTINGS: 'settings',
  SUPPRESS_ALERTS: 'suppress-alert',
  DASHBOARD: 'dashboard',
  GROUP_MANAGER_USERS: 'group-manager-users',
  PERSONAL_ALERT_DETAILS: 'personal-alert-details',
});

export const validateRedirectRoute = (
  location = {} as RouterLocation<unknown>,
) => {
  const {
    from: { search = '', pathname = '' },
  } = (location?.state as LocationProps) || {};

  if (!pathname && !search) {
    return { pathname: '', search: '' };
  }

  const queryParams = qs.parse(search, {
    ignoreQueryPrefix: true,
  });

  const validatedPathname = redirectRoutes.find(route => route === pathname)
    ? pathname
    : '';

  const validatedQueryParam = searchParams.every(qp =>
    Object.hasOwn(queryParams, qp),
  )
    ? search
    : '';

  return { pathname: validatedPathname, search: validatedQueryParam };
};

export type SoundQueryParamsType = {
  sound: string;
  repetitions: string;
  delay: string;
};

export const validateSoundQueryParams = (
  queryParams = {} as SoundQueryParamsType,
) => Object.keys(queryParams).some(qs => SoundQueryParams.includes(qs));

export const setSoundQueryParams = (
  queryParams = {} as SoundQueryParamsType,
) => {
  if (validateSoundQueryParams(queryParams))
    Object.keys(queryParams).forEach((qs: string) => {
      const key = qs as keyof SoundQueryParamsType;

      if (queryParams[key] && LOCAL_STORAGE_ITEMS[key]) {
        localStorage.setItem(LOCAL_STORAGE_ITEMS[key], queryParams[key]);
      }
    });
};

export type RouteKeys = keyof typeof appRoutes;
export type RouteValues = (typeof appRoutes)[RouteKeys];
