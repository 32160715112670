import React from 'react';
import { defineMessages, IntlShape } from 'react-intl';

import { sortWithEmptyString } from 'src/utils/sorters/utils';
import AlertLogTypeCell from 'src/components/AlertLogTypeCell';
import { TableColumnConfig } from 'src/types/table';
import { AlertLogTableItem } from './types';

const messages = defineMessages({
  startTime: {
    defaultMessage: 'Start Time',
  },
  endTime: {
    defaultMessage: 'End Time',
  },
  alertType: {
    defaultMessage: 'Alert type',
  },
  details: {
    defaultMessage: 'Details',
  },
  bpm: {
    defaultMessage: 'BPM',
  },
  brpm: {
    defaultMessage: 'BRPM',
  },
});

export const getAlertsLogColumns = (
  intl: IntlShape,
): TableColumnConfig<AlertLogTableItem>[] => [
  {
    title: intl.formatMessage(messages.startTime),
    dataIndex: 'startTime',
    key: 'startTime',
    filtered: true,
    sorter: (a, b) => sortWithEmptyString(a.startTime, b.startTime),
  },
  {
    title: intl.formatMessage(messages.endTime),
    dataIndex: 'endTime',
    key: 'endTime',
    filtered: true,
    sorter: (a, b) => sortWithEmptyString(a.endTime, b.endTime),
  },
  {
    title: intl.formatMessage(messages.alertType),
    dataIndex: 'thresholdMetric',
    key: 'thresholdMetric',
    render: (_, record) => (
      <AlertLogTypeCell
        thresholdMetric={record.thresholdMetric}
        thresholdPreposition={record.thresholdPreposition}
        status={record.status}
      />
    ),
    sorter: (a, b) =>
      sortWithEmptyString(
        a.thresholdMetric + (a.thresholdPreposition || ''),
        b.thresholdMetric + (b.thresholdPreposition || ''),
      ),
  },
  {
    title: intl.formatMessage(messages.details),
    dataIndex: 'details',
    key: 'details',
    className: 'details-column',
  },
];
