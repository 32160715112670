import React from 'react';
import { defineMessages } from 'react-intl';
import { DatePicker } from 'antd';
import dayjs, { Dayjs, ManipulateType } from 'dayjs';

import { DATE_TYPE_ENUM } from 'src/utils/graphsUtils/graphConstants';
import { DATE_TIME_FORMAT, parseInTimezone } from 'src/utils/timeUtils';
import { StatisticsDateUnitTypes } from 'src/routes/IndividualDashboard/modules/statistics/types';
import DateTypeButton from './DateTypeButton';

import {
  RightArrow,
  LeftArrow,
  StyledDateTypePicker,
  DatePickerWithArrows,
  ButtonsContainer,
} from './styled';
import { TIME_CALC_OPTIONS } from './constants';
import { _dateTypeOfTopDatePicker } from './utils';

type Props = {
  dateType: StatisticsDateUnitTypes;
  selectedDate: Dayjs;
  timezone: string;
  onChangeDate: (startDate: Dayjs, endDate: Dayjs) => void;
  onChangeDateType: (
    newDateType: StatisticsDateUnitTypes,
    date?: string,
  ) => void;
};

const DateTypePicker = ({
  dateType,
  selectedDate,
  onChangeDate,
  onChangeDateType,
  timezone,
}: Props): JSX.Element => {
  const momentDate = dayjs(selectedDate, DATE_TIME_FORMAT);

  const dateTypeToMomemtDateType = {
    [DATE_TYPE_ENUM.DATE]: 'd',
    [DATE_TYPE_ENUM.MONTH]: 'M',
    [DATE_TYPE_ENUM.YEAR]: 'y',
  };

  const computeNextDate = (
    date: Dayjs,
    dateTypeOfPicker: StatisticsDateUnitTypes,
  ): Dayjs =>
    date.add(1, dateTypeToMomemtDateType[dateTypeOfPicker] as ManipulateType);

  const computePrevDate = (
    date: Dayjs,
    dateTypeOfPicker: StatisticsDateUnitTypes,
  ): Dayjs =>
    date.subtract(
      1,
      dateTypeToMomemtDateType[dateTypeOfPicker] as ManipulateType,
    );

  const onArrowClicked = (
    timeCalcOption: keyof typeof TIME_CALC_OPTIONS,
    dateTypeOfPicker: StatisticsDateUnitTypes | null,
  ) => {
    if (!dateTypeOfPicker) {
      return;
    }

    const newDate =
      timeCalcOption === TIME_CALC_OPTIONS.ADD
        ? computeNextDate(selectedDate, dateTypeOfPicker)
        : computePrevDate(selectedDate, dateTypeOfPicker);

    const startTime = newDate.startOf(dateTypeOfPicker);
    const endTime = newDate.endOf(dateTypeOfPicker);

    onChangeDate(startTime, endTime);
  };

  const isDateInfuture = (dateTypeOfPicker: StatisticsDateUnitTypes | null) => {
    if (!dateTypeOfPicker) {
      return;
    }
    return (
      computeNextDate(selectedDate, dateTypeOfPicker).valueOf() > Date.now()
    );
  };

  // TODO: Change this to moment.Moment if its true
  const _onChangeDate = (newDate: Dayjs) => {
    const startTime = parseInTimezone(newDate, timezone).startOf(dateType);
    const endTime = parseInTimezone(newDate, timezone).endOf(dateType);

    onChangeDate(startTime, endTime);
  };

  return (
    <StyledDateTypePicker>
      <DatePickerWithArrows>
        <LeftArrow
          onClick={() =>
            onArrowClicked(
              TIME_CALC_OPTIONS.SUB,
              _dateTypeOfTopDatePicker(dateType),
            )
          }
        />

        <DatePicker
          picker={dateType}
          onChange={_onChangeDate}
          value={momentDate}
          showTime={false}
          onOk={_onChangeDate}
          allowClear={false}
          disabledDate={(date: Dayjs) => date && date.valueOf() > Date.now()}
        />
        <RightArrow
          disabled={isDateInfuture(_dateTypeOfTopDatePicker(dateType))}
          onClick={() =>
            onArrowClicked(
              TIME_CALC_OPTIONS.ADD,
              _dateTypeOfTopDatePicker(dateType),
            )
          }
        />
      </DatePickerWithArrows>
      <ButtonsContainer>
        <DateTypeButton
          isSelected={dateType === DATE_TYPE_ENUM.DATE}
          message={messages.day}
          onChangeDateType={() => onChangeDateType(DATE_TYPE_ENUM.DATE)}
        />
        <DateTypeButton
          isSelected={dateType === DATE_TYPE_ENUM.MONTH}
          message={messages.month}
          onChangeDateType={() => onChangeDateType(DATE_TYPE_ENUM.MONTH)}
        />
        <DateTypeButton
          isSelected={dateType === DATE_TYPE_ENUM.YEAR}
          message={messages.year}
          onChangeDateType={() => onChangeDateType(DATE_TYPE_ENUM.YEAR)}
        />
      </ButtonsContainer>
    </StyledDateTypePicker>
  );
};
const messages = defineMessages({
  minute: {
    defaultMessage: 'Min',
  },
  hour: {
    defaultMessage: 'H',
  },
  day: {
    defaultMessage: 'D',
  },
  month: {
    defaultMessage: 'Mon',
  },
  year: {
    defaultMessage: 'Y',
  },
});

export default DateTypePicker;
