import { DeviceAlerts } from 'src/routes/NursesStation/modules/types';
import { Alert, AlertStatus } from 'src/types/alerts';

export const checkIfAlertStatusIs =
  (status: AlertStatus) =>
  (a: Alert | undefined): boolean =>
    !!(a && a.status === status);

export const checkPatientHasAlert =
  (status: AlertStatus, isUnoccupied = false) =>
  (ongoingDeviceAlerts: DeviceAlerts): boolean => {
    const {
      latestHrAlert,
      latestRrAlert,
      occupancyAlert,
      bedTimeBaseline,
      longOutOfBed,
      bedExitFrequency,
    } = ongoingDeviceAlerts;

    const checkAlertStatus = checkIfAlertStatusIs(status);
    return (
      checkAlertStatus(latestHrAlert) ||
      checkAlertStatus(latestRrAlert) ||
      checkAlertStatus(bedTimeBaseline) ||
      checkAlertStatus(longOutOfBed) ||
      checkAlertStatus(bedExitFrequency) ||
      (checkAlertStatus(occupancyAlert) && isUnoccupied)
    );
  };
