import {
  all,
  takeLatest,
  call,
  put,
  select,
  delay,
} from 'typed-redux-saga/macro';

import BackendService from 'src/services/BackendService';
import { tenantFormFields } from 'src/utils/constants';
import { notifyUserByActionTypeAndCode } from 'src/utils/errorHandling/notifications';
import { tenantErrorCodes } from 'src/utils/errorHandling/errorCodes';
import { MODAL_STATUS } from 'src/components/Modal/constants';
import { actions as deviceActions } from 'src/routes/Devices/modules/slice';
import { actions } from './slice';
import { DATA_FETCHING_STATUS } from '../../constants';
import { getUserDevicesList } from '../../device/modules/saga';
import { selectors as deviceSelectors } from '../../device/modules/slice';
import dataActions from '../../dataActions';
import { UUID } from 'src/types/utility';
import { hasAllPermissions, hasGMPermissions } from 'src/utils/permissions';
import permissions from 'src/permissions';
import { RootState } from 'src/redux/store';

function* getTenantsList() {
  try {
    if (hasAllPermissions(permissions.VIEW_DEVICES_TENANT_COLUMN)) {
      yield* put(actions.setStatus(DATA_FETCHING_STATUS.LOADING));
      const tenantsResponse = yield* call(BackendService.getTenants);
      yield* put(actions.setTenantsList(tenantsResponse.data.data));
      yield* put(actions.setStatus(DATA_FETCHING_STATUS.SUCCESS));
    }
  } catch (e) {
    console.error('error in fetchTenantsList: ', e);
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.ERROR));
  }
}

function* getPaginatedTenants(
  action: ReturnType<typeof actions.getPaginatedTenants>,
) {
  try {
    if (hasAllPermissions(permissions.VIEW_DEVICES_TENANT_COLUMN)) {
      yield* put(actions.setStatus(DATA_FETCHING_STATUS.LOADING));
      const tenantsResponse = yield* call(
        BackendService.getTenantsPaginated,
        action.payload,
      );
      yield* put(
        actions.setPaginatedTenants({
          data: tenantsResponse.data.data,
          pageMetadata: tenantsResponse.data.metadata.page,
        }),
      );
      yield* put(actions.setStatus(DATA_FETCHING_STATUS.SUCCESS));
    }
  } catch (e) {
    console.error('error in fetchTenantsList: ', e);
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.ERROR));
  }
}

function* searchTenants(action: ReturnType<typeof actions.searchTenants>) {
  if (!action?.payload.length) {
    yield* put(
      actions.setSearchedTenants({
        data: [],
        status: DATA_FETCHING_STATUS.SUCCESS,
      }),
    );
    return;
  }
  yield* delay(500);
  try {
    yield* put(
      actions.setSearchedTenants({
        data: [],
        status: DATA_FETCHING_STATUS.LOADING,
      }),
    );

    const searchResults = yield* call(
      BackendService.searchAllTenants,
      action.payload,
    );

    yield* put(
      actions.setSearchedTenants({
        data: searchResults?.data?.data,
        status: DATA_FETCHING_STATUS.SUCCESS,
      }),
    );
  } catch (e) {
    console.error('error in search search results of Tenants: ', e);
    yield* put(
      actions.setSearchedTenants({
        data: [],
        status: DATA_FETCHING_STATUS.ERROR,
      }),
    );
  }
}

function* getSubtenantsList(
  action: ReturnType<typeof actions.getSubtenantsList>,
) {
  try {
    if (hasGMPermissions()) {
      yield* put(actions.setStatus(DATA_FETCHING_STATUS.LOADING));
      const subtenantsResponse = yield* call(
        BackendService.getSubtenants,
        action.payload,
      );
      yield* put(actions.setTenantsList(subtenantsResponse.data.data));
      yield* put(
        actions.setTenantsListMetadata(subtenantsResponse.data.metadata),
      );
      yield* put(actions.setStatus(DATA_FETCHING_STATUS.SUCCESS));
    }
  } catch (e) {
    console.error('error in fetchTenantsList: ', e);
    yield* put(actions.setStatus(DATA_FETCHING_STATUS.ERROR));
  }
}

function* deleteTenant(action: ReturnType<typeof actions.deleteTenant>) {
  const tenantId = action.payload;

  const devicePageMetadata = yield* select((state: RootState) => ({
    page: state.data.device.adminDevices.pageMetadata.page,
    limit: state.data.device.adminDevices.pageMetadata.limit,
  }));

  const paginationAction = { payload: devicePageMetadata, type: '' };

  try {
    yield* call(getUserDevicesList, paginationAction);
    const deviceListStatus = yield* select(deviceSelectors.getStatus);
    const devicesList = yield* select(deviceSelectors.getDevicesList);
    const amountOfAttachedDevicesToCurrentTenant = devicesList?.filter(
      device => device.tenantId === tenantId,
    ).length;

    if (deviceListStatus !== DATA_FETCHING_STATUS.SUCCESS) {
      notifyUserByActionTypeAndCode(
        action.type,
        tenantId,
        tenantErrorCodes.COULD_NOT_GET_DEVICE_DATA,
      );
    } else if (amountOfAttachedDevicesToCurrentTenant === 0) {
      yield* call(BackendService.deleteTenant, tenantId);
      yield* call(getTenantsList);
    } else {
      notifyUserByActionTypeAndCode(
        action.type,
        tenantId,
        tenantErrorCodes.DEVICES_ATTACHED_TO_TENANT_ERROR,
      );
    }
  } catch (error) {
    console.error('error in deleteTenant: ', error);
    notifyUserByActionTypeAndCode(action.type, tenantId, error);
  }
}

function* createTenant(action: ReturnType<typeof actions.createTenant>) {
  // TODO: Update this to be typesafe
  const formData = action.payload as unknown as Record<string, unknown>;
  const createTenantRequest = {
    name: formData[tenantFormFields.NAME],
    isEnableCPX: formData[tenantFormFields.ENABLE_CPX],
    timeZoneOffset: formData[tenantFormFields.TIMEZONE_UTCOFFSET_STR],
    timeZoneId: formData[tenantFormFields.TIMEZONE_ID],
    group: formData[tenantFormFields.GROUP],
    parentTenantId: formData[tenantFormFields.PARENT_TENANT_ID],
    customerId: formData[tenantFormFields.CUSTOMER_ID],
    address: {
      countryCode: formData[tenantFormFields.COUNTRY_CODE],
      city: formData[tenantFormFields.CITY],
      zipCode: formData[tenantFormFields.ZIP_CODE],
      address1: formData[tenantFormFields.ADDRESS1],
      address2: formData[tenantFormFields.ADDRESS2],
      region: formData[tenantFormFields.REGION],
      state: formData[tenantFormFields.STATE],
    },
    admin: {
      email: formData[tenantFormFields.EMAIL],
      firstName: formData[tenantFormFields.FIRST_NAME],
      lastName: formData[tenantFormFields.LAST_NAME],
    },
  };

  try {
    yield* put(actions.setModalStatus(MODAL_STATUS.SUBMITTING));
    yield* call(BackendService.createTenant, createTenantRequest);
    yield* put(actions.setModalStatus(MODAL_STATUS.SUCCESS));
  } catch (error) {
    console.error('error in createTenant: ', error);
    yield* put(actions.setModalStatus(MODAL_STATUS.ERROR));
    notifyUserByActionTypeAndCode(
      action.type,
      createTenantRequest?.name,
      error,
    );
  }
}

function* editTenant(action: ReturnType<typeof actions.editTenant>) {
  // TODO: Update this to be typesafe
  const { id, data: formData } = action.payload as unknown as {
    id: UUID;
    data: Record<string, unknown>;
  };
  const editTenantRequest = {
    name: formData[tenantFormFields.NAME],
    isEnableCPX: formData[tenantFormFields.ENABLE_CPX],
    timeZoneOffset: formData[tenantFormFields.TIMEZONE_UTCOFFSET_STR],
    timeZoneId: formData[tenantFormFields.TIMEZONE_ID],
    group: formData[tenantFormFields.GROUP],
    parentTenantId: formData[tenantFormFields.PARENT_TENANT_ID],
    customerId: formData[tenantFormFields.CUSTOMER_ID],
    address: {
      countryCode: formData[tenantFormFields.COUNTRY_CODE],
      state: formData[tenantFormFields.STATE],
      city: formData[tenantFormFields.CITY],
      zipCode: formData[tenantFormFields.ZIP_CODE],
      address1: formData[tenantFormFields.ADDRESS1],
      address2: formData[tenantFormFields.ADDRESS2],
      region: formData[tenantFormFields.REGION],
    },
  };
  try {
    yield* put(actions.setModalStatus(MODAL_STATUS.SUBMITTING));
    yield* call(BackendService.updateTenant, id, editTenantRequest);
    yield* put(actions.setModalStatus(MODAL_STATUS.SUCCESS));
  } catch (error) {
    console.error('error in editTenant: ', error);
    yield* put(actions.setModalStatus(MODAL_STATUS.ERROR));
    notifyUserByActionTypeAndCode(action.type, editTenantRequest?.name, error);
  }
}

export default function* watchTenantActions() {
  yield* all([
    takeLatest(actions.getTenantsList, getTenantsList),
    takeLatest(actions.getSubtenantsList, getSubtenantsList),
    takeLatest(actions.deleteTenant, deleteTenant),
    takeLatest(actions.createTenant, createTenant),
    takeLatest(actions.editTenant, editTenant),
    takeLatest(actions.getPaginatedTenants, getPaginatedTenants),
    takeLatest(actions.searchTenants, searchTenants),
    takeLatest(dataActions.onLoadGroupTenants, getTenantsList),
    takeLatest(deviceActions.onLoadDevicesListPage, getTenantsList),
  ]);
}
