import React, { Dispatch, SetStateAction } from 'react';
import { ColumnsType } from 'antd/lib/table';
import { IntlShape } from 'react-intl';

import Checkbox from 'src/components/general-ui/Checkbox';
import {
  USERS_TABLE_ACTIONS_KEYS,
  StyledStatusColumn,
  USER_STATUS,
} from 'src/components/UserCrudComponents/constants';
import { UserWithStatus } from 'src/redux/data/user/modules/types';
import {
  Subscriber,
  TargetMedia,
  UserActivitySubscription,
  UserMedicalSubscription,
  UserTechnicalSubscription,
} from 'src/types/subscribers';
import { TableActionsConfig } from 'src/types/table';
import { UUID } from 'src/types/utility';
import { USER_TYPES } from 'src/utils/constants';
import { UserTableItem } from '../../modules/types';
import { PropsFromRedux } from './Connector';
import { tenantUsersTableMessages } from './constants';
import { CheckboxContainer } from './styled';
import permissions from 'src/permissions';
import { hasAnyPermissions } from 'src/utils/permissions';
import AccessControl from 'src/components/AccessControl';
import { activityAlertsArray } from 'src/routes/GMDashboard/modules/constants';

export const getUsersTenantColumns = (
  intl: IntlShape,
  usersAssignedToEmailMedical: UserMedicalSubscription[],
  setUsersAssignedToEmailMedical: Dispatch<
    SetStateAction<UserMedicalSubscription[]>
  >,
  usersAssignedToSmsMedical: UserMedicalSubscription[],
  setUsersAssignedToSmsMedical: Dispatch<
    SetStateAction<UserMedicalSubscription[]>
  >,
  usersAssignedToEmailTechnical: UserTechnicalSubscription[],
  setUsersAssignedToEmailTechnical: Dispatch<
    SetStateAction<UserTechnicalSubscription[]>
  >,
  usersAssignedToSmsTechnical: UserTechnicalSubscription[],
  setUsersAssignedToSmsTechnical: Dispatch<
    SetStateAction<UserTechnicalSubscription[]>
  >,
  usersAssignedToSmsActivity: UserActivitySubscription[],
  setUsersAssignedToSmsActivity: Dispatch<
    SetStateAction<UserActivitySubscription[]>
  >,
  usersAssignedToEmailActivity: UserActivitySubscription[],
  setUsersAssignedToEmailActivity: Dispatch<
    SetStateAction<UserActivitySubscription[]>
  >,
): ColumnsType<UserTableItem> => [
  {
    title: intl.formatMessage(tenantUsersTableMessages.userType),
    dataIndex: 'userType',
    key: 'userType',
    render: (_, record) => record.userType.name,
  },
  {
    title: intl.formatMessage(tenantUsersTableMessages.name),
    dataIndex: 'fullName',
    key: 'fullName',
  },
  {
    title: intl.formatMessage(tenantUsersTableMessages.email),
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: intl.formatMessage(tenantUsersTableMessages.phoneNumber),
    dataIndex: 'phone',
    key: 'phoneNumber',
    // @ts-ignore Custom logic
    editable: hasAnyPermissions(permissions.ORGANIZATION_USER_UPDATE),
  },
  {
    title: intl.formatMessage(tenantUsersTableMessages.status),
    dataIndex: 'status',
    key: 'status',
    render: (_, record) => (
      // @ts-ignore fix later
      <StyledStatusColumn status={record.status}>
        {record.status}
      </StyledStatusColumn>
    ),
  },
  {
    title: intl.formatMessage(tenantUsersTableMessages.medicalAlerts),
    children: [
      {
        title: intl.formatMessage(tenantUsersTableMessages.smsAlert),
        dataIndex: 'smsAlert',
        key: 'smsAlert',
        render: (_, record) => (
          <AccessControl permissions={[permissions.ALERT_SUBSCRIBER_CREATE]}>
            <CheckboxContainer>
              <Checkbox
                disabled={
                  record.status === 'Pending' ||
                  !record.phone ||
                  [
                    USER_TYPES.MULTI_TENANT_MANAGER,
                    USER_TYPES.TENANT_ADMIN,
                  ].includes(record.userType.name)
                }
                checked={usersAssignedToSmsMedical.some(
                  user => user.id === record.id,
                )}
                handleChange={value => {
                  const userSubscription = usersAssignedToSmsMedical.find(
                    user => user.id === record.id,
                  );

                  if (!value) {
                    setUsersAssignedToSmsMedical(
                      usersAssignedToSmsMedical.filter(
                        user => user.id !== record.id,
                      ),
                    );
                    return;
                  }

                  if (!userSubscription) {
                    setUsersAssignedToSmsMedical([
                      ...usersAssignedToSmsMedical,
                      {
                        alertCategory: 'MEDICAL',
                        id: record.id,
                        targetMedia: 'SMS',
                        withPersonalDetails: false,
                        summaryReportEnabled: null,
                        summaryReportTime: null,
                        singleDisconnectionAllowedType: null,
                        emailCcEnabled: null,
                        emailCcRecipients: null,
                      },
                    ]);
                    return;
                  }
                }}
              />
            </CheckboxContainer>
          </AccessControl>
        ),
      },
      {
        title: intl.formatMessage(tenantUsersTableMessages.emailAlert),
        dataIndex: 'emailAlert',
        width: '7rem',
        key: 'emailAlert',
        render: (_, record) => (
          <AccessControl permissions={[permissions.ALERT_SUBSCRIBER_CREATE]}>
            <CheckboxContainer>
              <Checkbox
                disabled={
                  record.status === 'Pending' ||
                  [
                    USER_TYPES.MULTI_TENANT_MANAGER,
                    USER_TYPES.TENANT_ADMIN,
                  ].includes(record.userType.name)
                }
                checked={usersAssignedToEmailMedical.some(
                  user => user.id === record.id,
                )}
                handleChange={value => {
                  const userSubscription = usersAssignedToEmailMedical.find(
                    user => user.id === record.id,
                  );

                  if (!value) {
                    setUsersAssignedToEmailMedical(
                      usersAssignedToEmailMedical.filter(
                        user => user.id !== record.id,
                      ),
                    );
                    return;
                  }

                  if (!userSubscription) {
                    setUsersAssignedToEmailMedical([
                      ...usersAssignedToEmailMedical,
                      {
                        alertCategory: 'MEDICAL',
                        id: record.id,
                        targetMedia: 'EMAIL',
                        withPersonalDetails: false,
                        summaryReportEnabled: null,
                        summaryReportTime: null,
                        singleDisconnectionAllowedType: null,
                        emailCcEnabled: null,
                        emailCcRecipients: null,
                      },
                    ]);
                    return;
                  }
                }}
              />
            </CheckboxContainer>
          </AccessControl>
        ),
      },
      {
        title: intl.formatMessage(tenantUsersTableMessages.withPersonalDetails),
        dataIndex: 'withPersonalDetails',
        width: '7rem',
        key: 'withPersonalDetails',
        render: (_, record) => (
          <AccessControl permissions={[permissions.ALERT_SUBSCRIBER_CREATE]}>
            <CheckboxContainer>
              <Checkbox
                disabled={
                  record.status === 'Pending' ||
                  [
                    USER_TYPES.MULTI_TENANT_MANAGER,
                    USER_TYPES.TENANT_ADMIN,
                  ].includes(record.userType.name) ||
                  !usersAssignedToEmailMedical.some(
                    user => user.id === record.id,
                  )
                }
                checked={usersAssignedToEmailMedical.some(
                  user => user.id === record.id && user.withPersonalDetails,
                )}
                handleChange={value => {
                  setUsersAssignedToEmailMedical(
                    usersAssignedToEmailMedical.map(userSubscription =>
                      userSubscription.id === record.id
                        ? {
                            ...userSubscription,
                            withPersonalDetails: value,
                          }
                        : userSubscription,
                    ),
                  );
                }}
              />
            </CheckboxContainer>
          </AccessControl>
        ),
      },
    ],
  },
  {
    title: intl.formatMessage(tenantUsersTableMessages.technicalAlerts),
    children: [
      {
        title: intl.formatMessage(tenantUsersTableMessages.smsAlert),
        dataIndex: 'smsAlert',
        key: 'smsAlert',
        render: (_, record) => (
          <AccessControl permissions={[permissions.ALERT_SUBSCRIBER_CREATE]}>
            <CheckboxContainer>
              <Checkbox
                disabled={
                  record.status === 'Pending' ||
                  !record.phone ||
                  [
                    USER_TYPES.MULTI_TENANT_MANAGER,
                    USER_TYPES.TENANT_ADMIN,
                  ].includes(record.userType.name)
                }
                checked={usersAssignedToSmsTechnical.some(
                  user => user.id === record.id,
                )}
                handleChange={value => {
                  const userSubscription = usersAssignedToSmsTechnical.find(
                    user => user.id === record.id,
                  );

                  if (!value) {
                    setUsersAssignedToSmsTechnical(
                      usersAssignedToSmsTechnical.filter(
                        user => user.id !== record.id,
                      ),
                    );
                    return;
                  }

                  if (!userSubscription) {
                    setUsersAssignedToSmsTechnical([
                      ...usersAssignedToSmsTechnical,
                      {
                        alertCategory: 'TECHNICAL',
                        id: record.id,
                        targetMedia: 'SMS',
                        withPersonalDetails: false,
                        summaryReportEnabled: null,
                        summaryReportTime: null,
                        singleDisconnectionAllowedType: null,
                        emailCcEnabled: null,
                        emailCcRecipients: null,
                      },
                    ]);
                    return;
                  }
                }}
              />
            </CheckboxContainer>
          </AccessControl>
        ),
      },
      {
        title: intl.formatMessage(tenantUsersTableMessages.emailAlert),
        dataIndex: 'emailAlert',
        width: '7rem',
        key: 'emailAlert',
        render: (_, record) => (
          <AccessControl permissions={[permissions.ALERT_SUBSCRIBER_CREATE]}>
            <CheckboxContainer>
              <Checkbox
                disabled={
                  record.status === 'Pending' ||
                  [
                    USER_TYPES.MULTI_TENANT_MANAGER,
                    USER_TYPES.TENANT_ADMIN,
                  ].includes(record.userType.name)
                }
                checked={usersAssignedToEmailTechnical.some(
                  user => user.id === record.id,
                )}
                handleChange={value => {
                  const userSubscription = usersAssignedToEmailTechnical.find(
                    user => user.id === record.id,
                  );

                  if (!value) {
                    setUsersAssignedToEmailTechnical(
                      usersAssignedToEmailTechnical.filter(
                        user => user.id !== record.id,
                      ),
                    );
                    return;
                  }

                  if (!userSubscription) {
                    setUsersAssignedToEmailTechnical([
                      ...usersAssignedToEmailTechnical,
                      {
                        alertCategory: 'TECHNICAL',
                        id: record.id,
                        targetMedia: 'EMAIL',
                        withPersonalDetails: false,
                        summaryReportEnabled: null,
                        summaryReportTime: null,
                        singleDisconnectionAllowedType: null,
                        emailCcEnabled: null,
                        emailCcRecipients: null,
                      },
                    ]);
                    return;
                  }
                }}
              />
            </CheckboxContainer>
          </AccessControl>
        ),
      },
    ],
  },
  {
    title: intl.formatMessage(tenantUsersTableMessages.activityAlerts),
    children: [
      {
        title: intl.formatMessage(tenantUsersTableMessages.smsAlert),
        dataIndex: 'smsAlert',
        key: 'smsAlert',
        render: (_, record) => (
          <AccessControl permissions={[permissions.ALERT_SUBSCRIBER_CREATE]}>
            <CheckboxContainer>
              <Checkbox
                disabled={
                  record.status === 'Pending' ||
                  !record.phone ||
                  [
                    USER_TYPES.MULTI_TENANT_MANAGER,
                    USER_TYPES.TENANT_ADMIN,
                  ].includes(record.userType.name)
                }
                checked={usersAssignedToSmsActivity.some(
                  user => user.id === record.id,
                )}
                handleChange={value => {
                  const userSubscription = usersAssignedToSmsActivity.find(
                    user => user.id === record.id,
                  );

                  if (!value) {
                    setUsersAssignedToSmsActivity(
                      usersAssignedToSmsActivity.filter(
                        user => user.id !== record.id,
                      ),
                    );
                    return;
                  }

                  if (!userSubscription) {
                    setUsersAssignedToSmsActivity([
                      ...usersAssignedToSmsActivity,
                      {
                        alertCategory: 'ACTIVITY',
                        id: record.id,
                        targetMedia: 'SMS',
                      },
                    ]);
                  }
                }}
              />
            </CheckboxContainer>
          </AccessControl>
        ),
      },
      {
        title: intl.formatMessage(tenantUsersTableMessages.emailAlert),
        dataIndex: 'emailAlert',
        width: '7rem',
        key: 'emailAlert',
        render: (_, record) => (
          <AccessControl permissions={[permissions.ALERT_SUBSCRIBER_CREATE]}>
            <CheckboxContainer>
              <Checkbox
                disabled={
                  record.status === 'Pending' ||
                  [
                    USER_TYPES.MULTI_TENANT_MANAGER,
                    USER_TYPES.TENANT_ADMIN,
                  ].includes(record.userType.name)
                }
                checked={usersAssignedToEmailActivity.some(
                  user => user.id === record.id,
                )}
                handleChange={value => {
                  const userSubscription = usersAssignedToEmailActivity.find(
                    user => user.id === record.id,
                  );

                  if (!value) {
                    setUsersAssignedToEmailActivity(
                      usersAssignedToEmailActivity.filter(
                        user => user.id !== record.id,
                      ),
                    );
                    return;
                  }

                  if (!userSubscription) {
                    setUsersAssignedToEmailActivity([
                      ...usersAssignedToEmailActivity,
                      {
                        alertCategory: 'ACTIVITY',
                        id: record.id,
                        targetMedia: 'EMAIL',
                      },
                    ]);
                  }
                }}
              />
            </CheckboxContainer>
          </AccessControl>
        ),
      },
    ],
  },
];

export const getTableActions = (
  loggedInUserId: UUID | undefined,
  tenantId: UUID,
  tenantType: 'groupManager' | 'subtenant',
  intl: IntlShape,
  resendUserInvitation: PropsFromRedux['resendUserInvitation'],
  setModalVisible: React.Dispatch<React.SetStateAction<boolean>>,
  editableUserData: React.Dispatch<React.SetStateAction<UserTableItem | null>>,
  setIsDeleteConfirmationVisible: React.Dispatch<React.SetStateAction<boolean>>,
  sendMessageForResetPassword: PropsFromRedux['sendMessageForResetPassword'],
): TableActionsConfig<UserTableItem>[] => [
  {
    key: USERS_TABLE_ACTIONS_KEYS.EDIT,
    name: intl.formatMessage(tenantUsersTableMessages.edit),
    onClick: (_, { record }) => {
      setModalVisible(true);
      editableUserData(record);
    },
    isDisabled: ({ record }) => record.status === USER_STATUS.PENDING,
    permissions: () => [permissions.ORGANIZATION_USER_UPDATE],
  },
  {
    key: USERS_TABLE_ACTIONS_KEYS.DELETE,
    name: intl.formatMessage(tenantUsersTableMessages.delete),
    onClick: (_, { record }) => {
      editableUserData(record);
      setIsDeleteConfirmationVisible(true);
    },
    isDisabled: ({ record }) => record.id === loggedInUserId,
    permissions: () => [permissions.ORGANIZATION_INVITATIONS_DELETE],
  },
  {
    key: USERS_TABLE_ACTIONS_KEYS.RESET_PASSWORD,
    name: intl.formatMessage(tenantUsersTableMessages.resetPassword),
    onClick: (_, { record }) => {
      // @ts-ignore to refactor user saga and slice
      sendMessageForResetPassword({ email: record?.email });
    },
    isHidden: ({ record }) => record.status === USER_STATUS.PENDING,
    permissions: ({ record }) =>
      record.id === loggedInUserId
        ? []
        : [permissions.UMS_USERS_SELF_POST_FORGOT_PASSWORD],
  },
  {
    key: USERS_TABLE_ACTIONS_KEYS.RESEND_INVITE,
    name: intl.formatMessage(tenantUsersTableMessages.resendInvite),
    onClick: (_, { record }) => {
      resendUserInvitation({ tenantId, tenantType, data: record });
    },
    isHidden: ({ record }) => record.status === USER_STATUS.ACTIVE,
    permissions: () => [permissions.ORGANIZATION_INVITATIONS_RESEND],
  },
];

export const getTenantSubscribers = (
  tenantId: UUID,
  allSubscribers: Subscriber[],
  tenantDataDict: Record<UUID, UUID>,
): Subscriber[] =>
  allSubscribers.filter(s => tenantDataDict[s.id] === tenantId);

const checkTargetMediaMedicalSubscription = (
  targetMedia: TargetMedia,
  userSubscribers: Subscriber[],
): boolean =>
  userSubscribers.some(
    s => s.targetMedia === targetMedia && s.alertType === 'RR',
  );

const checkTargetMediaTechnicalSubscription = (
  targetMedia: TargetMedia,
  userSubscribers: Subscriber[],
): boolean =>
  userSubscribers.some(
    s => s.targetMedia === targetMedia && s.alertType === 'DEVICE_DISCONNECTED',
  );

const checkTargetMediaActivitySubscription = (
  targetMedia: TargetMedia,
  userSubscribers: Subscriber[],
): boolean =>
  userSubscribers.some(
    s =>
      s.targetMedia === targetMedia &&
      activityAlertsArray.includes(s.alertType),
  );

export const getTenantUserTableItems = (
  usersWithStatus: UserWithStatus[],
  tenantSubscribers: Subscriber[],
): UserTableItem[] =>
  usersWithStatus.map(user => {
    const userSubscribers = tenantSubscribers.filter(s => s.userId === user.id);

    return {
      ...user,
      smsMedicalAlert: checkTargetMediaMedicalSubscription(
        'SMS',
        userSubscribers,
      ),
      emailMedicalAlert: checkTargetMediaMedicalSubscription(
        'EMAIL',
        userSubscribers,
      ),
      emailMedicalWithPersonalDetails: userSubscribers.some(
        s =>
          s.targetMedia === 'EMAIL' &&
          s.alertType === 'RR' &&
          s.withPersonalDetails,
      ),
      smsTechnicalAlert: checkTargetMediaTechnicalSubscription(
        'SMS',
        userSubscribers,
      ),
      emailTechnicalAlert: checkTargetMediaTechnicalSubscription(
        'EMAIL',
        userSubscribers,
      ),
      smsActivityAlert: checkTargetMediaActivitySubscription(
        'SMS',
        userSubscribers,
      ),
      emailActivityAlert: checkTargetMediaActivitySubscription(
        'EMAIL',
        userSubscribers,
      ),
    };
  });
