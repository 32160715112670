import React, { useEffect, useState } from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';
import * as R from 'fp-ts/lib/Record';

import InputWithIcon from 'src/components/general-ui/InputWithIcon';
import { AverageThresholdMetric, ThresholdPreposition } from 'src/types/alerts';
import hrBigIcon from './resources/hr-big-icon.svg';
import rrBigIcon from './resources/rr-big-icon.svg';
import editIcon from './resources/edit-icon.svg';
import {
  TenantThresholdsState,
  ThresholdsErrorState,
  EditableThreshold,
} from './types';
import {
  StyledWrapper,
  StyledThresholdsWrapper,
  StyledText,
  StyledIconWrapper,
  StyledIcon,
  StyledIconText,
  StyledInfo,
} from './styled';
import { mapThresholdsArrayToState, mapValidationErrors } from './utils';
import {
  MAX_HR_VALUE,
  MAX_RR_VALUE,
  MIN_HR_VALUE,
  MIN_RR_VALUE,
} from './constants';
import permissions from 'src/permissions';
import AccessControl from 'src/components/AccessControl';
import { hasEditOrCreateAlertThresholdsPermissions } from 'src/utils/permissions';
import {
  ALERT_METRIC_ENUM,
  ALERT_METRIC_PREPOSITION_ENUM,
} from 'src/redux/data/constants';

type Props = {
  thresholds: TenantThresholdsState;
  setThresholdData: (thresholds: TenantThresholdsState) => void;
  setHasErrors: (hasErrors: boolean) => void;
  className?: string;
};

const AlertThresholdsForm = ({
  thresholds,
  setThresholdData,
  setHasErrors,
  className,
}: Props): JSX.Element => {
  const [thresholdErrorState, setThresholdErrorState] =
    useState<ThresholdsErrorState>({
      HR: { ABOVE: false, BELOW: false },
      RR: { ABOVE: false, BELOW: false },
    });

  const setThresholdValue =
    (metric: AverageThresholdMetric, preposition: ThresholdPreposition) =>
    (value: number) =>
      setThresholdData({
        ...thresholds,
        [metric]: {
          ...thresholds[metric],
          [preposition]: {
            ...thresholds[metric][preposition],
            value,
            dirty: true,
          },
        },
      });

  useEffect(() => {
    setThresholdErrorState(mapValidationErrors(thresholds));
  }, [thresholds]);

  useEffect(() => {
    const isAnError = R.some((item: Record<ThresholdPreposition, boolean>) =>
      R.some((isError: boolean) => isError)(item),
    )(thresholdErrorState);

    setHasErrors(isAnError && hasEditOrCreateAlertThresholdsPermissions());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thresholdErrorState]);

  return (
    <AccessControl
      permissions={[
        permissions.ALERT_THRESHOLD_CREATE,
        permissions.ALERT_THRESHOLD_UPDATE,
      ]}
    >
      <StyledWrapper>
        <StyledThresholdsWrapper className={className}>
          <StyledText>
            <FormattedMessage {...messages.measurement} />
          </StyledText>
          <StyledIconWrapper>
            <StyledIcon
              src={hrBigIcon}
              alt="hr-big-icon"
              w="3.4rem"
              h="3.6rem"
              background="#eff8f9"
            />
            <StyledIconText>
              <FormattedMessage {...messages.hr} />
            </StyledIconText>
          </StyledIconWrapper>
          <StyledIconWrapper>
            <StyledIcon
              src={rrBigIcon}
              alt="rr-big-icon"
              w="3.6rem"
              h="3.5rem"
              background="#eff8f9"
            />
            <StyledIconText>
              <FormattedMessage {...messages.rr} />
            </StyledIconText>
          </StyledIconWrapper>
          <StyledText>
            <FormattedMessage {...messages.alertIfBelow} />
          </StyledText>
          <InputWithIcon
            iconSrc={editIcon}
            type="number"
            min={MIN_HR_VALUE}
            max={MAX_HR_VALUE}
            value={thresholds.HR.BELOW.value || ''}
            isError={
              hasEditOrCreateAlertThresholdsPermissions() &&
              thresholdErrorState.HR.BELOW
            }
            onChange={e =>
              setThresholdValue(
                ALERT_METRIC_ENUM.HR,
                ALERT_METRIC_PREPOSITION_ENUM.BELOW,
              )(parseInt(e.target.value || '0'))
            }
          />
          <InputWithIcon
            iconSrc={editIcon}
            type="number"
            min={MIN_RR_VALUE}
            max={MAX_RR_VALUE}
            value={thresholds.RR.BELOW.value || ''}
            isError={
              hasEditOrCreateAlertThresholdsPermissions() &&
              thresholdErrorState.RR.BELOW
            }
            onChange={e =>
              setThresholdValue(
                ALERT_METRIC_ENUM.RR,
                ALERT_METRIC_PREPOSITION_ENUM.BELOW,
              )(parseInt(e.target.value || '0'))
            }
          />
          <StyledText>
            <FormattedMessage {...messages.alertIfAbove} />
          </StyledText>
          <InputWithIcon
            iconSrc={editIcon}
            type="number"
            min={MIN_HR_VALUE}
            max={MAX_HR_VALUE}
            value={thresholds.HR.ABOVE.value || ''}
            isError={
              hasEditOrCreateAlertThresholdsPermissions() &&
              thresholdErrorState.HR.ABOVE
            }
            onChange={e =>
              setThresholdValue(
                ALERT_METRIC_ENUM.HR,
                ALERT_METRIC_PREPOSITION_ENUM.ABOVE,
              )(parseInt(e.target.value || '0'))
            }
          />
          <InputWithIcon
            iconSrc={editIcon}
            type="number"
            min={MIN_RR_VALUE}
            max={MAX_RR_VALUE}
            value={thresholds.RR.ABOVE.value || ''}
            isError={
              hasEditOrCreateAlertThresholdsPermissions() &&
              thresholdErrorState.RR.ABOVE
            }
            onChange={e =>
              setThresholdValue(
                ALERT_METRIC_ENUM.RR,
                ALERT_METRIC_PREPOSITION_ENUM.ABOVE,
              )(parseInt(e.target.value || '0'))
            }
          />
          <div></div>
          <StyledInfo>
            <FormattedMessage {...messages.infoHr} />
          </StyledInfo>
          <StyledInfo>
            <FormattedMessage {...messages.infoRr} />
          </StyledInfo>
        </StyledThresholdsWrapper>
      </StyledWrapper>
    </AccessControl>
  );
};

const messages = defineMessages({
  measurement: {
    defaultMessage: 'Measurement',
  },
  hr: {
    defaultMessage: 'Heart Rate',
  },
  rr: {
    defaultMessage: 'Respiration Rate',
  },
  alertIfBelow: {
    defaultMessage: 'Alert if below',
  },
  alertIfAbove: {
    defaultMessage: 'Alert if above',
  },
  infoHr: {
    defaultMessage: 'Valid Range: 41-159',
  },
  infoRr: {
    defaultMessage: 'Valid Range: 6-39',
  },
});

export type { TenantThresholdsState, EditableThreshold };
export { mapThresholdsArrayToState };

export default AlertThresholdsForm;
