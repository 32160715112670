import React, { useEffect } from 'react';

import SummaryReport from './components/SummaryReport';
import SingleDeviceDisconnection from './components/SingleDeviceDisconnection';
import SendNotificationsBy from './components/SendNotificationsBy';
import { CheckboxesWrapper, StyledWrapper } from './styled';
import { TechnicalSubscriptionDetails } from 'src/routes/GMDashboard/modules/types';

type Props = {
  technicalSubscriptionDetails: {
    SMS: TechnicalSubscriptionDetails;
    EMAIL: TechnicalSubscriptionDetails;
  };
  handleSubscriptionDetails: (technicalSubscriptionDetails: {
    SMS: TechnicalSubscriptionDetails;
    EMAIL: TechnicalSubscriptionDetails;
  }) => void;
  setHasErrors: (isError: boolean) => void;
};

const DisconnectionAlertsSettingsForm = ({
  technicalSubscriptionDetails,
  handleSubscriptionDetails,
  setHasErrors,
}: Props): JSX.Element => {
  const summaryReportEnabled =
    technicalSubscriptionDetails.EMAIL.summaryReportEnabled ||
    technicalSubscriptionDetails.SMS.summaryReportEnabled;
  const summaryReportTime =
    technicalSubscriptionDetails.EMAIL.summaryReportTime ||
    technicalSubscriptionDetails.SMS.summaryReportTime;
  const singleDisconnectionAllowedType =
    technicalSubscriptionDetails.EMAIL.singleDisconnectionAllowedType ||
    technicalSubscriptionDetails.SMS.singleDisconnectionAllowedType;
  const emailCcEnabled =
    technicalSubscriptionDetails.EMAIL.emailCcEnabled ||
    technicalSubscriptionDetails.SMS.emailCcEnabled;
  const emailCcRecipients =
    technicalSubscriptionDetails.EMAIL.emailCcRecipients ||
    technicalSubscriptionDetails.SMS.emailCcRecipients;
  const isSummaryReportPossible = technicalSubscriptionDetails.EMAIL.isAssigned;
  const isSingleDisconnectionAllowedTypePossible =
    technicalSubscriptionDetails.EMAIL.isAssigned ||
    technicalSubscriptionDetails.SMS.isAssigned;
  const isEmailCcPossible = technicalSubscriptionDetails.EMAIL.isAssigned;

  useEffect(() => {
    setHasErrors(summaryReportEnabled && !summaryReportTime);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [summaryReportEnabled, summaryReportTime]);

  const handleCommonTechnicalSettingsChanges = (
    summaryReportSettings: Partial<
      Pick<
        TechnicalSubscriptionDetails,
        | 'summaryReportEnabled'
        | 'summaryReportTime'
        | 'singleDisconnectionAllowedType'
        | 'emailCcEnabled'
        | 'emailCcRecipients'
      >
    >,
  ) =>
    handleSubscriptionDetails({
      EMAIL: {
        ...technicalSubscriptionDetails.EMAIL,
        ...summaryReportSettings,
      },
      SMS: {
        ...technicalSubscriptionDetails.SMS,
        ...summaryReportSettings,
      },
    });

  return (
    <StyledWrapper>
      <CheckboxesWrapper>
        <SummaryReport
          settings={{
            isEnabled: summaryReportEnabled,
            timeString: summaryReportTime,
          }}
          disabled={!isSummaryReportPossible}
          handleChangeEnabled={isEnabled =>
            handleCommonTechnicalSettingsChanges({
              summaryReportEnabled: isEnabled,
            })
          }
          handleChangeTime={dateString =>
            handleCommonTechnicalSettingsChanges({
              summaryReportTime: dateString,
            })
          }
        />
        <SingleDeviceDisconnection
          settings={{
            isEnabled: !!singleDisconnectionAllowedType,
            deviceDisconnectionType:
              singleDisconnectionAllowedType || 'ALL_DISCONNECTIONS',
          }}
          disabled={!isSingleDisconnectionAllowedTypePossible}
          handleChangeEnabled={isEnabled =>
            handleCommonTechnicalSettingsChanges({
              singleDisconnectionAllowedType: isEnabled
                ? singleDisconnectionAllowedType || 'ALL_DISCONNECTIONS'
                : null,
            })
          }
          handleChangeType={type =>
            handleCommonTechnicalSettingsChanges({
              singleDisconnectionAllowedType: type,
            })
          }
        />
        <SendNotificationsBy
          settings={{
            byEmail: technicalSubscriptionDetails.EMAIL.isAssigned,
            bySMS: technicalSubscriptionDetails.SMS.isAssigned,
            extraEmails: {
              isEnabled: emailCcEnabled,
              emails: emailCcRecipients ? emailCcRecipients.split(',') : [],
            },
          }}
          disabled={!isEmailCcPossible}
          handleChangeSettings={settings =>
            handleSubscriptionDetails({
              EMAIL: {
                ...technicalSubscriptionDetails.EMAIL,
                isAssigned: settings.byEmail,
                emailCcEnabled: settings.extraEmails.isEnabled,
                emailCcRecipients: settings.extraEmails.emails.join(','),
              },
              SMS: {
                ...technicalSubscriptionDetails.SMS,
                isAssigned: settings.bySMS,
                emailCcEnabled: settings.extraEmails.isEnabled,
                emailCcRecipients: settings.extraEmails.emails.join(','),
              },
            })
          }
        />
      </CheckboxesWrapper>
    </StyledWrapper>
  );
};

export default DisconnectionAlertsSettingsForm;
