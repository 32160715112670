import { connect, ConnectedProps } from 'react-redux';
import dayjs from 'dayjs';

import { RootState } from 'src/redux/store';
import { selectors as alertsSelectors } from 'src/redux/data/alerts';
import {
  DeviceAlerts,
  NurseStationDevice,
} from 'src/routes/NursesStation/modules/types';
import { BaselineAlert, VsAlert } from 'src/types/alerts';
import { PatientAlertsByType } from 'src/redux/data/alerts/modules/types';
type OwnProps = NurseStationDevice;

const getLatestAlert = (
  a1: VsAlert | BaselineAlert | undefined,
  a2: VsAlert | BaselineAlert | undefined,
): VsAlert | BaselineAlert | undefined => {
  if (!a1) {
    return a2;
  }

  if (!a2) {
    return a1;
  }

  return dayjs(a1.startTime).isAfter(a2.startTime) ? a1 : a2;
};

const selectDeviceAlerts = ({
  HR,
  RR,
  HR_BASELINE,
  RR_BASELINE,
  BED_EXIT,
  BED_TIME_BASELINE,
  LONG_OUT_OF_BED,
  BED_EXIT_FREQUENCY,
}: PatientAlertsByType): DeviceAlerts => ({
  latestHrAlert: getLatestAlert(HR, HR_BASELINE),
  latestRrAlert: getLatestAlert(RR, RR_BASELINE),
  occupancyAlert: BED_EXIT,
  bedTimeBaseline: BED_TIME_BASELINE,
  longOutOfBed: LONG_OUT_OF_BED,
  bedExitFrequency: BED_EXIT_FREQUENCY,
});

const mapStateToProps = (state: RootState, ownProps: OwnProps) => ({
  ongoingDeviceAlerts: selectDeviceAlerts(
    alertsSelectors.selectOngoingAlertsByPatientId(
      state,
      ownProps.patientId ?? '',
    ),
  ),
  isVacancyAlertActive: alertsSelectors.selectIsBedExitAlertEnabled(
    state,
    ownProps.patientId ?? '',
  ),
  isLongOutOfBedAlertActive: alertsSelectors.selectLongOutOfBedAlert(
    state,
    ownProps.patientId ?? '',
  ),
});

const mapActionCreators = {};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export type Props = OwnProps & PropsFromRedux;

export default Connector;
