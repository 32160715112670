import styled from 'styled-components';
import { Tabs } from 'antd';
import { media } from 'src/utils/mediaQueryUtils';

export const PageContentWrapper = styled.div`
  min-height: 94%;
  background-color: ${props =>
    props.theme.colors.individualDashboardBackgroundDark};
`;

export const StyledTabs = styled(Tabs)`
  overflow: auto;
  display: block;
  color: ${props => props.theme.colors.individualDashboardLightText};
  height: 100%;
  .ant-tabs-nav {
    margin: 0;
    background-color: ${props =>
      props.theme.colors.individualDashboardBackground};
    ${media.small`
      padding: 0px 1%;
    `}
    ${media.extraLarge`
      padding: 0px 30px;
    `}
  }
  .ant-tabs-tab {
    margin: 0 0 0 12px;

    ${media.small`
      font-size: 8px;
      padding: 5px 8px;
    `}
    ${media.extraLarge`
      font-size: 12px;
      padding: 7px 14px;
    `}
  }
  .ant-tabs-tab-active .ant-tabs-tab-btn {
    color: ${props =>
      props.theme.colors.individualDashboardTabsTitle} !important;
  }
  .ant-tabs-ink-bar {
    border-bottom: 2px solid
      ${props => props.theme.colors.individualDashboardActiveBorder} !important;
  }
`;
