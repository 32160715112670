import React, {
  Dispatch,
  SetStateAction,
  useEffect,
  useRef,
  useState,
} from 'react';
import { FormattedMessage, injectIntl, IntlShape } from 'react-intl';
import { Select } from 'antd';
import { RefSelectProps } from 'antd/lib/select';

import MultiSelect from 'src/components/general-ui/MultiSelect';
import { SETTINGS_TYPE } from 'src/routes/GMDashboard/modules/constants';
import {
  SubtenantItem,
  TenantSubscriptionDetails,
} from 'src/routes/GMDashboard/modules/types';
import { ErrorState, MTMAlertSettingsOnSaveHandlers } from '../types';
import {
  SettingsInfo,
  StyledOptionLabel,
  StyledOptionLabelTag,
  StyledWrapper,
} from './styled';
import { messages, typeToColor, typeToMessage } from './constants';
import MTMTechnicalAlerts from 'src/routes/GMDashboard/components/SubtenantCards/SubtenantSettings/AlertSettings/CombinedSettings/MTMTechnicalAlerts';

const { Option } = Select;

const customOptionRender = ({ value, label, type }: SubtenantItem) => (
  <Option value={value} label={label} key={value}>
    <StyledOptionLabel>{label}</StyledOptionLabel>
    <StyledOptionLabelTag color={typeToColor[type]}>
      <FormattedMessage {...typeToMessage[type]} />
    </StyledOptionLabelTag>
  </Option>
);

type Props = {
  setOnSaveHandlers: Dispatch<SetStateAction<MTMAlertSettingsOnSaveHandlers>>;
  setErrors: Dispatch<SetStateAction<ErrorState>>;
  intl: IntlShape;
  setSelectedSubtenants: Dispatch<SetStateAction<SubtenantItem[]>>;
  selectedSubtenants: SubtenantItem[];
  subtenantOptions: SubtenantItem[];
  subscriptionDetails: TenantSubscriptionDetails;
  setSubscriptionDetails: Dispatch<SetStateAction<TenantSubscriptionDetails>>;
};

const ConnectivityAlertsSettings = ({
  selectedSubtenants,
  setSelectedSubtenants,
  subtenantOptions,
  setErrors,
  subscriptionDetails,
  setSubscriptionDetails,
  intl,
}: Props): JSX.Element => {
  const [displayEditableSettings, setDisplayEditableSettings] = useState(true);
  const ref = useRef<RefSelectProps>(null);
  useEffect(() => {
    const settingsDoNoMatch =
      selectedSubtenants.length > 1 &&
      selectedSubtenants.some(s => s.type !== SETTINGS_TYPE.COLLECTIVE);

    setDisplayEditableSettings(!settingsDoNoMatch);
    setErrors(prevState => ({
      ...prevState,
      subtenants: selectedSubtenants.length === 0 || settingsDoNoMatch,
    }));

    if (settingsDoNoMatch === displayEditableSettings) {
      ref.current?.blur();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [displayEditableSettings, selectedSubtenants]);

  return (
    <StyledWrapper>
      <MultiSelect
        selectRef={ref}
        selectedItems={selectedSubtenants.map(s => s.value)}
        options={subtenantOptions}
        handleChange={ids =>
          setSelectedSubtenants(
            subtenantOptions.filter(s => ids.includes(s.value)),
          )
        }
        placeholder={intl.formatMessage(messages.multiselectPlaceholder)}
        customOptionRender={customOptionRender}
      />
      {displayEditableSettings ? (
        <MTMTechnicalAlerts
          subscriptionDetails={subscriptionDetails}
          setSubscriptionDetails={setSubscriptionDetails}
          setErrors={setErrors}
        />
      ) : (
        <SettingsInfo>
          <FormattedMessage {...messages.settingsDoNoMatch} />
        </SettingsInfo>
      )}
    </StyledWrapper>
  );
};

export default injectIntl(ConnectivityAlertsSettings);
