import { DefaultTheme } from 'styled-components';

export const palette = {
  primary: '#1f2534',
  primaryLight: '#37425c',
  secondary: '#a9c5d1',
  secondaryBright: '#87b7c5',
  tertiary: '#385a6c',
  neutralLight: '#ccc',
  neutralLighter: '#eee',
  neutralLightest: '#f9f9f9',
  neutralDark: '#999',
  neutralDarkest: '#1e1e1e',
  appWhite: '#fff',
  appBlack: '#000000',
  error: '#ff0000',
  negative: '#ff6384',
  negativeLight: '#fadbe1',
  start: '#188038',
  appDirtyWhite: '#f2f2f2',
  appDark: '#485d6a',
  whiteLine: '#cccccc',
  redLine: '#b45f87',
  header: '#afc2d1',
  histogramBar: '#87b7c5',
  darkHistogramBar: '#88B7C5',
  greenLine: '#2eb82e',
  orangeLine: ' #ff8c1a',
  blueLine: ' #1a8cff ',
  mediumSeaGreen: '#3CB371',
  lightSalmon: '#FFA07A',
  lightYellow: '#ffeb3bba',
  hardYellow: '#ffeb3b',
  gray: '#808080',
  hoverGray: '#d9d9d9',

  red: '#c71508',
  jellyBeanRed: '#D3444F',
  hardRed: '#ff0000',
  freeSpeechRed: '#CA0000',
  mediumRed: '#FF4F4F',
  lightRed: '#ef8b8f',

  green: '#19a83b',
  veryLightGreen: '#e7ffce',
  yellow: '#ffce1c',
  lighterSalmon: '#F5D0D0',
  darkGrey: '#353535',
  lightGrey: '#92959A',
  mediumGrey: '#5E5E5E',
  darkGrey2: '#F7F7F7',
  mediumOrange: '#FFC048',
  mediumGreen: '#59DDAA',
  mediumBlue: '#9BBCCB',
  hardBlue: '#354ACB',
  veryLightGrey: '#f6f6f6',

  grey3: '#E5E5E5',
  grey4: '#252525',
  grey5: '#6C757D',
  grey6: '#EDEDED',
  grey7: '#767881',
  grey8: '#4A4A4A',
  grey9: '#6C6E79',
  grey10: '#5A5A5A',
  grey11: '#818181',
  grey12: '#A5A5A5',
  grey13: 'rgba(151, 151, 151, 0.33)',
  grey14: '#E3E3E7',

  lightGrey2: '#E5E5E5',
  lightBlue: '#E7F4F6',
  darkBlue: '#385B75',
  lightAppBlue: '#63B7C3',
  veryDarkGrey: '#252525',
  appDarkBlue: 'rgba(99, 183, 195, 0.155611)',
  transparentWhite: 'rgba(255, 255, 255, 0.8)',

  // alert colors
  darkOrange: '#CC3311',
  lightOrange: '#FDEDE9',
  darkAqua: '#3A4242',
  lightAqua: '#DDFFFF',
  darkTangerine: '#FFAD0D',
};

export const fontFamily = {
  primary: "'Inter', sans-serif",
  secondary: "'Montserrat', sans-serif",
};

const theme: DefaultTheme = {
  colors: {
    /* general */
    layoutBackground: palette.neutralLightest,
    headerBackground: palette.header,
    headerTextColor: palette.appWhite,
    headerAvatarColor: palette.tertiary,
    errorText: palette.error,
    successText: palette.secondaryBright,
    MainContentBackground: palette.appDirtyWhite,
    shadowColor: palette.neutralLight,
    defaultTextColor: palette.grey4,
    siderBackground: palette.appDark,
    logoutButton: palette.secondaryBright,
    inputHoverBorder: palette.secondaryBright,
    hoverColor: palette.hoverGray,
    overlayBackground: palette.transparentWhite,
    veryDarkGrey: palette.veryDarkGrey,

    /* buttons */
    coloredButtonTextColor: palette.appWhite,
    primaryButtonBackground: palette.primary,
    primaryButtonHover: palette.primaryLight,
    cancelButtonBackground: palette.neutralLightest,
    cancelButtonBorder: palette.neutralLight,
    cancelButtonBorderFocused: palette.neutralDark,
    actionButtonBackground: palette.secondaryBright,
    negativeActionButtonBackground: palette.negative,
    languageMenuButtonBackground: 'transparent',
    actionValidButtonBackgroud: palette.start,
    actionInvalidButtonBackgroud: palette.error,
    dateTypeButtonBackground: palette.secondary,
    trendButtonBackground: palette.secondary,
    buttonDisabledColor: palette.neutralLight,
    deviceButtonText: palette.secondaryBright,
    cancelEditMode: palette.red,
    saveEditMode: palette.green,

    /* borders */
    borderPrimaryColor: palette.appWhite,
    borderHoverColor: palette.hoverGray,

    /* table */
    actionButtonColor: palette.secondaryBright,
    actionButtonDisabledColor: palette.neutralDark,
    actionsSeparatorColor: palette.secondary,
    scrollBarThumbColor: palette.secondaryBright,
    scrollBarTrackColor: palette.neutralLighter,
    highlightRowLightGreen: palette.veryLightGreen,

    /* graph */
    graphTick: palette.negative,
    graphLine: palette.negativeLight,
    graphBackground: palette.appDark,
    monitorGraphBackground: palette.appWhite,

    /* history graph */
    historyGraphMinTick: palette.negative,
    historyGraphMinLine: palette.redLine,
    historyGraphAvgTick: palette.neutralDark,
    historyGraphAvgLine: palette.whiteLine,
    historyGraphMaxTick: palette.secondary,
    historyGraphMaxLine: palette.secondaryBright,
    historyGraphStartSessionPointColor: palette.start,
    historyGraphSessionPointColor: palette.appBlack,
    maxTrendLine: palette.greenLine,
    minTrendLine: palette.blueLine,
    avgTrendLine: palette.orangeLine,

    /* history */
    historyDateRangeBorder: palette.secondaryBright,
    activeTabs: palette.secondaryBright,

    /* modals */
    modalTextColor: palette.primary,
    modalLoaderColor: palette.secondaryBright,

    /* cards */
    spotMeasurementCardBorderColor: palette.neutralLight,
    spotMeasurementCardTitleColor: palette.secondaryBright,
    measurementCardBackgroundColor: palette.appWhite,
    spotMeasurementLowQualityColor: palette.secondaryBright,
    spotMeasurementHighQualityColor: palette.appBlack,

    monitorPageSubTitleColor: palette.tertiary,
    graphLabelColor: palette.appWhite,
    historyGraphTitle: palette.appWhite,
    deviceDisconnectedCard: palette.gray,

    /* auth */
    textColor: palette.secondaryBright,
    authButtonBackground: palette.appDark,
    authNavigationLink: palette.appDark,

    tabsBackground: palette.appWhite,

    /* usersTable */
    activeUserColor: palette.mediumSeaGreen,
    pendingUserColor: palette.lightSalmon,

    /* setting */
    settingBoxBorderColor: palette.neutralLight,
    alertBoxTitleColor: palette.secondaryBright,
    alertBoxBackgroundColor: palette.appWhite,

    /* alerts */
    alertOnColor: palette.lightRed,
    alertOnBorderColor: palette.hardRed,
    alertOffColor: palette.lightYellow,
    alertOffBorderColor: palette.hardYellow,
    alertStatus: palette.appBlack,
    averageAlertBackground: palette.lighterSalmon,

    alertBackground: palette.lightOrange,
    alertBorder: palette.darkOrange,
    suppressedAlertColor: palette.darkTangerine,

    /* Patient Status Icon */
    iconAlertOn: palette.freeSpeechRed,

    /*nurses station*/
    lowQualityVitalSigns: palette.secondaryBright,
    highQualityVitalSigns: palette.appBlack,

    /*continuous graph*/
    graphAxisLabels: palette.secondary,

    /* legen modal */
    legendText: palette.veryDarkGrey,

    /*average statistics*/
    lowBarGraph: palette.histogramBar,
    normalBarGraph: palette.histogramBar,
    highBarGraph: palette.histogramBar,

    personalMonitorPatientName: palette.darkHistogramBar,

    dropdownMenuItemBorder: palette.neutralLight,
    dropdownMenuHoverBackground: palette.neutralLightest,
    bedExitBannerBorderColor: palette.appBlack,

    /* individual dashboard */
    individualDashboardLightText: palette.lightGrey,
    individualDashboardBlackText: palette.appBlack,
    individualDashboardBackground: palette.appWhite,
    individualDashboardBackgroundDark: palette.veryLightGrey,
    individualDashboardTabsTitle: palette.grey8,
    individualDashboardTabsText: palette.mediumGrey,
    individualDashboardHeaderBorder: palette.grey3,
    individualDashboardActiveBorder: palette.grey8,
    individualdDashboardHeaderTextColor: palette.grey7,

    individualDashboardCardTitle: palette.grey4,
    invidualDashboardCardLowQualityValue: palette.mediumBlue,

    individualDashboardBoxWrapperBorder: palette.grey3,
    individualDashboardBoxTitleColor: palette.grey4,
    individualDashboardBoxValueSuffixColor: palette.grey5,
    individualDashboardTimeButtonBackground: palette.grey6,
    individualDashboardTimeButtonBackgroundActive: palette.lightBlue,
    individualDashboardTimeButtonBorder: palette.darkBlue,

    bedOccupancyHoursInBedBar: palette.darkBlue,
    bedOccupancyNumberOfBedAlertsBar: palette.lightAppBlue,

    hrRrHistogramBarColor1: palette.mediumRed,
    hrRrHistogramBarColor2: palette.mediumOrange,
    hrRrHistogramBarColor3: palette.mediumGreen,
    hrRrHistogramLineColor: palette.lightGrey,

    continuousGraphHrContinuousColor: palette.mediumRed,
    continuousGraphRrContinuousColor: palette.hardBlue,

    individualDashboardGraphAxisLabels: palette.grey9,

    individualDashboardAlertLogBackground: palette.appWhite,

    individualDashboardSelectorText: palette.veryDarkGrey,
    individualDashboardSelectorWhite: palette.appWhite,
    individualDashboardSelectorGrey: palette.grey10,
    individualDashboardHoverBackground: palette.appDarkBlue,
    individualDashboardTextGrey: palette.grey11,
    individualDashboardBorderGrey: palette.lightGrey2,
    individualDashboardBorderGrey2: palette.grey12,
    individualDashboardBorderGrey3: palette.grey13,
    individualDashboardBorderGrey4: palette.grey14,
    individualDashboardStatusColor: palette.jellyBeanRed,

    simpleModalHeaderBorder: palette.gray,
    simpleModalHeaderTitle: palette.grey7,
    simpleModalBackground: palette.appWhite,

    spinnerColor: palette.appBlack,

    /*alert notes*/
    emptyNotes: palette.grey3,
    normalNotes: palette.grey7,
    importantNotes: palette.freeSpeechRed,
  },

  fonts: {
    primary: fontFamily.primary,
    secondary: fontFamily.secondary,
    smallestFontSize: '11px',
    smallFontSize: '12px',
    normalFontSize: '14px',
    headerFontSize: '24px',
  },
  layout: {
    defaultBorderRadius: '4px',
    defaultButtonPadding: '5px 10px',
  },
};

export default theme;
