import React, { Dispatch, SetStateAction, useEffect } from 'react';

import BaselineAlertSettingsForm, {
  TenantBaselineThresholdsState,
} from 'src/components/AlertSettingsComponents/BaselineAlertSettingsForm';
import { DataTenantDict } from 'src/redux/data/groupManager/modules/types';
import {
  ALL_TENANTS_OPTION,
  SETTINGS_TYPE,
} from 'src/routes/GMDashboard/modules/constants';
import {
  applyForEachBaselineThreshold,
  checkIfCollectiveChange,
  upsertMultiSubtenantBaselineThresholds,
  upsertSubtenantBaselineThresholds,
} from 'src/routes/GMDashboard/modules/settingsUtils';
import { SubtenantItem } from 'src/routes/GMDashboard/modules/types';
import { BaselineAlertThreshold } from 'src/types/alerts';
import Connector, { PropsFromRedux } from './Connector';
import { deepEqual } from 'src/utils/comparators';

type Props = PropsFromRedux & {
  subtenants: SubtenantItem[];
  selectedSubtenants: SubtenantItem[];
  allBaselineThresholds: BaselineAlertThreshold[];
  baselineThresholdsDict: DataTenantDict;
  setOnSave: (onSave: () => void) => void;
  setIsSaveBtnDisabled: (isDisabled: boolean) => void;
  baselineThresholdsData: TenantBaselineThresholdsState;
  setBaselineThresholdData: Dispatch<
    SetStateAction<TenantBaselineThresholdsState>
  >;
};

const MTMBaselineThresholdForm = ({
  subtenants,
  selectedSubtenants,
  allBaselineThresholds,
  baselineThresholdsDict,
  createGMBaselineAlertThreshold,
  editGMBaselineAlertThreshold,
  createSubtenantBaselineAlertThresholds,
  editSubtenantBaselineAlertThresholds,
  setOnSave,
  setIsSaveBtnDisabled,
  baselineThresholdsData,
  setBaselineThresholdData,
}: Props): JSX.Element => {
  useEffect(() => {
    setOnSave(() => {
      const isCollectiveChange = checkIfCollectiveChange(selectedSubtenants);

      if (!isCollectiveChange) {
        const selectedSubtenantId = selectedSubtenants[0]?.value;

        if (selectedSubtenants.length !== 1 || !selectedSubtenantId) {
          return;
        }

        upsertSubtenantBaselineThresholds(
          selectedSubtenantId,
          baselineThresholdsData,
          createSubtenantBaselineAlertThresholds,
          editSubtenantBaselineAlertThresholds,
        );

        return;
      }

      const areAllTenantsSelected = selectedSubtenants.find(
        s => s.value === ALL_TENANTS_OPTION,
      );
      const subtenantsToUpdate = areAllTenantsSelected
        ? subtenants.filter(s => s.type !== SETTINGS_TYPE.UNIQUE)
        : selectedSubtenants;

      if (areAllTenantsSelected) {
        applyForEachBaselineThreshold(baselineThresholdsData, threshold => {
          const {
            id,
            dirty,
            enable,
            metric,
            baselineDaysInterval,
            deviationHoursInterval,
            deviationPercentage,
          } = threshold;

          if (!dirty) {
            return;
          }

          if (threshold.id) {
            editGMBaselineAlertThreshold({
              id,
              enable,
              baselineDaysInterval,
              deviationHoursInterval,
              deviationPercentage,
            });
          } else {
            if (!enable) {
              return;
            }

            createGMBaselineAlertThreshold({
              enable,
              metric,
              baselineDaysInterval,
              deviationHoursInterval,
              deviationPercentage,
            });
          }
        });
      }

      upsertMultiSubtenantBaselineThresholds(
        subtenantsToUpdate,
        allBaselineThresholds,
        baselineThresholdsDict,
        baselineThresholdsData,
        createSubtenantBaselineAlertThresholds,
        editSubtenantBaselineAlertThresholds,
      );
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baselineThresholdsData, selectedSubtenants]);

  return (
    <BaselineAlertSettingsForm
      baselineThresholds={baselineThresholdsData}
      setBaselineThresholdData={setBaselineThresholdData}
      setHasErrors={setIsSaveBtnDisabled}
    />
  );
};

export default Connector(
  React.memo(MTMBaselineThresholdForm, (oldProps, newProps) =>
    deepEqual(oldProps, newProps),
  ),
);
