import React from 'react';
import ActivityAlertsForm from './index';
import { deepEqual } from 'src/utils/comparators';
import { ActivityAlertComponentProps } from 'src/components/AlertSettingsComponents/ActivityAlerts/types';

const ActivityAlerts = ({
  activityAlertSettings,
  subscriptionDetails,
  setSelectedSubtenants,
  subtenantOptions,
  setActivityAlertSettings,
  setSubscriptionDetails,
  displaySubtenantSelector,
  hasSubscriptions = true,
  areSelectedTenantsSettingsEquals = { equals: true },
  setIsFormValid,
  editablePermissions = [],
}: ActivityAlertComponentProps) => {
  return (
    <ActivityAlertsForm
      activityAlertSettings={activityAlertSettings}
      subscriptionDetails={subscriptionDetails}
      setActivityAlertSettings={setActivityAlertSettings}
      subtenantOptions={subtenantOptions ?? []}
      setSelectedSubtenants={setSelectedSubtenants}
      setSubscriptionDetails={setSubscriptionDetails}
      displaySubtenantSelector={displaySubtenantSelector}
      hasSubscriptions={hasSubscriptions}
      areSelectedTenantsSettingsEquals={areSelectedTenantsSettingsEquals}
      setIsFormValid={setIsFormValid}
      editablePermissions={editablePermissions}
    />
  );
};

export default React.memo(ActivityAlerts, (oldProps, newProps) =>
  deepEqual(oldProps, newProps),
);
