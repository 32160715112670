import dayjs, { Dayjs } from 'dayjs';
import React, { useEffect, useState } from 'react';
import {
  defineMessages,
  FormattedMessage,
  injectIntl,
  IntlShape,
} from 'react-intl';

import { US_FORMAT_WITHOUT_SECONDS } from 'src/utils/timeUtils';
import { getAlertLogDetails } from 'src/utils/messages/alertMessages';
import Connector, { PropsFromRedux } from './Connector';
import { AlertLogTableItem } from '../types';
import { getAlertsLogColumns } from '../utils';
import { StyledTable } from '../styled';
import { DEFAULT_PAGINATION } from 'src/utils/constants';
import { StyledMessage } from 'src/routes/IndividualDashboard/components/DashboardTab/MainGraph/AlertLog/styled';

type Props = PropsFromRedux & {
  intl: IntlShape;
  startDate: Dayjs | null;
  endDate: Dayjs | null;
};

const AlertLogTable = ({
  patient,
  alertsLogData,
  getAlertsLogData,
  alertLogMetadata,
  isLoading,
  startDate,
  endDate,
  intl,
}: Props): JSX.Element => {
  const cols = getAlertsLogColumns(intl);
  const alertLogItems: AlertLogTableItem[] = alertsLogData.map(alertLog => ({
    key: alertLog.id,
    id: alertLog.id,
    thresholdMetric: alertLog.thresholdMetric,
    thresholdPreposition: alertLog.thresholdPreposition,
    startTime: dayjs(alertLog.startTime).format(US_FORMAT_WITHOUT_SECONDS),
    endTime: alertLog.endTime
      ? dayjs(alertLog.endTime).format(US_FORMAT_WITHOUT_SECONDS)
      : '--',
    details: getAlertLogDetails(alertLog, intl),
    status: alertLog.status,
  }));

  const [tableParams, setTableParams] = useState({
    current: DEFAULT_PAGINATION.PAGE,
    pageSize: 30,
    showSizeChanger: false,
    total: 0,
  });

  useEffect(() => {
    if (!patient?.id || !startDate || !endDate) {
      return;
    }
    getAlertsLogData({
      patientId: patient.id,
      startDate,
      endDate,
      currentPage: tableParams?.current,
      pageSize: tableParams?.pageSize,
    });
  }, [
    tableParams?.current,
    tableParams?.pageSize,
    patient?.id,
    startDate,
    endDate,
  ]);

  const paginationChange = (page: number) => {
    setTableParams(prevState => ({
      ...prevState,
      current: page,
    }));
  };

  useEffect(() => {
    setTableParams(prevState => ({
      ...prevState,
      total: alertLogMetadata?.totalResults || 0,
    }));
  }, [alertLogMetadata?.totalResults]);

  return alertsLogData.length ? (
    <StyledTable
      loading={isLoading}
      locale={{
        emptyText: intl.formatMessage(messages.noAlerts),
      }}
      pagination={
        tableParams?.total > tableParams.pageSize
          ? {
              ...tableParams,
              onChange: paginationChange,
            }
          : false
      }
      data={alertLogItems}
      columns={cols}
      scroll={{ y: '23rem' }}
    />
  ) : (
    <StyledMessage>
      <FormattedMessage {...messages.noAlerts} />
    </StyledMessage>
  );
};

const messages = defineMessages({
  noAlerts: {
    defaultMessage: 'No alerts in the selected timeframe',
  },
});

export default Connector(injectIntl(AlertLogTable));
