import {
  all,
  takeLatest,
  call,
  put,
  select,
  race,
  take,
} from 'typed-redux-saga/macro';
import { replace, getLocation } from 'connected-react-router';

import UmsLogicSdk from 'src/libs/ums-js-logic';
import { getLocaleFromUrlPath } from 'src/utils/languageUtils';
import BackendService from 'src/services/BackendService';
import {
  actionSuccessNotification,
  notifyUserByActionTypeAndCode,
} from 'src/utils/errorHandling/notifications';
import { UUID } from 'src/types/utility';
import { actions as loggedInUserActions } from '../../loggedInUser';
import { getUserTenant } from '../../app/modules/saga';
import { actions } from './slice';
import {
  SoundQueryParamsType,
  validateRedirectRoute,
  setSoundQueryParams,
} from 'src/routes/Root/modules/routesUtils';
import { LocationProps } from 'src/routes/Root/modules/types';
import { UserLoginData } from 'src/types/users';

function* login(action: ReturnType<typeof actions.login>) {
  const { email, password, rememberMe, withRedirect = true } = action.payload;
  try {
    actions.setUserMfa(false);
    actions.setUserMfaPhone(null);
    const loginResponse = yield* call([UmsLogicSdk, UmsLogicSdk.login], {
      username: email,
      password,
      rememberMe,
    });
    if (loginResponse.mfaRequired) {
      yield* put(actions.setUserMfa(loginResponse.mfaRequired));
      yield* put(actions.setUserMfaPhone(loginResponse.mfaPhone));
      yield* put(actions.loginSuccess());
      return;
    }

    yield* call(updateLoginUserData, withRedirect, loginResponse);
  } catch (error) {
    console.error('Error in login: ', error);
    yield* put(actions.loginFail());
  }
}
function* mfaLogin(action: ReturnType<typeof actions.mfaLogin>) {
  const { code, withRedirect = true } = action.payload;

  try {
    const loginWithMfaResponse = yield* call(
      [UmsLogicSdk, UmsLogicSdk.loginWithMfa],
      {
        code,
      },
    );
    yield* call(updateLoginUserData, withRedirect, loginWithMfaResponse);
  } catch (error) {
    console.error('Error in login: ', error);
    notifyUserByActionTypeAndCode(action.type, null, error);
    yield* put(actions.loginFail());
  }
}
function* mfaResendCodeLogin(
  action: ReturnType<typeof actions.mfaResendCodeLogin>,
) {
  try {
    yield* call([UmsLogicSdk, UmsLogicSdk.resendMfaCode]);
    actionSuccessNotification(action.type, null);
    // yield* call(updateLoginUserData, withRedirect, loginWithMfaResponse);
  } catch (error) {
    console.error('Error in login: ', error);
    notifyUserByActionTypeAndCode(action.type, null, error);
  }
}

export function* updateLoginUserData(
  withRedirect = true,
  loginResponse: UserLoginData,
) {
  try {
    const locationObj = yield* select(getLocation);
    const queryParamas = (locationObj?.query as SoundQueryParamsType) || {};

    setSoundQueryParams(queryParamas);
    const tenantDetails = yield* call(getUserTenant);
    const userDetails = yield* call(BackendService.getUserDetails);
    yield* put(
      loggedInUserActions.loginUser({
        ...loginResponse,
        ...userDetails?.data,
        tenantDetails: { ...tenantDetails?.data },
      }),
    );
    const consents = yield* call(getConsents);

    if (consents) {
      return;
    }

    yield* put(actions.loginSuccess());

    if (withRedirect) {
      yield* call(navigateToSystem);
    }
  } catch (error) {
    console.error('Error in loginUserData: ', error);
    yield* put(actions.loginFail());
  }
}

function* logout(action: ReturnType<typeof actions.logout>) {
  try {
    yield* call(UmsLogicSdk.logout);
  } catch (error) {
    console.log('Failed to logout: ', error);
    notifyUserByActionTypeAndCode(action.type, action.payload, error);
  } finally {
    yield* put(actions.logoutFinish());
  }
}

//@ts-ignore - check sagas to return value
export function* getConsents() {
  try {
    const response = yield* call(BackendService.getUserConsents);
    const consents = response.data.consents;

    if (consents.length > 0) {
      for (const consent of consents) {
        yield* call(getConsentData, consent.id);

        const { confirmAction, cancelConsentAction } = yield* race({
          confirmAction: take(actions.onConfirmConsent),
          cancelConsentAction: take(actions.onCancelConsent),
        });

        if (consent && cancelConsentAction) {
          return consent;
        }

        if (confirmAction) {
          yield* call(BackendService.confirmConsent, confirmAction.payload);
        }
      }
    }
  } catch (error) {
    console.log('error in get consents', error);
    yield* put(actions.consentFailed());
  }
}
function* getConsentData(consentId: UUID) {
  try {
    const currentConsentDetailsResponse = yield* call(
      BackendService.getConsent,
      consentId,
    );
    const { revisionId, downloadUrl } = currentConsentDetailsResponse.data;

    yield* put(actions.getActiveConsent({ revisionId, url: downloadUrl }));
  } catch (error) {
    console.log('error in get consent data', error);
    yield* put(actions.consentFailed());
  }
}

function* navigateToSystem() {
  const locationObj = yield* select(getLocation);

  const { from } = (locationObj.state as LocationProps) || {};

  const redirectToPrevLocation = from && validateRedirectRoute(locationObj);

  if (redirectToPrevLocation?.pathname && redirectToPrevLocation?.search) {
    yield* put(
      replace(
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        `${redirectToPrevLocation.pathname}${redirectToPrevLocation.search}`,
      ),
    );
  } else {
    // TODO: Add typed router state
    // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
    yield* put(replace(`/${getLocaleFromUrlPath(locationObj.pathname)}/`));
  }
}

function* forgotPassword(action: ReturnType<typeof actions.forgotPassword>) {
  const { email } = action.payload;
  try {
    yield* call([UmsLogicSdk, UmsLogicSdk.forgotPassword], { username: email });
    yield* put(actions.forgotPasswordSuccess());
  } catch (error) {
    console.error('Error in forgotPassword: ', error);
    yield* put(actions.forgotPasswordFail());
  }
}

export default function* watchLoginActions() {
  yield* all([
    takeLatest(actions.login, login),
    takeLatest(actions.mfaLogin, mfaLogin),
    takeLatest(actions.mfaResendCodeLogin, mfaResendCodeLogin),
    takeLatest(actions.logout, logout),
    takeLatest(actions.forgotPassword, forgotPassword),
  ]);
}
