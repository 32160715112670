import {
  createSlice,
  createSelector,
  PayloadAction,
  createAction,
} from '@reduxjs/toolkit';
import * as R from 'fp-ts/lib/Record';
import * as Ord from 'fp-ts/lib/Ord';

import { DATA_FETCHING_STATUS } from 'src/redux/data/constants';
import { RootState } from 'src/redux/store';
import {
  AlertLog,
  AlertSoundSettings,
  PatientAttentionItem,
} from 'src/types/alerts';
import { selectors as tenantSelectors } from 'src/redux/data/tenant';
import { selectors as deviceSelectors } from 'src/redux/data/device';
import { selectors as alertSelectors } from 'src/redux/data/alerts';
import { selectors as gmSelectors } from 'src/redux/data/groupManager';
import { GM_DATA_KEYS } from 'src/redux/data/groupManager/modules/constants';
import { DeviceFilter, StateFilters } from './filterUtils';
import { SubTenantOverview } from './types';
import { PageMetaDataType, UUID } from 'src/types/utility';
import { API_STATUS } from 'src/utils/api-constants';
import { hasAlertFinishedInLast24h } from 'src/redux/data/alerts/modules/utils';
import { DeviceDisconnectionOverview } from 'src/types/devices';
import { isMedicalAlert } from 'src/redux/data/alerts/modules/typeGuards';

export const STATE_KEY = 'groupManagerDashboard';

export const INITIAL_STATE: {
  alertSoundStatus: AlertSoundSettings;
  fetchStatus: keyof typeof DATA_FETCHING_STATUS;
  filters: StateFilters;
  searchQuery: string;
  alertLogs: {
    data: Record<UUID, AlertLog[]>;
    apiStatus: API_STATUS;
    pageMetadata?: PageMetaDataType;
  };
  devicesDisconnectionOverview: {
    data: Record<UUID, DeviceDisconnectionOverview[]>;
    apiStatus: API_STATUS;
    pageMetadata: Record<UUID, PageMetaDataType>;
  };
} = {
  alertSoundStatus: AlertSoundSettings.ENABLED,
  fetchStatus: DATA_FETCHING_STATUS.SUCCESS,
  filters: {
    status: [],
  },
  searchQuery: '',
  alertLogs: {
    data: {},
    apiStatus: API_STATUS.LOADING,
    pageMetadata: {
      totalResults: 0,
      page: 0,
      limit: 0,
    },
  },
  devicesDisconnectionOverview: {
    data: {},
    apiStatus: API_STATUS.LOADING,
    pageMetadata: {},
  },
};

const slice = createSlice({
  name: STATE_KEY,
  initialState: INITIAL_STATE,
  reducers: {
    applyFilter: (state, action: PayloadAction<DeviceFilter>) => {
      const { filter, value } = action.payload;

      switch (filter) {
        case 'status':
          state.filters[filter] = [...state.filters[filter], value];
          return;
      }
    },
    removeFilter: (state, action: PayloadAction<DeviceFilter>) => {
      const { filter, value } = action.payload;

      switch (filter) {
        case 'status':
          state.filters[filter] = state.filters[filter].filter(
            f => f !== value,
          );
          return;
      }
    },
    resetFilters: (state, action: PayloadAction<DeviceFilter['filter']>) => {
      const filter = action.payload;

      switch (filter) {
        case 'status':
          state.filters[filter] = [];
          return;
      }
    },
    applySearchQuery: (state, action: PayloadAction<string>) => {
      state.searchQuery = action.payload;
    },
    changeAlertSoundStatus: (
      state,
      action: PayloadAction<AlertSoundSettings>,
    ) => {
      state.alertSoundStatus = action.payload;
    },
    fetchSubtenantAlertLogSuccess: (
      state,
      action: PayloadAction<{ subtenantId: UUID; alerts: AlertLog[] }>,
    ) => {
      const { subtenantId, alerts } = action.payload;

      state.alertLogs.apiStatus = API_STATUS.OK;
      state.alertLogs.data[subtenantId] = alerts;
    },
    fetchSubtenantAlertLogMetadataSuccess: (
      state,
      action: PayloadAction<PageMetaDataType | undefined>,
    ) => {
      state.alertLogs.pageMetadata = action?.payload;
    },
    fetchSubtenantAlertLogFailed: state => {
      state.alertLogs.apiStatus = API_STATUS.ERROR;
    },
    fetchSubtenantDisconnectionOverviewSuccess: (
      state,
      action: PayloadAction<{
        subtenantId: UUID;
        disconnectionOverview: DeviceDisconnectionOverview[];
        metaData: PageMetaDataType;
      }>,
    ) => {
      const { subtenantId, disconnectionOverview, metaData } = action.payload;

      state.devicesDisconnectionOverview.apiStatus = API_STATUS.OK;
      state.devicesDisconnectionOverview.data[subtenantId] =
        disconnectionOverview;
      state.devicesDisconnectionOverview.pageMetadata[subtenantId] =
        metaData || {};
    },
    fetchSubtenantDisconnectionOverviewFailed: state => {
      state.devicesDisconnectionOverview.apiStatus = API_STATUS.ERROR;
    },
  },
  extraReducers: builder => {
    builder.addCase(actions.fetchSubtenantAlertLog, state => {
      state.alertLogs.apiStatus = API_STATUS.LOADING;
    });
    builder.addCase(actions.fetchSubtenantDisconnectionOverview, state => {
      state.devicesDisconnectionOverview.apiStatus = API_STATUS.LOADING;
    });
  },
});

const extraActions = {
  gmDashboardPageMounted: createAction(`${STATE_KEY}/gmDashboardPageMounted`),
  gmDashboardPageUnmounted: createAction(
    `${STATE_KEY}/gmDashboardPageUnmounted`,
  ),
  fetchSubtenantAlertLog: createAction<{
    subtenantId: UUID;
    currentPage: number | string;
    pageSize: string | number;
  }>(`${STATE_KEY}/fetchSubtenantAlertLog`),
  fetchSubtenantDisconnectionOverview: createAction<{
    subtenantId: UUID;
    continuousDisconnectionSeconds: number;
    currentPage: number | string;
    pageSize: string | number;
  }>(`${STATE_KEY}/fetchSubtenantDisconnectionOverview`),
};

const getState = (state: RootState) => state[STATE_KEY] || INITIAL_STATE;

const getSubtenantId = (_state: RootState, subtenantId: UUID) => subtenantId;

export const selectors = {
  selectFilters: createSelector(getState, state => state.filters),
  selectSearchQuery: createSelector(getState, state => state.searchQuery),
  selectAlertSoundStatus: createSelector(
    getState,
    state => state.alertSoundStatus,
  ),
  selectSubtenantAlertLog: createSelector(
    getState,
    getSubtenantId,
    (state, subtenantId) => state.alertLogs.data[subtenantId] || [],
  ),
  selectSubtenantAlertLogMetadata: createSelector(
    getState,
    state => state.alertLogs.pageMetadata,
  ),
  selectSubtenantAlertLogStatus: createSelector(
    getState,
    state => state.alertLogs.apiStatus,
  ),
  selectSubtenantDisconnectionOverview: createSelector(
    getState,
    getSubtenantId,
    (state, subtenantId) =>
      state.devicesDisconnectionOverview.data[subtenantId] || [],
  ),
  selectSubtenantDisconnectionOverviewMetadata: createSelector(
    getState,
    getSubtenantId,
    (state, subtenantId) =>
      state.devicesDisconnectionOverview.pageMetadata[subtenantId],
  ),
  selectSubtenantDisconnectionOverviewStatus: createSelector(
    getState,
    state => state.devicesDisconnectionOverview.apiStatus,
  ),
};

export const extraSelectors = {
  selectSubtenants: createSelector(
    (state: RootState) => state,
    tenantSelectors.getTenantsList,
    alertSelectors.selectAlerts,
    alertSelectors.selectMTMAttentionList,
    (state, tenants, alerts, mtmAttentionList): SubTenantOverview[] =>
      tenants.map(tenant => {
        const deviceInfoData = deviceSelectors.selectTenantDeviceStatistics(
          state,
          tenant.id,
        );

        const currentAlertsDict = gmSelectors.selectDataTenantDict(
          state,
          GM_DATA_KEYS.ALERTS,
        );
        const currentMedicalAlerts = alerts.filter(isMedicalAlert);
        const currentSubtenantAlerts = currentMedicalAlerts.filter(
          alert =>
            currentAlertsDict[alert.id] === tenant.id &&
            (alert.status !== 'OFF' || hasAlertFinishedInLast24h(alert)),
        );

        const subtenantAttentionList = mtmAttentionList[tenant.id];

        const subtenantHistoricalAlertCount = R.reduce(Ord.trivial)<
          PatientAttentionItem,
          number
        >(
          0,
          (acc2, patientAttentionList) =>
            acc2 + patientAttentionList.alertsCount,
        )(subtenantAttentionList?.medical?.byPatientId || {});
        return {
          id: tenant.id,
          name: tenant.name,
          timeZoneOffset: tenant.timeZoneOffset,
          timeZoneId: tenant.timeZoneId,
          address: tenant.address,
          totalDevicesCounter: deviceInfoData.totalDevices,
          disconnectedAssignedDevicesCounter:
            deviceInfoData.disconnectedAssignedDevices,
          disconnectedUnassignedDevicesCounter:
            deviceInfoData.disconnectedUnassignedDevices,
          connectedAssignedDevicesCounter:
            deviceInfoData.connectedAssignedDevices,
          connectedUnassignedDevicesCounter:
            deviceInfoData.connectedUnassignedDevices,
          lastDayAlertsCounter:
            currentSubtenantAlerts.length + subtenantHistoricalAlertCount,
          hasActiveAlert: currentSubtenantAlerts.some(
            alert => alert.status === 'ON',
          ),
        };
      }),
  ),
};

export const actions = { ...slice.actions, ...extraActions };
const { reducer } = slice;
export default reducer;
