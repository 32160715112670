import {
  AlertStatus,
  AverageThresholdMetric,
  BaselineAlert,
  BaselineMetric,
  ThresholdPreposition,
  VsAlert,
} from 'src/types/alerts';
import { validateMetricValue } from 'src/utils/validators';
import { PatientState } from 'src/types/measurements';
import { DefaultTheme } from 'styled-components';
import {
  isAverageAlert,
  isBaselineAlert,
} from 'src/redux/data/alerts/modules/typeGuards';
import { ALERT_METRIC_PREPOSITION_ENUM } from 'src/redux/data/constants';

type MeasurementBoxColors = {
  backgroundColor: string;
  borderColor: string;
  titleColor: string;
  valueColor: string;
};

export const LOW_QUALITY_VS_COLOR = '#9BBCCB';
export const NO_DATA_DISPLAY_VALUE = '--';

const BOX_STATE_COLORS = Object.freeze({
  deviceActive: {
    backgroundColor: '#f4f6f8',
    borderColor: '#E5E5E5',
    titleColor: '#252525',
    valueColor: '#252525',
  },
  // TODO: add back when testing alert colors is reverted NSP-1480
  // alertActive: {
  //   backgroundColor: '#00FFFF',
  //   borderColor: '#007575',
  //   titleColor: '#252525',
  //   valueColor: '#252525',
  // },
  alertSuppresed: {
    backgroundColor: '#FAF3E2',
    borderColor: '#FFAD0D',
    titleColor: '#252525',
    valueColor: '#252525',
  },
  deviceInactive: {
    backgroundColor: 'none',
    borderColor: 'unset',
    titleColor: '#E5E5E5',
    valueColor: '#E5E5E5',
  },
});

export const getMeasurementBoxColors = (
  isDeviceActive: boolean,
  alertStatus: AlertStatus | undefined,
  theme: DefaultTheme,
): MeasurementBoxColors => {
  if (!isDeviceActive) {
    return BOX_STATE_COLORS.deviceInactive;
  }

  if (alertStatus === 'ON') {
    // TODO: add back when testing alert colors is reverted NSP-1480
    // return BOX_STATE_COLORS.alertActive;
    return {
      backgroundColor: theme.colors.alertBackground,
      borderColor: theme.colors.alertBorder,
      titleColor: '#252525',
      valueColor: '#252525',
    };
  }

  if (alertStatus === 'SUPPRESSED') {
    return BOX_STATE_COLORS.alertSuppresed;
  }

  return BOX_STATE_COLORS.deviceActive;
};

export const getVSColorByQuality = (
  vsValue: string,
  quality: boolean,
  initialColor: string,
): string => {
  if (vsValue === NO_DATA_DISPLAY_VALUE) {
    return initialColor;
  }

  return quality ? initialColor : LOW_QUALITY_VS_COLOR;
};

export const getArrowIcon = (
  thresholdMetric: AverageThresholdMetric | BaselineMetric,
  thresholdPreposition: ThresholdPreposition,
) => {
  if (isAverageAlert(thresholdMetric)) {
    switch (thresholdPreposition) {
      case ALERT_METRIC_PREPOSITION_ENUM.ABOVE:
        return 'up-right-arrow-long-icon';
      case ALERT_METRIC_PREPOSITION_ENUM.BELOW:
        return 'down-right-arrow-long-icon';
    }
  }

  switch (thresholdPreposition) {
    case ALERT_METRIC_PREPOSITION_ENUM.ABOVE:
      return 'up-trend-arrow-icon';
    case ALERT_METRIC_PREPOSITION_ENUM.BELOW:
      return 'down-trend-arrow-icon';
  }
};

export const getVSAlertDetails = (alert: VsAlert) => ({
  alertDisplayValue: Math.round(alert.triggerValue),
  alertThresholdMetric: alert.thresholdMetric,
  alertThresholdPreposition: alert.thresholdPreposition,
});

export const getBaselineAlertDetails = (alert: BaselineAlert) => ({
  alertDisplayValue: Math.round(alert.baselineValue),
  alertThresholdMetric: alert.thresholdMetric,
  alertThresholdPreposition: alert.thresholdPreposition,
});

export const getDeviceAlertDetails = (
  isDeviceActive: boolean,
  alert: VsAlert | BaselineAlert | undefined,
) => {
  if (!alert || !isDeviceActive) {
    return {
      alertDisplayValue: null,
      alertThresholdMetric: null,
      alertThresholdPreposition: null,
    };
  }

  if (isAverageAlert(alert.thresholdMetric)) {
    // @ts-ignore find a way to tell typescript that this is a VsAlert
    return getVSAlertDetails(alert);
  }

  if (isBaselineAlert(alert.thresholdMetric)) {
    // @ts-ignore find a way to tell typescript that this is a BaselineAlert
    return getBaselineAlertDetails(alert);
  }

  return {
    alertDisplayValue: null,
    alertThresholdMetric: null,
    alertThresholdPreposition: null,
  };
};

const checkVSQuality = (
  quality: boolean,
  shouldDisplayHighQualityOnly: boolean,
) => (shouldDisplayHighQualityOnly ? quality : true);

export const getDisplayContinuousValue = (
  value: string | undefined | null,
  quality: boolean | undefined,
  shouldDisplayHighQualityOnly: boolean,
  metric: AverageThresholdMetric,
): string => {
  if (!value) {
    return NO_DATA_DISPLAY_VALUE; // TODO: Check if we leave '--' or ''
  }

  if (
    value === '-1' ||
    !checkVSQuality(!!quality, shouldDisplayHighQualityOnly) ||
    !validateMetricValue(value, metric)
  ) {
    return NO_DATA_DISPLAY_VALUE;
  }

  return value;
};
export const isDeviceGatheringData = (
  value: string | undefined | null,
  quality: boolean | undefined,
  state?: number | null,
  shouldDisplayHighQualityOnly?: boolean,
): boolean => {
  return (
    !!value &&
    !quality &&
    !!shouldDisplayHighQualityOnly &&
    state !== PatientState.EMPTY
  );
};
