import { useEffect, useState } from 'react';
import {
  ALL_TENANTS_OPTION,
  SETTINGS_TYPE,
} from 'src/routes/GMDashboard/modules/constants';
import {
  SubtenantItem,
  TenantSubscriptionDetails,
} from 'src/routes/GMDashboard/modules/types';
import { DataTenantDict } from 'src/redux/data/groupManager/modules/types';
import {
  checkIfCollectiveChange,
  getTenantSubscribersWithGM,
} from 'src/routes/GMDashboard/modules/settingsUtils';
import { UUID } from 'src/types/utility';
import { Subscriber } from 'src/types/subscribers';
import { MTMAlertSettingsOnSaveHandlers } from 'src/routes/GMDashboard/components/SubtenantCards/SubtenantSettings/AlertSettings/types';
import { actions } from 'src/redux/data/groupManager';
import { actions as subscriberActions } from 'src/redux/data/subscribers';
import { actions as alertActions } from 'src/redux/data/alerts';
import dayjs from 'dayjs';
import { SelectedLevelAlertSettings } from 'src/components/AlertSettingsComponents/ActivityAlerts/types';

type Props = {
  selectedSubtenants: SubtenantItem[];
  subtenants: SubtenantItem[];
  gmUserId?: UUID;
  gmTenantId?: UUID;
  allSubscribers: Subscriber[];
  gmSubscribers: Subscriber[];
  subscribersDict: DataTenantDict;
  subscriptionDetails: TenantSubscriptionDetails;
  activityAlertSettings?: SelectedLevelAlertSettings;
  updateSubtenantSubscribersList: typeof actions.updateSubtenantSubscribersList;
  updateSubscribersList: typeof subscriberActions.updateSubscribersList;
  updateMTMActivityAlertSettings: typeof alertActions.updateMTMActivityAlertSettings;
};

const DEFAULT_SAVE_HANDLERS: MTMAlertSettingsOnSaveHandlers = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  saveAlertThresholds: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  saveBaselineThresholds: () => {},
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  saveSubscribers: () => {},
};

export const useSaveHandlers = ({
  selectedSubtenants,
  subtenants,
  gmUserId,
  allSubscribers,
  gmSubscribers,
  subscribersDict,
  gmTenantId,
  subscriptionDetails,
  activityAlertSettings,
  updateSubtenantSubscribersList,
  updateSubscribersList,
  updateMTMActivityAlertSettings,
}: Props) => {
  const [onSaveHandlers, setOnSaveHandlers] =
    useState<MTMAlertSettingsOnSaveHandlers>(DEFAULT_SAVE_HANDLERS);

  useEffect(() => {
    setOnSaveHandlers(prevState => ({
      ...prevState,
      saveSubscribers: () => {
        const isCollectiveChange = checkIfCollectiveChange(selectedSubtenants);

        if (!isCollectiveChange) {
          const selectedSubtenantId = selectedSubtenants[0]?.value;

          if (selectedSubtenants.length !== 1 || !selectedSubtenantId) {
            return;
          }

          updateSubtenantSubscribersList({
            subtenantIds: [selectedSubtenantId],
            data: getTenantSubscribersWithGM(
              allSubscribers.filter(
                s => subscribersDict[s.id] === selectedSubtenantId,
              ),
              gmUserId ?? '',
              subscriptionDetails,
            ),
          });

          return;
        }

        const areAllTenantsSelected = selectedSubtenants.find(
          s => s.value === ALL_TENANTS_OPTION,
        );
        const subtenantsToUpdate = areAllTenantsSelected
          ? subtenants.filter(s => s.type !== SETTINGS_TYPE.UNIQUE)
          : selectedSubtenants;

        if (areAllTenantsSelected) {
          updateSubscribersList(
            getTenantSubscribersWithGM(
              gmSubscribers,
              gmUserId ?? '',
              subscriptionDetails,
            ),
          );
        }

        for (const subtenant of subtenantsToUpdate) {
          updateSubtenantSubscribersList({
            subtenantIds: [subtenant.value],
            data: getTenantSubscribersWithGM(
              allSubscribers.filter(
                s => subscribersDict[s.id] === subtenant.value,
              ),
              gmUserId ?? '',
              subscriptionDetails,
            ),
          });
        }
      },
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptionDetails, selectedSubtenants]);

  useEffect(() => {
    if (!activityAlertSettings) {
      return;
    }
    const areAllTenantsSelected = selectedSubtenants.find(
      s => s.value === ALL_TENANTS_OPTION,
    );

    const subtenantsToUpdate = areAllTenantsSelected
      ? [{ value: gmTenantId }]
      : selectedSubtenants;
    setOnSaveHandlers(prevState => ({
      ...prevState,
      saveActivityAlertsSettings: () =>
        updateMTMActivityAlertSettings({
          ...activityAlertSettings,
          outOfBedAlertSettings: {
            ...activityAlertSettings.outOfBedAlertSettings,
            bedtimeExitAlertSettings: {
              ...activityAlertSettings.outOfBedAlertSettings
                .bedtimeExitAlertSettings,
              startTime: dayjs(
                activityAlertSettings.outOfBedAlertSettings
                  .bedtimeExitAlertSettings.startTime,
              ).format('HH:mm'),
              endTime: dayjs(
                activityAlertSettings.outOfBedAlertSettings
                  .bedtimeExitAlertSettings.endTime,
              ).format('HH:mm'),
            },
          },
          // @ts-ignore need to define better types
          // eslint-disable-next-line @typescript-eslint/no-unsafe-return
          tenantIds: subtenantsToUpdate.map(elem => elem.value),
        }),
    }));
  }, [activityAlertSettings, selectedSubtenants, subtenants, gmTenantId]);

  return {
    onSaveHandlers,
    setOnSaveHandlers,
  };
};
