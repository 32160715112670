import Axios, { AxiosInstance, InternalAxiosRequestConfig } from 'axios';
import qs from 'qs';

import UmsLogic from 'src/libs/ums-js-logic';
import { Alert } from 'src/types/alerts';
import { DateUTC, SerialNumber, UUID } from 'src/types/utility';
import AppConfig from '../config/AppConfig';
import { resourceCreators, resources } from './resources';
import {
  CreateAlertThresholdResponse,
  CreateBaselineAlertThresholdResponse,
  CreateGroupResponse,
  CreateMeasurementSettingsResponse,
  CreateNotePayload,
  CreateNoteResponse,
  DeleteAlertThresholdResponse,
  FetchAdminDevicesResponse,
  FetchAggregatedContinuousMeasurementsResponse,
  FetchAlertLogsDataResponse,
  FetchAlertSettingsResponse,
  FetchAlertsListResponse,
  FetchAlertThresholdsResponse,
  FetchAlertWithPersonalDetailsResponse,
  FetchBaselineAlertThresholdsResponse,
  FetchBedOccupancyDataResponse,
  FetchConsentResponse,
  FetchDefaultVersionResponse,
  FetchDeviceCredentialsResponse,
  FetchDevicesLatestSessionsResponse,
  FetchDevicesResponse,
  FetchGroupClientsResponse,
  FetchGroupListResponse,
  FetchInvitedUsersResponse,
  FetchMeasurementSettingsResponse,
  FetchMetricHistogramDataResponse,
  GetCustomersResponse,
  MapFacilitiesPayload,
  GetFacilitiesResponse,
  GetTenantsByCustomerIdResponse,
  QueryFilter,
  PaginationParams,
  FetchMTMAttentionListResponse,
  FetchMTMDeviceConnectionInfoResponse,
  FetchPatientAlertTypesResponse,
  FetchPatientNotesReponse,
  FetchPatientsLatestSessionsResponse,
  FetchPatientsNoteCounterMetadataResponse,
  FetchPatientsResponse,
  FetchRoomsResponse,
  FetchStatisticsDataResponse,
  FetchSubscribersResponse,
  FetchSubscriberStatusResponse,
  FetchTenantResponse,
  FetchTenantsResponse,
  FetchUserConsentsResponse,
  FetchUserDetailsResponse,
  FetchUsersResponse,
  FetchUserTypesResponse,
  FetchVersionResponse,
  PatchBedExitAlertStatusResponse,
  StartSessionResponse,
  UpdateAlertThresholdResponse,
  UpdateBaselineAlertThresholdResponse,
  UpdateMeasurementSettingsResponse,
  UpdateSubscribersListPayload,
  UpdateSubscribersPayload,
  UpdateSubscribersResponse,
} from './types';
import {
  buildKeyGenerator,
  CacheRequestConfig,
  setupCache,
} from 'axios-cache-interceptor';
import { getBuildGeneratorKey, getHeaderInterpreter } from 'src/services/utils';

import {
  CreateCustomerPayload,
  CustomerType,
} from 'src/routes/Customers/modules/types';
import { ActivityAlertSettingsRoot } from 'src/components/AlertSettingsComponents/ActivityAlerts/types';
/**
|--------------------------------------------------
| The server service - expose interface to the API.
| Every method returns a promise.
|--------------------------------------------------
*/

// TODO: Add response types for all resources
// TODO: Add request types for all resources
// TODO: Add better typing for resources so can respond to ctrl+click

const DEFAULT_LIMIT = 1000000;
const DEFAULT_PAGE = 0;

const filterParamsString = (filter: QueryFilter): string => {
  let paramsString = '';

  filter.forEach((item, index) => {
    const char = index ? '&' : '?';
    paramsString += `${char}filter%5B${index}%5D.prop=${item.prop}`;
    item.values.forEach(value => {
      paramsString += `&filter%5B${index}%5D.values=${value}`;
    });
  });

  return paramsString;
};

const searchParamString = (searchKeyword: string): string => {
  return `?search=${searchKeyword}`;
};

const paginationParamString = (pagination: PaginationParams): string => {
  const page: number = pagination?.page || DEFAULT_PAGE;
  const limit: number = pagination?.limit || DEFAULT_LIMIT;

  return `?page=${page}&limit=${limit}`;
};

const beforeRequestInterceptor = (
  request: InternalAxiosRequestConfig,
): InternalAxiosRequestConfig => {
  // @ts-ignore Note: Custom request also includes requireAuth bool property
  if (!request.headers?.getAuthorization() && request.requireAuth) {
    const token = `Bearer ${UmsLogic.getApiCallToken()}`;
    request.headers.setAuthorization(token);
  }
  return request;
};

class BackendService {
  _axiosInstance: AxiosInstance;
  _axios: AxiosInstance;

  constructor() {
    this._axiosInstance = Axios.create({
      baseURL: AppConfig.API_URL,
    });
    this._axios = setupCache(this._axiosInstance, {
      // debug: console.log,
      headerInterpreter: getHeaderInterpreter,
      generateKey: buildKeyGenerator(
        ({
          baseURL = '',
          url = '',
          method = 'get',
          params,
          data,
          headers,
        }: CacheRequestConfig) =>
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          getBuildGeneratorKey({ baseURL, url, method, params, data, headers }),
      ),
    });
    this._axios.interceptors.request.use(beforeRequestInterceptor);
  }

  getUsers = () => this._axios.request(resources.getUsers);

  getUsersByUserType = (userTypeId: UUID) =>
    this._axios.request<FetchUsersResponse>({
      ...resourceCreators.getUsersByUserType(
        filterParamsString([
          {
            prop: 'userType.id',
            values: [userTypeId],
          },
        ]),
      ),
    });

  getDevices = (data: unknown) =>
    this._axios.request<FetchDevicesResponse>({
      ...resources.getDevices,
      data,
    });

  getDeviceById = (id: UUID) =>
    this._axios.request<FetchDevicesResponse>({
      ...resourceCreators.getDeviceById(
        filterParamsString([
          {
            prop: 'manufacturerId',
            values: [id],
          },
        ]),
      ),
    });

  getAdminDevicesByTenantId = (id: UUID) =>
    this._axios.request<FetchDevicesResponse>({
      ...resourceCreators.getAdminDevicesByTenantId(
        filterParamsString([
          {
            prop: 'tenantId',
            values: [id],
          },
        ]),
      ),
    });

  adminGetDevices = (paginationParams: PaginationParams) => {
    const queryString = paginationParamString(paginationParams);

    return this._axios.request<FetchAdminDevicesResponse>({
      ...resourceCreators.adminGetDevices(queryString),
    });
  };

  adminSearchAllDevices = (searchKeyword: string) => {
    const queryString = searchParamString(searchKeyword);

    return this._axios.request<FetchAdminDevicesResponse>({
      ...resourceCreators.adminSearchAllDevices(queryString),
    });
  };

  adminCreateDevice = (data: unknown) =>
    this._axios.request({ ...resources.adminCreateDevice, data });

  updateDevice = (manufacturerId: string, data: unknown) =>
    this._axios.request({
      ...resourceCreators.updateDevice(manufacturerId),
      data,
    });

  adminUpdateDevice = (manufacturerId: string, data: unknown) =>
    this._axios.request({
      ...resourceCreators.adminUpdateDevice(manufacturerId),
      data,
    });

  adminUpdateDeviceTenant = (manufacturerId: string, tenantId: string | null) =>
    this._axios.request({
      ...resourceCreators.adminUpdateDeviceTenant(manufacturerId),
      // @ts-ignore TODO: Check why null can be set here
      headers: { tenantId },
    });

  adminDeleteDevice = (manufacturerId: string) =>
    this._axios.request({
      ...resourceCreators.adminDeleteDevice(manufacturerId),
    });

  getTenantsPaginated = (paginationParams: PaginationParams) => {
    const queryString = paginationParamString(paginationParams);

    return this._axios.request<FetchTenantsResponse>(
      resourceCreators.getTenantsPaginated(queryString),
    );
  };

  searchAllTenants = (searchKeyword: string) => {
    const queryString = searchParamString(searchKeyword);

    return this._axios.request<FetchTenantsResponse>({
      ...resourceCreators.searchAllTenants(queryString),
    });
  };

  createTenant = (data: unknown) =>
    this._axios.request({ ...resources.createTenant, data });

  getTenants = () =>
    this._axios.request<FetchTenantsResponse>(resources.getTenants);

  getSubtenants = (parentTenantId: string) =>
    this._axios.request<FetchTenantsResponse>({
      ...resourceCreators.getSubtenants(parentTenantId),
    });

  deleteTenant = (id: string) =>
    this._axios.request({ ...resourceCreators.deleteTenant(id) });

  updateTenant = (id: string, data: unknown) =>
    this._axios.request({ ...resourceCreators.updateTenant(id), data });

  login = (data: unknown) => this._axios.request({ ...resources.login, data }); // TODO: use UMS package to login (when ready + has users)

  getUserDetails = () =>
    this._axios.request<FetchUserDetailsResponse>({
      ...resources.getUserDetails,
    });

  getSpotMeasurements = ({
    patientId,
    startTime,
    endTime,
  }: {
    patientId: string;
    startTime: string;
    endTime: string;
  }) =>
    this._axios.request({
      ...resourceCreators.getSpotMeasurements(patientId),
      params: { startTime, endTime },
    });

  getAllSessions = ({ patientId }: { patientId: string }) =>
    this._axios.request({ ...resourceCreators.getAllSessions(patientId) });

  getSessionGraphPoints = ({
    sessionId,
    metric,
  }: {
    sessionId: string;
    metric: string;
  }) =>
    this._axios.request({
      ...resourceCreators.queryBySession(sessionId),
      params: { metric },
    });

  getDateTimeGraphPoints = ({
    patientId,
    metric,
    startTime,
    endTime,
  }: {
    patientId: string;
    metric: string;
    startTime: string;
    endTime: string;
  }) =>
    this._axios.request<FetchStatisticsDataResponse>({
      ...resourceCreators.queryByDateTime(patientId),
      params: { metric, startTime, endTime },
    });

  getGraphDataByDateTime = ({
    patientId,
    metric,
    startTime,
    endTime,
  }: {
    patientId: string;
    metric: string;
    startTime: string;
    endTime: string;
  }) =>
    this._axios.request<FetchAggregatedContinuousMeasurementsResponse>({
      ...resourceCreators.queryGraphDataByDateTime(patientId),
      params: { metric, startTime, endTime },
    });

  getPatients = () =>
    this._axios.request<FetchPatientsResponse>({ ...resources.getPatients });

  deletePatient = (id: string) =>
    this._axios.request({ ...resourceCreators.deletePatient(id) });

  createPatient = (data: unknown) =>
    this._axios.request({ ...resources.createPatient, data });

  updatePatient = ({ id, data }: { id: string; data: unknown }) =>
    this._axios.request({ ...resourceCreators.updatePatient(id), data });

  deviceCredentials = (data: { deviceIds: SerialNumber[] }) =>
    this._axios.request<FetchDeviceCredentialsResponse>({
      ...resources.deviceCredentials,
      data,
    });

  startContinuous = (
    deviceId: string,
    patientId: string,
    saveRawData: boolean,
  ) =>
    this._axios.request<StartSessionResponse>({
      ...resourceCreators.startContinuous(deviceId),
      data: { patientId, saveRawData },
    });

  stopContinuous = (deviceId: string) =>
    this._axios.request<void>({ ...resourceCreators.stopContinuous(deviceId) });

  startSpot = (deviceId: string, patientId: string) =>
    this._axios.request({
      ...resourceCreators.startSpot(deviceId),
      data: { patientId },
    });

  getAllUserTypes = (data: unknown) =>
    this._axios.request<FetchUserTypesResponse>({
      ...resources.getAllUserTypes,
      data,
    });

  getAllUsers = (data: unknown) =>
    this._axios.request<FetchUsersResponse>({ ...resources.getAllUsers, data });
  getMfaUsersData = (data: unknown) =>
    this._axios.request<FetchUsersResponse>({
      ...resources.getMfaUsersData,
      data,
    });

  inviteUser = (data: unknown) =>
    this._axios.request({ ...resources.inviteUser, data });

  updateUser = ({ userId, data }: { userId: string; data: unknown }) =>
    this._axios.request({ ...resourceCreators.updateUser(userId), data });

  updateMfaUserInfo = ({ userId, data }: { userId: string; data: unknown }) =>
    this._axios.request({
      ...resourceCreators.updateMfaUserInfo(userId),
      data,
    });

  deleteUser = (userId: string) =>
    this._axios.request({ ...resourceCreators.deleteUser(userId) });

  getGroups = (page: string | number = 1, limit: string | number = 20) => {
    const queryString = paginationParamString({
      page: parseInt(`${page}`),
      limit: parseInt(`${limit}`),
    });
    return this._axios.request<FetchGroupListResponse>({
      ...resourceCreators.getGroups(queryString),
    });
  };
  createGroup = (data: { name: string; applicationIds: UUID[] }) =>
    this._axios.request<CreateGroupResponse>({
      ...resources.createGroup,
      data,
    });

  adminSearchAllGroups = (searchKeyword: string) => {
    const queryString = searchParamString(searchKeyword);

    return this._axios.request<FetchGroupListResponse>({
      ...resourceCreators.searchGroups(queryString),
    });
  };

  getUploadUrl = (versionId: string) =>
    this._axios.request({ ...resourceCreators.getUploadUrl(versionId) });

  deleteGroup = (groupId: string) =>
    this._axios.request({ ...resourceCreators.deleteGroup(groupId) });

  getVersionByGroupId = (groupId: string) =>
    this._axios.request({ ...resourceCreators.getVersionByGroupId(groupId) });

  getGroupClients = () =>
    this._axios.request<FetchGroupClientsResponse>({
      ...resourceCreators.getGroupClients(),
    });

  assignDevice = ({
    groupId,
    data,
  }: {
    groupId: UUID;
    data: { clientIds: SerialNumber[]; removeOldGroupIfExists: boolean };
  }) =>
    this._axios.request({ ...resourceCreators.assignDevice(groupId), data });

  editAssignedDevices = ({
    groupId,
    data,
  }: {
    groupId: UUID;
    data: {
      clientIds: SerialNumber[];
      unassignedClientIds: SerialNumber[];
      removeOldGroupIfExists: boolean;
    };
  }) => {
    return this._axios.request({
      ...resourceCreators.editAssignedDevices(groupId),
      data,
    });
  };

  updateGroup = ({ groupId, data }: { groupId: string; data: unknown }) =>
    this._axios.request({ ...resourceCreators.updateGroup(groupId), data });

  createVersion = ({ versionId, data }: { versionId: string; data: unknown }) =>
    this._axios.request({ ...resourceCreators.createVersion(versionId), data });

  updateVersion = ({ versionId, data }: { versionId: string; data: unknown }) =>
    this._axios.request({ ...resourceCreators.updateVersion(versionId), data });

  getDefaultVersion = (appId: string) =>
    this._axios.request<FetchDefaultVersionResponse>({
      ...resourceCreators.getDefaultVersion(appId),
    });

  updateDefaultVersion = ({ appId, data }: { appId: string; data: unknown }) =>
    this._axios.request({
      ...resourceCreators.updateDefaultVersion(appId),
      data,
    });

  getVersionById = (versionId: string) =>
    this._axios.request<FetchVersionResponse>({
      ...resourceCreators.getVersionById(versionId),
    });

  getVersion = (filter?: QueryFilter) =>
    this._axios.request({
      ...resourceCreators.getVersion(filterParamsString(filter || [])),
    });

  uploadFile = ({
    url,
    fileToUpload,
  }: {
    url: string;
    fileToUpload: unknown;
  }) => Axios({ method: 'PUT', url, data: fileToUpload });

  deleteDeviceCertificate = (manufacturerId: string) =>
    this._axios.request({
      ...resourceCreators.deleteDeviceCertificate(manufacturerId),
    });

  getUserConsents = () =>
    this._axios.request<FetchUserConsentsResponse>({
      ...resourceCreators.getUserConsents(),
    });

  getConsent = (consentId: string) =>
    this._axios.request<FetchConsentResponse>({
      ...resourceCreators.getConsent(consentId),
    });

  confirmConsent = (revisionId: string) =>
    this._axios.request<void>({
      ...resourceCreators.confirmConsent(),
      data: { revisionId, localeName: 'eng' },
    });

  getHriData = ({
    patientId,
    startTime,
    stopTime,
  }: {
    patientId: string;
    startTime: string;
    stopTime: string;
  }) =>
    this._axios.request({
      ...resourceCreators.getHriData(patientId),
      params: { startTime, stopTime },
    });

  getHriFile = (url: string) =>
    this._axios.request(resourceCreators.getHriFile(url));

  deleteVersion = (versionId: string) =>
    this._axios.request({ ...resourceCreators.deleteVersion(versionId) });

  getSessionsForDevice = ({
    deviceId,
    startDateTime,
    endDateTime,
  }: {
    deviceId: string;
    startDateTime: string;
    endDateTime: string;
  }) =>
    this._axios.request({
      ...resourceCreators.getSessionsForDevice(deviceId),
      params: { startDateTime, endDateTime },
    });

  getSessionDataToDownload = ({
    sessionId,
    startTime,
    endTime,
  }: {
    sessionId: string;
    startTime: string;
    endTime: string;
  }) =>
    this._axios.request({
      ...resourceCreators.getSessionDataToDownload(sessionId),
      params: { startTime, endTime },
    });

  getTenant = () =>
    this._axios.request<FetchTenantResponse>({ ...resources.getTenant });

  getInvitedUsers = () =>
    this._axios.request<FetchInvitedUsersResponse>({
      ...resources.getInvitedUsers,
    });

  resendInvitation = (invitationId: string) =>
    this._axios.request({ ...resourceCreators.resendInvitation(invitationId) });

  deleteInvitation = (invitationId: string) =>
    this._axios.request({ ...resourceCreators.deleteInvitation(invitationId) });

  getDeviceStatus = (devicesId: string) =>
    this._axios.request({ ...resourceCreators.getDeviceStatus(devicesId) });

  getDevicesStatuses = (devicesIds: string) =>
    this._axios.request<FetchDevicesLatestSessionsResponse>({
      ...resourceCreators.getDevicesStatuses(devicesIds),
    });

  getPatientsLatestSessions = (patientIds: string) =>
    this._axios.request<FetchPatientsLatestSessionsResponse>({
      ...resourceCreators.getPatientsLatestSessions(patientIds),
    });

  getAllLatestSessions = () =>
    this._axios.request<FetchDevicesLatestSessionsResponse>(
      resourceCreators.getAllLatestSessions(),
    );

  getVSAlertThresholds = () =>
    this._axios.request<FetchAlertThresholdsResponse>({
      ...resources.getVSAlertThresholds,
    });

  createAlertThreshold = (data: unknown) =>
    this._axios.request<CreateAlertThresholdResponse>({
      ...resources.createAlertThreshold,
      data,
    });

  updateAlertThreshold = (id: string, data: unknown) =>
    this._axios.request<UpdateAlertThresholdResponse>({
      ...resourceCreators.updateAlertThreshold(id),
      data,
    });

  getBaselineAlertThresholds = () =>
    this._axios.request<FetchBaselineAlertThresholdsResponse>({
      ...resources.getBaselineAlertThresholds,
    });

  createBaselineAlertThreshold = (data: unknown) =>
    this._axios.request<CreateBaselineAlertThresholdResponse>({
      ...resources.createBaselineAlertThreshold,
      data,
    });

  updateActivityAlertSettings = (data: unknown) =>
    this._axios.request<any>({
      ...resources.updateActivityAlertSettings,
      data,
    });

  updateMTMActivityAlertSettings = (data: unknown) =>
    this._axios.request<any>({
      ...resources.updateMTMActivityAlertSettings,
      data,
    });

  updateBaselineAlertThreshold = (id: string, data: unknown) =>
    this._axios.request<UpdateBaselineAlertThresholdResponse>({
      ...resourceCreators.updateBaselineAlertThreshold(id),
      data,
    });

  deleteAlertThreshold = (alertId: string) =>
    this._axios.request<DeleteAlertThresholdResponse>({
      ...resourceCreators.deleteAlertThreshold(alertId),
    });

  clearAlert = (id: string) =>
    this._axios.request({ ...resourceCreators.clearAlert(id) });

  fetchPatientsCurrentAlerts = (patientIds: string[]) =>
    this._axios.request<FetchAlertsListResponse>({
      ...resources.fetchPatientsCurrentAlerts,
      params: { patientIds },
      paramsSerializer: params => qs.stringify(params, { indices: false }),
    });

  fetchPatientsHistoricalAlerts = (
    patientIds: string[],
    startDateTime: DateUTC,
    endDateTime: DateUTC,
  ) =>
    this._axios.request<FetchAlertsListResponse>({
      ...resources.fetchPatientsHistoricalAlerts,
      params: { patientIds, startDateTime, endDateTime },
      paramsSerializer: params => qs.stringify(params, { indices: false }),
    });

  suppressAlertType = (alertId: UUID) =>
    this._axios.request<Alert>({
      ...resourceCreators.suppressAlertType(alertId),
    });

  unSuppressAlertType = (alertId: UUID) =>
    this._axios.request<Alert>({
      ...resourceCreators.unSuppressAlertType(alertId),
    });

  clearAllPatientAlerts = (alertId: UUID) =>
    this._axios.request<Alert>({
      ...resourceCreators.clearAllPatientAlerts(alertId),
    });

  getMeasurementSettings = () =>
    this._axios.request<FetchMeasurementSettingsResponse>(
      resources.getMeasurementSettings,
    );

  createMeasurementSettings = (data: { highQuality: boolean }) =>
    this._axios.request<CreateMeasurementSettingsResponse>({
      ...resources.createMeasurementSettings,
      data,
    });

  updateMeasurementSettings = (data: { highQuality: boolean }) =>
    this._axios.request<UpdateMeasurementSettingsResponse>({
      ...resources.updateMeasurementSettings,
      data,
    });

  getRooms = () => this._axios.request<FetchRoomsResponse>(resources.getRooms);

  createRoom = (data: unknown) =>
    this._axios.request({ ...resources.createRoom, data });

  updateRoom = (id: string, data: unknown) =>
    this._axios.request({ ...resourceCreators.updateRoom(id), data });

  deleteRoom = (alertId: string) =>
    this._axios.request({ ...resourceCreators.deleteRoom(alertId) });

  getAlertsSettings = () =>
    this._axios.request<FetchAlertSettingsResponse>(
      resources.getAlertsSettings,
    );

  deleteAlertsSettings = () =>
    this._axios.request(resources.deleteAlertsSettings);
  fetchActivityAlertSettings = () =>
    this._axios.request<ActivityAlertSettingsRoot>(
      resources.fetchActivityAlertSettings,
    );
  fetchMTMActivityAlertSettings = () =>
    this._axios.request<ActivityAlertSettingsRoot>(
      resources.fetchMTMActivityAlertSettings,
    );
  getHistogramData = ({
    patientId,
    startTime,
    endTime,
  }: {
    patientId: string;
    startTime: string;
    endTime: string;
  }) =>
    this._axios.request<FetchMetricHistogramDataResponse>(
      resourceCreators.getHistogramData(patientId, startTime, endTime),
    );

  assignDeviceToBed = ({
    bedId,
    deviceId,
  }: {
    bedId: string;
    deviceId: string;
  }) =>
    this._axios.request(resourceCreators.assignDeviceToBed(bedId, deviceId));

  createBedInRoom = ({ roomId, name }: { roomId: string; name: string }) =>
    this._axios.request<{ id: string; name: string }>({
      ...resourceCreators.createBedInRoom(),
      data: { roomId, name },
    });

  getSubscriberStatus = (
    userId: string,
    targetMedia: string,
    alertType: string,
  ) =>
    this._axios.request<FetchSubscriberStatusResponse>(
      resourceCreators.getSubscriberStatus(userId, targetMedia, alertType),
    );

  updateSubscribers = (userId: UUID, data: UpdateSubscribersPayload) =>
    this._axios.request<UpdateSubscribersResponse>({
      ...resourceCreators.updateSubscribers(userId),
      data,
    });

  getSubscribersList = () =>
    this._axios.request<FetchSubscribersResponse>(resources.getSubscribersList);

  updateSubscribersList = (data: UpdateSubscribersListPayload) =>
    this._axios.request<UpdateSubscribersResponse>({
      ...resources.updateSubscribersList,
      data,
    });

  getBedOccupancyData = ({
    patientId,
    startTime,
    endTime,
  }: {
    patientId: string;
    startTime: string;
    endTime: string;
  }) =>
    this._axios.request<FetchBedOccupancyDataResponse>(
      resourceCreators.getBedOccupancyData(patientId, startTime, endTime),
    );

  getAlertsLogData = ({
    patientId,
    startDate,
    endDate,
    page = 1,
    limit = 30,
  }: {
    patientId: string;
    startDate: string;
    endDate: string;
    page?: number;
    limit?: number;
  }) => {
    const queryString = paginationParamString({ page, limit });

    return this._axios.request<FetchAlertLogsDataResponse>(
      resourceCreators.getAlertsLogData(
        patientId,
        startDate,
        endDate,
        queryString,
      ),
    );
  };

  getPatientAlertTypesSettings = (patientIds: string) =>
    this._axios.request<FetchPatientAlertTypesResponse>({
      ...resourceCreators.getPatientAlertTypesSettings(patientIds),
    });

  setBedExitAlertStatus = (patientId: string, data: unknown) =>
    this._axios.request<PatchBedExitAlertStatusResponse>({
      ...resourceCreators.setBedExitAlertStatus(patientId),
      data,
    });

  fetchPatientNotes = (patientId: string) =>
    this._axios.request<FetchPatientNotesReponse>({
      ...resourceCreators.fetchPatientNotes(patientId),
    });
  fetchPatientsNoteCounterMetadata = (patientIds: string[]) =>
    this._axios.request<FetchPatientsNoteCounterMetadataResponse>({
      ...resources.fetchPatientsNoteCounterMetadata,
      params: {
        patientIds,
      },
      paramsSerializer: params => qs.stringify(params, { indices: false }),
    });
  createNote = (payload: CreateNotePayload) =>
    this._axios.request<CreateNoteResponse>({
      ...resourceCreators.createNote(payload.patientId),
      data: payload,
    });

  fetchAlertWithPersonalDetails = (alertId: UUID) =>
    this._axios.request<FetchAlertWithPersonalDetailsResponse>({
      ...resourceCreators.fetchAlertWithPersonalDetails(alertId),
    });

  fetchMTMAttentionList = (startDateTime: DateUTC, endDateTime: DateUTC) =>
    this._axios.request<FetchMTMAttentionListResponse>({
      ...resources.fetchMTMAttentionList,
      params: { startDateTime, endDateTime },
    });

  fetchMTMDeviceConnectionInfo = (continuousDisconnectionSeconds: number) =>
    this._axios.request<FetchMTMDeviceConnectionInfoResponse>({
      ...resources.fetchMTMDeviceConnectionInfo,
      params: { continuousDisconnectionSeconds },
    });

  getCustomers = () =>
    this._axios.request<GetCustomersResponse>({
      ...resources.getCustomers,
    });

  createCustomer = (payload: CreateCustomerPayload) =>
    this._axios.request<CustomerType>({
      ...resources.createCustomer,
      data: payload,
    });

  updateCustomer = (customerId: UUID, customerForm: CreateCustomerPayload) =>
    this._axios.request({
      ...resourceCreators.updateCustomer(customerId),
      data: customerForm,
    });

  deleteCustomer = (customerId: UUID) =>
    this._axios.request({
      ...resourceCreators.deleteCustomer(customerId),
    });

  getTenantsByCustomerId = (customerId: UUID) =>
    this._axios.request<GetTenantsByCustomerIdResponse>({
      ...resourceCreators.getTenantsByCustomerId(customerId),
    });

  getFacilitiesByEmrType = (customerId: UUID, emrType: string) =>
    this._axios.request<GetFacilitiesResponse>(
      resourceCreators.getFacilitiesByEmrType(customerId, emrType),
    );

  mapEmrFacilities = (data: MapFacilitiesPayload) =>
    this._axios.request({
      ...resources.mapEmrFacilities,
      data,
    });

  fetchMTMAlerts = () =>
    this._axios.request<FetchAlertsListResponse>({
      ...resources.fetchMTMAlerts,
      params: {
        ignoreSingleDeviceDisconnection: true,
      },
      paramsSerializer: params => qs.stringify(params, { indices: false }),
    });

  fetchMTMRooms = (MtmDeviceIds: Array<SerialNumber | null>) =>
    this._axios.request<FetchRoomsResponse>({
      ...resources.fetchMTMRooms,
      params: {
        deviceIds: [...MtmDeviceIds],
      },
      paramsSerializer: params => qs.stringify(params, { indices: false }),
    });

  fetchMTMPatientDetails = (patientIds: Array<UUID>) =>
    this._axios.request<FetchPatientsResponse>({
      ...resources.fetchMTMPatients,
      params: {
        ...(patientIds?.length
          ? {
              'filter[0]': {
                prop: 'id',
                values: patientIds,
              },
            }
          : {}),
      },
      paramsSerializer: params =>
        qs.stringify(params, {
          indices: true,
          arrayFormat: 'repeat',
          allowDots: true,
        }),
    });
}

export default new BackendService();
