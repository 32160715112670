import { all, call, put, takeEvery, select } from 'typed-redux-saga/macro';
import { AxiosResponse } from 'axios';

import MultiTenantService, {
  extractResponseData,
} from 'src/services/MultiTenantService';
import { actions as alertActions } from 'src/redux/data/alerts';
import { actions as patientActions } from 'src/redux/data/patient';
import { actions as deviceActions } from 'src/redux/data/device';
import { actions as roomActions } from 'src/redux/data/rooms';
import { actions as sessionActions } from 'src/redux/data/sessions';
import { actions as userActions } from 'src/redux/data/user';
import { actions as subscriberActions } from 'src/redux/data/subscribers';
import {
  createSoundMap,
  mapCurrentAlerts,
} from 'src/redux/data/alerts/modules/utils';
import { actions } from './slice';
import { GM_DATA_KEYS } from './constants';
import { mapSingleItemResponse, mapMultiItemResponse } from './utils';
import {
  FetchAlertThresholdsResponse,
  FetchBaselineAlertThresholdsResponse,
  FetchDevicesLatestSessionsResponse,
  FetchPatientsLatestSessionsResponse,
  FetchSubscribersResponse,
  FetchPatientsResponse,
  FetchRoomsResponse,
} from 'src/services/types';
import BackendService from 'src/services/BackendService';
import { Alert, BaselineAlertThreshold } from 'src/types/alerts';
import { SerialNumber, UUID } from 'src/types/utility';
import { selectors as loggedInUserSelectors } from 'src/redux/data/loggedInUser';
import { Room } from 'src/types/rooms';
import { DATA_FETCHING_STATUS } from 'src/redux/data/constants';
import { hasEditOrCreateAlertThresholdsPermissions } from 'src/utils/permissions';
import { getAlertLogsUpdated } from 'src/utils/alertHelpers';

function* fetchCurrentAlertsData() {
  const { data } = yield* call(BackendService.fetchMTMAlerts);
  const tenantsAlertData: Record<SerialNumber, { alerts: Alert[] }> = {};
  const { alerts: currentAlertsList } = data;
  const timezone = yield* select(loggedInUserSelectors.getUserTenantTimezone);
  const updatedAlerts = getAlertLogsUpdated(
    currentAlertsList,
    timezone,
  ) as Alert[];
  const patientCurrentAlerts = mapCurrentAlerts(updatedAlerts);

  yield* put(
    alertActions.fetchAlertsSuccess({
      alertsList: updatedAlerts,
      alertsMap: patientCurrentAlerts,
    }),
  );
  yield* put(
    alertActions.alertSoundsChanged(createSoundMap(patientCurrentAlerts)),
  );
  updatedAlerts.forEach(elem => {
    if (!elem.tenantId) {
      return;
    }
    if (!tenantsAlertData[elem.tenantId]) {
      tenantsAlertData[elem.tenantId] = { alerts: [elem] };
    } else {
      tenantsAlertData[elem.tenantId]?.alerts.push(elem);
    }
  });

  const tenantDict = mapMultiItemResponse(tenantsAlertData, response =>
    response.alerts.map(alert => alert.id),
  );

  yield* put(
    actions.fetchSubtenantDataSuccess({
      dataKey: GM_DATA_KEYS.ALERTS,
      dict: tenantDict,
    }),
  );
}

function* fetchTenantAlertThresholds(subtenantIds: string[] | undefined) {
  const response: FetchAlertThresholdsResponse = {
    tenantIdToThresholds: {},
  };
  const tenantResponse = yield* call(
    MultiTenantService.fetchMultitenantAlertThresholds,
    subtenantIds || [],
  );
  const extractedResponse = extractResponseData(tenantResponse);
  Object.keys(extractedResponse).forEach(key => {
    if (!extractedResponse || !extractedResponse[key]) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    response.tenantIdToThresholds = {
      ...response.tenantIdToThresholds,
      ...extractedResponse[key]?.tenantIdToThresholds,
    };
  });
  return response;
}

function* fetchTenantActivityAlertSettings(subtenantIds: string[] | undefined) {
  const tenantResponse = yield* call(
    MultiTenantService.fetchMultitenantActivityAlertSettings,
    subtenantIds ?? [],
  );
  const extractedResponse = extractResponseData(tenantResponse);
  return extractedResponse;
}

function* fetchTenantBaselineTresholds(subtenantIds: string[] | undefined) {
  const response: FetchBaselineAlertThresholdsResponse = {
    tenantBaselineThresholds: {},
  };
  const tenantResponse = yield* call(
    MultiTenantService.fetchMultitenantBaselineAlertThresholds,
    subtenantIds || [],
  );
  const extractedResponse = extractResponseData(tenantResponse);
  Object.keys(extractedResponse).forEach(key => {
    if (!extractedResponse || !extractedResponse[key]) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    response.tenantBaselineThresholds = {
      ...response.tenantBaselineThresholds,
      ...extractedResponse[key]?.tenantBaselineThresholds,
    };
  });
  return response;
}

function* fetchTenantSubscribers(subtenantIds: string[] | undefined) {
  const response: FetchSubscribersResponse = {
    tenantIdToSubscribers: {},
  };
  const tenantResponse = yield* call(
    MultiTenantService.fetchMultitenantSubscribersList,
    subtenantIds || [],
  );
  const extractedResponse = extractResponseData(tenantResponse);
  Object.keys(extractedResponse).forEach(key => {
    if (!extractedResponse || !extractedResponse[key]) {
      return;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    response.tenantIdToSubscribers = {
      ...response.tenantIdToSubscribers,
      ...extractedResponse[key]?.tenantIdToSubscribers,
    };
  });
  return response;
}

function* fetchSubtenantData(
  action: ReturnType<typeof actions.fetchSubtenantData>,
) {
  const { dataKey, subtenantIds } = action.payload;

  try {
    switch (dataKey) {
      case GM_DATA_KEYS.ALERTS: {
        yield* call(fetchCurrentAlertsData);
        return;
      }
      case GM_DATA_KEYS.ALERT_SETTINGS: {
        const { data: alertSettings } = yield* call(
          BackendService.getAlertsSettings,
        );
        const tenantsData = alertSettings.tenantAlertSettingsMap;
        const responseArray = Object.values(tenantsData);

        yield* put(alertActions.getAlertSettingsListSuccess(responseArray));
        const tenantDict = {};
        const copyOfData = { ...alertSettings };
        const tenantId = yield* select(
          loggedInUserSelectors.getCurrentTenantId,
        );
        delete copyOfData.tenantAlertSettingsMap[tenantId];
        Object.keys(copyOfData.tenantAlertSettingsMap).forEach(tenantId => {
          if (!copyOfData.tenantAlertSettingsMap) {
            return;
          }
          const alertId = alertSettings.tenantAlertSettingsMap[tenantId]?.id;
          if (alertId === undefined) {
            return;
          }
          // @ts-ignore TODO: Fix this later
          tenantDict[alertId] = tenantId;
        });

        yield* put(
          actions.fetchSubtenantDataSuccess({
            dataKey,
            dict: tenantDict,
          }),
        );

        return;
      }
      case GM_DATA_KEYS.ALERT_THRESHOLDS: {
        let response: FetchAlertThresholdsResponse = {
          tenantIdToThresholds: {},
        };
        yield* put(
          alertActions.setThresholdsStatus(DATA_FETCHING_STATUS.LOADING),
        );
        if (subtenantIds?.length) {
          response = yield* call(fetchTenantAlertThresholds, subtenantIds);
        } else {
          const { data } = yield* call(BackendService.getVSAlertThresholds);
          response = { ...data };
        }
        yield* put(
          alertActions.setThresholdsStatus(DATA_FETCHING_STATUS.SUCCESS),
        );

        const tenantsData = response.tenantIdToThresholds;
        const responseArray = Object.values(tenantsData);
        const alertThresholdsResponse = [];
        for (const response of responseArray) {
          alertThresholdsResponse.push(...response.thresholds);
        }
        yield* put(
          alertActions.getThresholdsListSuccess(alertThresholdsResponse),
        );

        const copyOfData = { ...response };
        const tenantId = yield* select(
          loggedInUserSelectors.getCurrentTenantId,
        );
        delete copyOfData.tenantIdToThresholds[tenantId];
        const tenantDict = mapMultiItemResponse(
          copyOfData.tenantIdToThresholds,
          response => response.thresholds.map(threshold => threshold.id),
        );

        yield* put(
          actions.fetchSubtenantDataSuccess({ dataKey, dict: tenantDict }),
        );

        return;
      }
      case GM_DATA_KEYS.ACTIVITY_ALERTS_SETTINGS: {
        if (!subtenantIds?.length) {
          return;
        }
        const response = yield* call(
          fetchTenantActivityAlertSettings,
          subtenantIds,
        );

        yield* put(
          actions.updateSubtenantDataIncrementallySuccess({
            dataKey,
            // @ts-ignore need some refactoring in order to have id provided from BE
            dict: response,
          }),
        );
        return;
      }

      case GM_DATA_KEYS.BASELINE_ALERT_THRESHOLDS: {
        let response: FetchBaselineAlertThresholdsResponse = {
          tenantBaselineThresholds: {},
        };
        yield* put(
          alertActions.setBaselineThresholdsStatus(
            DATA_FETCHING_STATUS.LOADING,
          ),
        );
        if (subtenantIds?.length) {
          response = yield* call(fetchTenantBaselineTresholds, subtenantIds);
        } else {
          const { data } = yield* call(
            BackendService.getBaselineAlertThresholds,
          );
          response = { ...data };
        }
        yield* put(
          alertActions.setBaselineThresholdsStatus(
            DATA_FETCHING_STATUS.SUCCESS,
          ),
        );
        const tenantsData = response.tenantBaselineThresholds;
        const responseArray = Object.values(tenantsData);
        const responseThresholds: BaselineAlertThreshold[] = [];
        for (const response of responseArray) {
          responseThresholds.push(...response.thresholds);
        }
        yield* put(
          alertActions.getBaselineThresholdsListSuccess(responseThresholds),
        );
        const copyOfData = { ...response };
        const tenantId = yield* select(
          loggedInUserSelectors.getCurrentTenantId,
        );
        delete copyOfData.tenantBaselineThresholds[tenantId];
        const tenantDict = mapMultiItemResponse(
          copyOfData.tenantBaselineThresholds,
          response => response.thresholds.map(threshold => threshold.id),
        );
        yield* put(
          actions.fetchSubtenantDataSuccess({ dataKey, dict: tenantDict }),
        );
        return;
      }
      // TODO: seems it's unused, needs more investigation
      case GM_DATA_KEYS.DEVICES: {
        const response = yield* call(
          MultiTenantService.fetchMultitenantDevices,
        );
        const tenantsData = extractResponseData(response);
        const responseArray = Object.values(tenantsData);

        for (const response of responseArray) {
          yield* put(deviceActions.setDevicesList(response.data));
        }

        const tenantDict = mapMultiItemResponse(tenantsData, response =>
          response.data.map(device => device.manufacturerId),
        );

        yield* put(
          actions.fetchSubtenantDataSuccess({ dataKey, dict: tenantDict }),
        );

        return;
      }
      case GM_DATA_KEYS.ROOMS: {
        const { payload } = action.payload;

        const payloadSize = 100;
        const roomIds = payload || [];
        const data: FetchRoomsResponse = {
          rooms: [],
        };
        for (let i = 0; i < roomIds?.length; i += payloadSize) {
          const payloadChunk = roomIds.slice(i, i + payloadSize);
          const { data: roomsData } = yield* call(
            BackendService.fetchMTMRooms,
            payloadChunk || [],
          );
          data.rooms = [...data.rooms, ...roomsData.rooms] as Room[];
        }
        yield* put(roomActions.onLoadRoomListSuccess(data.rooms));
        const tenantDict: Record<UUID, UUID> = {};

        data.rooms.forEach(room => {
          tenantDict[room.id] = room.tenantId;
        });

        yield* put(
          actions.fetchSubtenantDataSuccess({ dataKey, dict: tenantDict }),
        );

        return;
      }
      case GM_DATA_KEYS.SESSIONS: {
        // yield* put(sessionsActions.onFetchSessions());
        const [devicesResponse, patientsResponse] = yield* all([
          call(
            MultiTenantService.fetchMultitenantDeviceLatestsSessions,
            subtenantIds || [],
          ),
          call(
            MultiTenantService.fetchMultitenantPatientLatestSessions,
            [],
            subtenantIds || [],
          ),
        ]);

        const tenantsDevicesData = extractResponseData(
          devicesResponse as Record<
            string,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            AxiosResponse<FetchDevicesLatestSessionsResponse, any>
          >,
        );
        const tenantsPatientsData = extractResponseData(
          patientsResponse as Record<
            string,
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            AxiosResponse<FetchPatientsLatestSessionsResponse, any>
          >,
        );
        const devicesResponseArray = Object.values(tenantsDevicesData);
        const patientsResponseArray = Object.values(tenantsPatientsData);
        const devicesLatestSessions = [];
        const patientsLatestSessions = [];
        for (const response of devicesResponseArray) {
          devicesLatestSessions.push(...response.sessions);
        }
        yield* put(
          sessionActions.fetchDevicesLatestSessionsSuccess({
            sessions: devicesLatestSessions,
          }),
        );
        for (const response of patientsResponseArray) {
          patientsLatestSessions.push(...response.sessions);
        }
        yield* put(
          sessionActions.fetchPatientsLatestSessionsSuccess(
            patientsLatestSessions,
          ),
        );

        const deviceSessionsTenantDict = mapMultiItemResponse(
          tenantsDevicesData,
          response => response.sessions.map(session => session.id),
        );
        const patientSessionsTenantDict = mapMultiItemResponse(
          tenantsPatientsData,
          response => response.sessions.map(session => session.id),
        );

        yield* put(
          actions.fetchSubtenantDataSuccess({
            dataKey: GM_DATA_KEYS.SESSIONS,
            dict: {
              ...deviceSessionsTenantDict,
              ...patientSessionsTenantDict,
            },
          }),
        );

        return;
      }

      case GM_DATA_KEYS.USERS: {
        const responseUsers = yield* call(
          MultiTenantService.fetchMultiTenantUsers,
          subtenantIds || [],
        );

        const responseInvitedUsers = yield* call(
          MultiTenantService.fetchMultiTenantInvitedUsers,
          subtenantIds || [],
        );

        const usersData = extractResponseData(responseUsers);
        const invitedUsersData = extractResponseData(responseInvitedUsers);

        const userResponseEntries = Object.entries(usersData);
        for (const [subtenantId, usersResponse] of userResponseEntries) {
          yield* put(
            userActions.fetchAllUsersSuccess({
              users: usersResponse,
              invitedUsers: invitedUsersData[subtenantId] || {
                invitationsResponse: [],
              },
            }),
          );
        }

        const usersDict = mapMultiItemResponse(usersData, response =>
          response.data.map(user => user.id),
        );
        const invitationsDict = mapMultiItemResponse(
          invitedUsersData,
          response =>
            response.invitationsResponse.map(invitation => invitation.id),
        );

        yield* put(
          actions.fetchSubtenantDataSuccess({
            dataKey,
            dict: { ...usersDict, ...invitationsDict },
          }),
        );

        return;
      }

      case GM_DATA_KEYS.SUBSCRIBERS: {
        let response: FetchSubscribersResponse = {
          tenantIdToSubscribers: {},
        };
        yield* put(
          subscriberActions.setSubscribersStatus(DATA_FETCHING_STATUS.LOADING),
        );
        if (subtenantIds?.length) {
          response = yield* call(fetchTenantSubscribers, subtenantIds);
        } else {
          const { data } = yield* call(BackendService.getSubscribersList);
          response = { ...data };
        }
        yield* put(
          subscriberActions.setSubscribersStatus(DATA_FETCHING_STATUS.SUCCESS),
        );

        const tenantsData = response.tenantIdToSubscribers;
        const responseArray = Object.values(tenantsData);
        const subscribersList = [];
        for (const response of responseArray) {
          if (!response?.subscribers) {
            continue;
          }
          subscribersList.push(...response.subscribers);
        }
        yield* put(
          subscriberActions.fetchSubscribersListSuccess(subscribersList),
        );
        const copyOfData = { ...response };
        const tenantId = yield* select(
          loggedInUserSelectors.getCurrentTenantId,
        );
        delete copyOfData.tenantIdToSubscribers[tenantId];
        const tenantDict = mapMultiItemResponse(
          copyOfData.tenantIdToSubscribers,
          response =>
            (response.subscribers || []).map(subscriber => subscriber.id),
        );
        yield* put(
          actions.fetchSubtenantDataSuccess({ dataKey, dict: tenantDict }),
        );

        return;
      }
      default: {
        // eslint-disable-next-line no-console
        console.error(`Unhandled case in fetchSubtenantData for ${dataKey}`);
      }
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(`error in fetchSubtenantDataFailed for ${dataKey}: `, e);
    yield* put(actions.fetchSubtenantDataFailed(dataKey));
  }
}

function* fetchMultitenantPatients(
  action: ReturnType<typeof actions.fetchMultitenantPatients>,
) {
  if (!action.payload || !action.payload.length) {
    yield* put(patientActions.fetchAllPatientsSuccess([]));
    yield* put(
      actions.updateSubtenantDataIncrementallySuccess({
        dataKey: GM_DATA_KEYS.PATIENTS,
        dict: {},
      }),
    );
    return;
  }
  const data: FetchPatientsResponse = {
    data: [],
    metadata: {
      page: null,
      sort: null,
    },
  };

  const payloadSize = 100;
  for (let i = 0; i < action.payload.length; i += payloadSize) {
    const payloadChunk = action.payload.slice(i, i + payloadSize);
    const { data: patientsData } = yield* call(
      BackendService.fetchMTMPatientDetails,
      payloadChunk,
    );
    data.data = [...data.data, ...patientsData.data];
    data.metadata = patientsData.metadata;
  }
  yield* put(patientActions.fetchAllPatientsSuccess(data.data));

  const tenantDict: Record<UUID, UUID> = {};

  data.data.forEach(patient => {
    tenantDict[patient.id] = patient.tenantId;
  });

  yield* put(
    actions.updateSubtenantDataIncrementallySuccess({
      dataKey: GM_DATA_KEYS.PATIENTS,
      dict: tenantDict,
    }),
  );
}

function* createSubtenantAlertThresholds(
  action: ReturnType<typeof actions.createSubtenantAlertThresholds>,
) {
  const { subtenantIds, data } = action.payload;

  if (!hasEditOrCreateAlertThresholdsPermissions()) {
    return;
  }

  try {
    const response = yield* call(
      MultiTenantService.createMultitenantAlertThreshold,
      subtenantIds,
      data,
    );
    const tenantsData = extractResponseData(response);
    const responseArray = Object.values(tenantsData);

    for (const response of responseArray) {
      yield* put(alertActions.createAlertThresholdSuccess(response));
    }

    const tenantDict = mapSingleItemResponse(
      tenantsData,
      response => response.id,
    );

    yield* put(
      actions.updateSubtenantDataIncrementallySuccess({
        dataKey: GM_DATA_KEYS.ALERT_THRESHOLDS,
        dict: tenantDict,
      }),
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(
      `error in fetchSubtenantDataFailed for ${GM_DATA_KEYS.ALERT_THRESHOLDS}: `,
      e,
    );
    yield* put(actions.fetchSubtenantDataFailed(GM_DATA_KEYS.ALERT_THRESHOLDS));
  }
}

function* editSubtenantAlertThresholds(
  action: ReturnType<typeof actions.editSubtenantAlertThresholds>,
) {
  const { subtenantIdThresholdIdDict, data } = action.payload;

  if (!hasEditOrCreateAlertThresholdsPermissions()) {
    return;
  }

  try {
    const response = yield* call(
      MultiTenantService.updateMultitenantAlertThreshold,
      subtenantIdThresholdIdDict,
      data,
    );
    const tenantsData = extractResponseData(response);
    const responseArray = Object.values(tenantsData);

    for (const response of responseArray) {
      yield* put(alertActions.editAlertThresholdSuccess(response));
    }

    const tenantDict = mapSingleItemResponse(
      tenantsData,
      response => response.id,
    );

    yield* put(
      actions.updateSubtenantDataIncrementallySuccess({
        dataKey: GM_DATA_KEYS.ALERT_THRESHOLDS,
        dict: tenantDict,
      }),
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(
      `error in fetchSubtenantDataFailed for ${GM_DATA_KEYS.ALERT_THRESHOLDS}: `,
      e,
    );
    yield* put(actions.fetchSubtenantDataFailed(GM_DATA_KEYS.ALERT_THRESHOLDS));
  }
}

function* createSubtenantBaselineAlertThresholds(
  action: ReturnType<typeof actions.createSubtenantBaselineAlertThresholds>,
) {
  const { subtenantIds, data } = action.payload;

  if (!hasEditOrCreateAlertThresholdsPermissions()) {
    return;
  }

  try {
    const response = yield* call(
      MultiTenantService.createMultitenantBaselineAlertThreshold,
      subtenantIds,
      data,
    );
    const tenantsData = extractResponseData(response);
    const responseArray = Object.values(tenantsData);

    for (const response of responseArray) {
      yield* put(alertActions.createBaselineAlertThresholdSuccess(response));
    }

    const tenantDict = mapSingleItemResponse(
      tenantsData,
      response => response.id,
    );

    yield* put(
      actions.updateSubtenantDataIncrementallySuccess({
        dataKey: GM_DATA_KEYS.BASELINE_ALERT_THRESHOLDS,
        dict: tenantDict,
      }),
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(
      `error in fetchSubtenantDataFailed for ${GM_DATA_KEYS.BASELINE_ALERT_THRESHOLDS}: `,
      e,
    );
    yield* put(
      actions.fetchSubtenantDataFailed(GM_DATA_KEYS.BASELINE_ALERT_THRESHOLDS),
    );
  }
}

function* editSubtenantBaselineAlertThresholds(
  action: ReturnType<typeof actions.editSubtenantBaselineAlertThresholds>,
) {
  const { subtenantIdThresholdIdDict, data } = action.payload;

  if (!hasEditOrCreateAlertThresholdsPermissions()) {
    return;
  }

  try {
    const response = yield* call(
      MultiTenantService.updateMultitenantBaselineAlertThreshold,
      subtenantIdThresholdIdDict,
      data,
    );
    const tenantsData = extractResponseData(response);
    const responseArray = Object.values(tenantsData);

    for (const response of responseArray) {
      yield* put(alertActions.editBaselineAlertThresholdSuccess(response));
    }

    const tenantDict = mapSingleItemResponse(
      tenantsData,
      response => response.id,
    );

    yield* put(
      actions.updateSubtenantDataIncrementallySuccess({
        dataKey: GM_DATA_KEYS.BASELINE_ALERT_THRESHOLDS,
        dict: tenantDict,
      }),
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(
      `error in fetchSubtenantDataFailed for ${GM_DATA_KEYS.BASELINE_ALERT_THRESHOLDS}: `,
      e,
    );
    yield* put(
      actions.fetchSubtenantDataFailed(GM_DATA_KEYS.BASELINE_ALERT_THRESHOLDS),
    );
  }
}

function* updateSubtenantSubscribersList(
  action: ReturnType<typeof actions.updateSubtenantSubscribersList>,
) {
  const { subtenantIds, data } = action.payload;

  try {
    const response = yield* call(
      MultiTenantService.updateMultitenantSubscribersList,
      subtenantIds,
      data,
    );
    const tenantsData = extractResponseData(response);
    const responseArray = Object.entries(tenantsData);

    for (const [subtenantId, response] of responseArray) {
      yield* put(
        subscriberActions.updateSubscribersListSuccess({
          tenantId: subtenantId,
          subscribers: response.subscribers,
        }),
      );
    }

    const tenantDict = mapMultiItemResponse(tenantsData, response =>
      response.subscribers.map(subscriber => subscriber.id),
    );

    yield* put(
      actions.updateSubtenantDataWithCacheInvalidationSuccess({
        dataKey: GM_DATA_KEYS.SUBSCRIBERS,
        subtenantIds,
        dict: tenantDict,
      }),
    );
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(
      `error in updateSubscribersListSuccess for ${GM_DATA_KEYS.SUBSCRIBERS}: `,
      e,
    );
    yield* put(actions.fetchSubtenantDataFailed(GM_DATA_KEYS.SUBSCRIBERS));
  }
}

function* updateSubtenantActivityAlertSettings(
  action: ReturnType<typeof actions.updateSubtenantSubscribersList>,
) {
  const payload = action.payload;
  const { subtenantIds, data } = payload;

  try {
    yield* call(
      MultiTenantService.updateMultitenantActivityAlertSettings,
      subtenantIds,
      data,
    );
    /*    const tenantsData = extractResponseData(response);
    const responseArray = Object.entries(tenantsData);

    for (const [subtenantId, response] of responseArray) {
      yield* put(
        subscriberActions.updateSubscribersListSuccess({
          tenantId: subtenantId,
          subscribers: response.subscribers,
        }),
      );
    }

    const tenantDict = mapMultiItemResponse(tenantsData, response =>
      response.subscribers.map(subscriber => subscriber.id),
    );

    yield* put(
      actions.updateSubtenantDataWithCacheInvalidationSuccess({
        dataKey: GM_DATA_KEYS.SUBSCRIBERS,
        subtenantIds,
        dict: tenantDict,
      }),
    );*/
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(
      `error in updateSubscribersListSuccess for ${GM_DATA_KEYS.ACTIVITY_ALERTS_SETTINGS}: `,
      e,
    );
    yield* put(
      actions.fetchSubtenantDataFailed(GM_DATA_KEYS.ACTIVITY_ALERTS_SETTINGS),
    );
  }
}

function* suppressSubtenantAlert(
  action: ReturnType<typeof actions.suppressSubtenantAlert>,
) {
  const subtenantIdAlertIdDict = action.payload;

  try {
    const response = yield* call(
      MultiTenantService.suppressMultitenantAlerts,
      subtenantIdAlertIdDict,
    );
    const tenantsData = extractResponseData(response);
    const responseArray = Object.values(tenantsData);
    const timezone = yield* select(loggedInUserSelectors.getUserTenantTimezone);
    const updatedAlerts = getAlertLogsUpdated(
      responseArray,
      timezone,
    ) as Alert[];
    for (const response of updatedAlerts) {
      yield* put(alertActions.suppressAlertTypeSuccess(response));
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(
      `error in fetchSubtenantDataFailed for ${GM_DATA_KEYS.ALERTS}: `,
      e,
    );
    yield* put(actions.fetchSubtenantDataFailed(GM_DATA_KEYS.ALERTS));
  }
}

function* unSuppressSubtenantAlert(
  action: ReturnType<typeof actions.unSuppressSubtenantAlert>,
) {
  const subtenantIdAlertIdDict = action.payload;

  try {
    const response = yield* call(
      MultiTenantService.unSuppressMultitenantAlerts,
      subtenantIdAlertIdDict,
    );
    const tenantsData = extractResponseData(response);
    const responseArray = Object.values(tenantsData);
    const timezone = yield* select(loggedInUserSelectors.getUserTenantTimezone);
    const updatedAlerts = getAlertLogsUpdated(
      responseArray,
      timezone,
    ) as Alert[];

    for (const response of updatedAlerts) {
      yield* put(alertActions.unSuppressAlertTypeSuccess(response));
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(
      `error in fetchSubtenantDataFailed for ${GM_DATA_KEYS.ALERTS}: `,
      e,
    );
    yield* put(actions.fetchSubtenantDataFailed(GM_DATA_KEYS.ALERTS));
  }
}

function* clearSubtenantPatientAlerts(
  action: ReturnType<typeof actions.clearSubtenantPatientAlerts>,
) {
  const subtenantIdAlertIdDict = action.payload;

  try {
    const response = yield* call(
      MultiTenantService.clearMultitenantPatientAlerts,
      subtenantIdAlertIdDict,
    );
    const tenantsData = extractResponseData(response);
    const responseArray = Object.values(tenantsData);

    for (const response of responseArray) {
      yield* put(alertActions.clearAllPatientAlertsSuccess(response));
    }
  } catch (e) {
    // eslint-disable-next-line no-console
    console.error(
      `error in fetchSubtenantDataFailed for ${GM_DATA_KEYS.ALERTS}: `,
      e,
    );
    yield* put(actions.fetchSubtenantDataFailed(GM_DATA_KEYS.ALERTS));
  }
}

export default function* watchTenantActions() {
  yield* all([
    takeEvery(actions.fetchSubtenantData, fetchSubtenantData),
    takeEvery(
      actions.createSubtenantAlertThresholds,
      createSubtenantAlertThresholds,
    ),
    takeEvery(
      actions.editSubtenantAlertThresholds,
      editSubtenantAlertThresholds,
    ),
    takeEvery(
      actions.createSubtenantBaselineAlertThresholds,
      createSubtenantBaselineAlertThresholds,
    ),
    takeEvery(
      actions.editSubtenantBaselineAlertThresholds,
      editSubtenantBaselineAlertThresholds,
    ),
    takeEvery(
      actions.updateSubtenantSubscribersList,
      updateSubtenantSubscribersList,
    ),
    takeEvery(actions.suppressSubtenantAlert, suppressSubtenantAlert),
    takeEvery(actions.unSuppressSubtenantAlert, unSuppressSubtenantAlert),
    takeEvery(actions.clearSubtenantPatientAlerts, clearSubtenantPatientAlerts),
    takeEvery(actions.fetchMultitenantPatients, fetchMultitenantPatients),
    takeEvery(
      // @ts-ignore check this error
      actions.updateSubtenantActivityAlertSettings,
      updateSubtenantActivityAlertSettings,
    ),
  ]);
}
