import { all, call, put, select, take } from 'typed-redux-saga/macro';
import * as R from 'fp-ts/lib/Record';

import {
  actions as alertActions,
  selectors as alertSelectors,
} from 'src/redux/data/alerts';
import { actions as gmActions } from 'src/redux/data/groupManager';
import { Alert, SubtenantAttentionList } from 'src/types/alerts';
import { UUID } from 'src/types/utility';
import { filterDuplicates } from 'src/utils/dataUtils';
import { GM_DATA_KEYS } from './constants';
import { hasGMPermissions } from 'src/utils/permissions';
import { Action } from '@reduxjs/toolkit';

const getAlertPatientIdsBySubtenant = (
  currentAlerts: Alert[],
  attentionList: Record<string, SubtenantAttentionList>,
): UUID[] => {
  const currentAlertsPatientIds = currentAlerts
    .filter(alert => !!alert.patientId)
    .reduce((acc: UUID[], alert) => {
      return [...acc, alert.patientId || ''];
    }, []);

  const attentionListPatientIdsByTenant = R.map<SubtenantAttentionList, UUID[]>(
    subtenantAttentionList =>
      Object.keys(subtenantAttentionList.medical.byPatientId),
  )(attentionList);

  const attentionListPatientIds = Object.values(
    attentionListPatientIdsByTenant,
  ).reduce((acc: UUID[], eachTenant) => [...acc, ...eachTenant], []);

  const result = [...currentAlertsPatientIds, ...attentionListPatientIds];
  return filterDuplicates(result);
};

export const getAlertDeviceIdsBySubtenant = (
  currentAlerts: Alert[],
  attentionList: Record<string, SubtenantAttentionList>,
): UUID[] => {
  const currentAlertsDeviceIds = currentAlerts.reduce((acc: UUID[], alert) => {
    return [...acc, alert.deviceId || ''];
  }, []);

  const attentionListDeviceIds = Object.values(attentionList)?.reduce(
    (acc: UUID[], eachTenantAlert) => {
      const eachDeviceIdList: UUID[] = [];
      for (const key in eachTenantAlert.medical.byPatientId) {
        eachDeviceIdList.push(
          eachTenantAlert.medical.byPatientId[key]?.deviceId || '',
        );
      }
      return [...acc, ...eachDeviceIdList];
    },
    [],
  );

  const result = [...currentAlertsDeviceIds, ...attentionListDeviceIds];
  return filterDuplicates(result);
};

function* extractAlertPatientIds(
  action: ReturnType<typeof alertActions.fetchMTMAttentionListSuccess>,
) {
  const currentAlertsData = yield* select(alertSelectors.selectAlerts);
  const mtmAttentionList = action.payload.byTenantId;

  return getAlertPatientIdsBySubtenant(currentAlertsData, mtmAttentionList);
}
export function* extractAlertDeviceIds() {
  const currentAlertsData = yield* select(alertSelectors.selectAlerts);
  const mtmAttentionList = yield* select(alertSelectors.selectMTMAttentionList);
  return getAlertDeviceIdsBySubtenant(currentAlertsData, mtmAttentionList);
}

function* onAlertsLoaded(
  action: ReturnType<typeof alertActions.fetchMTMAttentionListSuccess>,
) {
  if (!hasGMPermissions()) {
    return;
  }
  const patientIdsBySubtenant = yield* call(extractAlertPatientIds, action);
  yield* put(gmActions.fetchMultitenantPatients(patientIdsBySubtenant));
  const MtmDeviceIds = yield* call(extractAlertDeviceIds);
  yield* put(
    gmActions.fetchSubtenantData({
      dataKey: GM_DATA_KEYS.ROOMS,
      payload: MtmDeviceIds,
    }),
  );
}

export default function* watchTenantDataActions() {
  while (true) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [_subtenantAlertsFetchAction, mtmAttentionListAction] = yield* all([
      take((action: Action) => {
        return (
          action.type === gmActions.fetchSubtenantDataSuccess.type &&
          // @ts-ignore Redux saga does not have types for RTK
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          action?.payload?.dataKey === GM_DATA_KEYS.ALERTS
        );
      }),
      take(alertActions.fetchMTMAttentionListSuccess),
    ]);
    yield* call(
      onAlertsLoaded,
      mtmAttentionListAction as ReturnType<
        typeof alertActions.fetchMTMAttentionListSuccess
      >,
    );
  }
}
