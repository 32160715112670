import { connect, ConnectedProps } from 'react-redux';
import * as R from 'fp-ts/lib/Record';
import * as Ord from 'fp-ts/lib/Ord';

import { RootState } from 'src/redux/store';
import { selectors as gmSelectors } from 'src/redux/data/groupManager';
import { selectors as deviceSelectors } from 'src/redux/data/device';
import { selectors as alertSelectors } from 'src/redux/data/alerts';
import { isLatestData } from 'src/redux/data/groupManager/modules/utils';
import { hasAlertFinishedInLast24h } from 'src/redux/data/alerts/modules/utils';
import { isMedicalAlert } from 'src/redux/data/alerts/modules/typeGuards';
import { GM_DATA_KEYS } from 'src/redux/data/groupManager/modules/constants';
import { PatientAttentionItem, SubtenantAttentionList } from 'src/types/alerts';

const mapStateToProps = (state: RootState) => {
  const currentAlertsDict = gmSelectors.selectDataTenantDict(
    state,
    GM_DATA_KEYS.ALERTS,
  );
  const currentAlertEvents = alertSelectors
    .selectAlerts(state)
    .filter(
      a =>
        isLatestData(a.id, currentAlertsDict) &&
        isMedicalAlert(a) &&
        (a.status !== 'OFF' || hasAlertFinishedInLast24h(a)),
    );
  const mtmHistoricalAlertCount = R.reduce(Ord.trivial)<
    SubtenantAttentionList,
    number
  >(0, (acc, subtenantAttentionList) => {
    const subtenantHistoricalAlertCount = R.reduce(Ord.trivial)<
      PatientAttentionItem,
      number
    >(
      0,
      (acc2, patientAttentionList) => acc2 + patientAttentionList.alertsCount,
    )(subtenantAttentionList.medical.byPatientId);

    return acc + subtenantHistoricalAlertCount;
  })(alertSelectors.selectMTMAttentionList(state));

  const lastDayAlertEvents =
    currentAlertEvents.length + mtmHistoricalAlertCount;

  const deviceInfoData = deviceSelectors.selectMTMDeviceStatistics(state);

  return {
    totalDevices: deviceInfoData.totalDevices,
    connectedAssignedDevices: deviceInfoData.connectedAssignedDevices,
    connectedUnassignedDevices: deviceInfoData.connectedUnassignedDevices,
    disconnectedAssignedDevices: deviceInfoData.disconnectedAssignedDevices,
    disconnectedUnassignedDevices: deviceInfoData.disconnectedUnassignedDevices,
    lastDayAlertEvents: lastDayAlertEvents,
  };
};

const mapActionCreators = {};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export default Connector;
