import React from 'react';
import {
  defineMessages,
  FormattedMessage,
  IntlShape,
  injectIntl,
} from 'react-intl';
import { Switch } from 'antd';

import Checkbox from 'src/components/general-ui/Checkbox';
import { TenantSubscriptionDetails } from 'src/routes/GMDashboard/modules/types';
import {
  CheckboxesWrapper,
  EmailSubscriptionWrapper,
  MedicalAlertSettingsTitle,
  MedicalAlertSettingsWrapper,
  PersonalDetailsContainer,
} from './styled';
import { deepEqual } from 'src/utils/comparators';

type Props = {
  subscriptionDetails: TenantSubscriptionDetails;
  setSubscriptionDetails: React.Dispatch<
    React.SetStateAction<TenantSubscriptionDetails>
  >;
  intl: IntlShape;
};

const MTMMedicalAlerts = ({
  subscriptionDetails,
  setSubscriptionDetails,
  intl,
}: Props): JSX.Element => {
  return (
    <MedicalAlertSettingsWrapper>
      <MedicalAlertSettingsTitle>
        <FormattedMessage {...messages.medicalAlerts} />
      </MedicalAlertSettingsTitle>
      <FormattedMessage {...messages.notificationInfo} />
      <CheckboxesWrapper>
        <EmailSubscriptionWrapper>
          <Checkbox
            label={intl.formatMessage(messages.email)}
            checked={subscriptionDetails.MEDICAL.EMAIL.isAssigned}
            handleChange={value => {
              if (!value) {
                setSubscriptionDetails({
                  ...subscriptionDetails,
                  MEDICAL: {
                    ...subscriptionDetails.MEDICAL,
                    EMAIL: {
                      isAssigned: false,
                      withPersonalDetails: false,
                    },
                  },
                });

                return;
              }

              setSubscriptionDetails({
                ...subscriptionDetails,
                MEDICAL: {
                  ...subscriptionDetails.MEDICAL,
                  EMAIL: {
                    ...subscriptionDetails.MEDICAL.EMAIL,
                    isAssigned: value,
                  },
                },
              });
            }}
          />
          <PersonalDetailsContainer>
            <FormattedMessage {...messages.withPatientDetails} />
            <Switch
              defaultChecked={false}
              disabled={!subscriptionDetails.MEDICAL.EMAIL.isAssigned}
              checked={subscriptionDetails.MEDICAL.EMAIL.withPersonalDetails}
              onChange={checked =>
                setSubscriptionDetails({
                  ...subscriptionDetails,
                  MEDICAL: {
                    ...subscriptionDetails.MEDICAL,
                    EMAIL: {
                      ...subscriptionDetails.MEDICAL.EMAIL,
                      withPersonalDetails: checked,
                    },
                  },
                })
              }
            />
          </PersonalDetailsContainer>
        </EmailSubscriptionWrapper>
        <Checkbox
          label={intl.formatMessage(messages.sms)}
          checked={subscriptionDetails.MEDICAL.SMS.isAssigned}
          handleChange={value =>
            setSubscriptionDetails({
              ...subscriptionDetails,
              MEDICAL: {
                ...subscriptionDetails.MEDICAL,
                SMS: { ...subscriptionDetails.MEDICAL.SMS, isAssigned: value },
              },
            })
          }
        />
      </CheckboxesWrapper>
    </MedicalAlertSettingsWrapper>
  );
};

export const messages = defineMessages({
  medicalAlerts: {
    defaultMessage: 'Medical Alerts',
  },
  withPatientDetails: {
    defaultMessage: `With patient's personal details`,
  },
  notificationInfo: {
    defaultMessage: 'Send Me Alert Notification by:',
  },
  email: {
    defaultMessage: 'Email',
  },
  sms: {
    defaultMessage: 'SMS',
  },
});

export default injectIntl(
  React.memo(MTMMedicalAlerts, (oldProps, newProps) =>
    deepEqual(oldProps, newProps),
  ),
);
