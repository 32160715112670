import Search from 'antd/es/input/Search';
import styled from 'styled-components';

export const FacilityMappingWrap = styled.div``;

export const FacilityTenantFieldsRow = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`;

export const FacilitySearchBox = styled(Search)`
  width: 250px;
  margin-bottom: 0.5rem;
`;
