import React from 'react';
import { defineMessages, FormattedMessage } from 'react-intl';

import { handleClickSubtenantPatientName } from 'src/routes/GMDashboard/modules/utils';
import AlertCard from '../../components/AlertCard';
import Connector, { PropsFromRedux } from './Connector';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useInfiniteScrollProps } from 'src/utils/hooks/useInfiniteScrollProps';
import { AlertCardsWrapper, StyledText } from '../../styled';
import { deepEqual } from 'src/utils/comparators';
import { useGMCalculateLongOutOfBedTime } from 'src/utils/hooks/useGMCalculateLongOutOfBedTime';

type Props = PropsFromRedux;

const CurrentAlerts = ({
  alertItems,
  suppressAlertType,
  unSuppressAlertType,
  clearAllPatientAlerts,
  areCurrentAlertsLoading,
}: Props): JSX.Element => {
  const { noOfVisibleElements, fetchMoreData } = useInfiniteScrollProps();
  useGMCalculateLongOutOfBedTime();

  return (
    <AlertCardsWrapper
      id={'mtmCurrentAlertsScrollableDiv'}
      data-cy={`alerts-sidebar-gm-current-alerts-loading=${areCurrentAlertsLoading.toString()}`}
    >
      {alertItems.length ? (
        <InfiniteScroll
          dataLength={alertItems.slice(0, noOfVisibleElements).length} //This is important field to render the next data
          next={fetchMoreData}
          hasMore={alertItems.length > noOfVisibleElements}
          loader={
            <StyledText>
              <FormattedMessage {...messages.loadingOnScroll} />
            </StyledText>
          }
          scrollableTarget="mtmCurrentAlertsScrollableDiv"
          style={{ gap: '0.5rem' }}
        >
          {alertItems
            .slice(0, noOfVisibleElements)
            .map(({ alertId, deviceId, patientId, tenantId, notesCount }) => (
              <AlertCard
                key={alertId}
                alertId={alertId}
                deviceId={deviceId}
                patientId={patientId}
                tenantId={tenantId}
                notesCount={notesCount}
                onPatientNameClick={() =>
                  patientId &&
                  deviceId &&
                  handleClickSubtenantPatientName(tenantId, patientId)
                }
                onSuppressAlertClick={id =>
                  suppressAlertType({ [tenantId]: id })
                }
                onUnSuppressAlertClick={id =>
                  unSuppressAlertType({ [tenantId]: id })
                }
                onDeleteAlertClick={id =>
                  clearAllPatientAlerts({ [tenantId]: id })
                }
              />
            ))}
        </InfiniteScroll>
      ) : (
        <StyledText>
          <FormattedMessage {...messages.emptyText} />
        </StyledText>
      )}
    </AlertCardsWrapper>
  );
};

const messages = defineMessages({
  emptyText: {
    defaultMessage: 'No ongoing alerts at this moment.',
  },
  loadingOnScroll: {
    defaultMessage: 'Loading...',
  },
});

export default Connector(
  React.memo(CurrentAlerts, (oldProps, newProps) =>
    deepEqual(oldProps, newProps),
  ),
);
