import { all, call, put, select, takeLatest } from 'typed-redux-saga/macro';

import { actions } from './slice';
import { DATA_FETCHING_STATUS } from '../../constants';
import BackendService from 'src/services/BackendService';
import { notifyUserByActionTypeAndCode } from 'src/utils/errorHandling/notifications';
import { getState as getCustomersState } from 'src/redux/data/customers/modules/slice';

function* getFacilitiesList(action: ReturnType<typeof actions.getFacilities>) {
  const { currentCustomer } = yield* select(getCustomersState);

  const customerId = currentCustomer?.id || '';
  const emrMappings =
    (currentCustomer?.emrMappings &&
      currentCustomer?.emrMappings[0]?.emrType) ||
    '';

  try {
    yield* put(actions.setFacilitiesStatus(DATA_FETCHING_STATUS.LOADING));

    const { data } = yield* call(
      BackendService.getFacilitiesByEmrType,
      customerId,
      emrMappings,
    );

    yield* put(
      actions.setFacilitiesList({
        data: data.pccFacilities,
        status: DATA_FETCHING_STATUS.SUCCESS,
      }),
    );
  } catch (e) {
    console.error('error in fetchFacilities: ', e);
    yield* put(
      actions.setFacilitiesList({
        data: null,
        status: DATA_FETCHING_STATUS.ERROR,
      }),
    );
    notifyUserByActionTypeAndCode(action.type, e, e);
  }
}

function* getCustomerTenants() {
  const { currentCustomer } = yield* select(getCustomersState);

  const customerId = currentCustomer?.id || '';

  try {
    yield* put(actions.setCustomerTenantsStatus(DATA_FETCHING_STATUS.LOADING));
    const { data } = yield* call(
      BackendService.getTenantsByCustomerId,
      customerId,
    );

    yield* put(
      actions.setCustomerTenantsList({
        //@ts-ignore testing
        data: data,
        status: DATA_FETCHING_STATUS.SUCCESS,
      }),
    );
  } catch (e) {
    console.error('error in fetchCustomerTenants: ', e);
    yield* put(
      actions.setCustomerTenantsList({
        data: null,
        status: DATA_FETCHING_STATUS.ERROR,
      }),
    );
    notifyUserByActionTypeAndCode();
  }
}

function* mapFacilities(action: ReturnType<typeof actions.mapFacilities>) {
  const data = action.payload;

  try {
    yield* put(actions.setFacilityFormStatus(DATA_FETCHING_STATUS.LOADING));
    //@ts-ignore: TODO: add type when flow is tested
    yield* call(BackendService.mapEmrFacilities(data));
    yield* put(actions.setFacilityFormStatus(DATA_FETCHING_STATUS.SUCCESS));
  } catch (e) {
    console.error('error in mappingFacilities: ', e);
    yield* put(
      actions.setCustomerTenantsList({
        //@ts-ignore testing
        data: null,
        status: DATA_FETCHING_STATUS.ERROR,
      }),
    );
    notifyUserByActionTypeAndCode();
  }
}

export default function* watchEmrMappingsActions() {
  yield* all([
    takeLatest(actions.getFacilities, getFacilitiesList),
    takeLatest(actions.getCustomerTenants, getCustomerTenants),
    takeLatest(actions.mapFacilities, mapFacilities),
  ]);
}
