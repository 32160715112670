import { connect, ConnectedProps } from 'react-redux';

import { RootState } from 'src/redux/store';
import { selectors as patientSelectors } from 'src/redux/data/patient';
import dataSelectors from 'src/redux/data/dataSelectors';
import { selectors as loggedInUserSelectors } from 'src/redux/data/loggedInUser';
import { selectors as tenantSelectors } from 'src/redux/data/tenant';
import { actions, selectors } from 'src/routes/GMDashboard/modules/slice';
import { UUID } from 'src/types/utility';
import { API_STATUS } from 'src/utils/api-constants';
import { updateText } from 'src/utils/stringUtils';
import { correctAlertLogTimestamps } from 'src/redux/data/alerts/modules/utils';
import { AlertLogWithExtraData } from './types';
import { isMedicalAlertLog } from 'src/redux/data/alerts/modules/typeGuards';
import { STANDARD_TIMEZONE_ID } from 'src/redux/data/loggedInUser/modules/constants';

type OwnProps = {
  subtenantId: UUID;
};

const getDeviceLocationDetails = (
  deviceLocationData:
    | {
        roomName?: string;
        bedName?: string;
      }
    | undefined,
): {
  roomName: string;
  bedName: string;
  deviceLocation: string;
} => {
  return {
    roomName: deviceLocationData?.roomName || 'N/A',
    bedName: deviceLocationData?.bedName || 'N/A',
    deviceLocation: `${updateText(
      'R',
      deviceLocationData?.roomName,
    )}${updateText(' B', deviceLocationData?.bedName)}`,
  };
};

const selectAlertLogItems = (
  state: RootState,
  subtenantId: UUID,
): AlertLogWithExtraData[] => {
  const timezone = subtenantId
    ? tenantSelectors.getTenant(state, subtenantId)?.timeZoneId ||
      STANDARD_TIMEZONE_ID
    : loggedInUserSelectors.getUserTenantTimezone(state);

  const lastDayAlertLogs = selectors
    .selectSubtenantAlertLog(state, subtenantId)
    .filter(isMedicalAlertLog);
  const patients = patientSelectors.getPatientsList(state);

  return lastDayAlertLogs
    .map(alertLog => correctAlertLogTimestamps(alertLog, timezone))
    .map(alertLog => {
      const deviceLocationData = alertLog.deviceId
        ? dataSelectors.selectDeviceRoomAndBed(state, alertLog.deviceId)
        : undefined;
      return {
        ...alertLog,
        patientName:
          patients.find(p => p.id === alertLog.patientId)?.fullName || '',
        ...getDeviceLocationDetails(deviceLocationData),
      };
    });
};

const mapStateToProps = (state: RootState, { subtenantId }: OwnProps) => ({
  alertLogWithPatients: selectAlertLogItems(state, subtenantId),
  alertLogMetadata: selectors.selectSubtenantAlertLogMetadata(state),
  isLoading:
    selectors.selectSubtenantAlertLogStatus(state) === API_STATUS.LOADING,
});

const mapActionCreators = {
  fetchSubtenantAlertLog: actions.fetchSubtenantAlertLog,
};

const Connector = connect(mapStateToProps, mapActionCreators);

export type PropsFromRedux = ConnectedProps<typeof Connector>;
export type IProps = PropsFromRedux & OwnProps;

export default Connector;
